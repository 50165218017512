import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/Navbar';
import './clients.css'
import { RiSearch2Line } from 'react-icons/ri';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FaFileImport } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';
import AddClientModal from './AddClientModal';
import { useDispatch } from 'react-redux';
import { setCurrentItem } from '../../redux/reducers/navbar';
import { filterClientsList, setCurrentClient } from '../../redux/reducers/clients';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdEye } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import UdateClientModal from './UdateClientModal';
import { CONTACTS_PATH, DETAILS_CLIENTS_PATH } from '../../utils/navigation_paths';
import DeleteClientModal from './DeleteClientModal';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import { useNavigate } from 'react-router-dom';
import ImportClientModal from './ImportClientModal';
import ServicesComponent from '../ServicesComponent';
import ServiceDataMap from '../ServiceDataMap';
// import clients from '../../redux/actions/clients';
import clients from '../../redux/actions/clients';
import ApiController from '../../redux/actions';
import { useAgenceId } from '../../hooks/useAgenceId';

const ListClients = () => {
  let { clientsList } = useSelector(state => state.clients) || []
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.users.currentUser)
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const agenceId = useAgenceId()

  useEffect(() => {
    // ApiController.clients.getClientsList(agenceId, dispatch, token)
    ApiController.clients.getClientsList(agenceId, dispatch, currentUser.token.token)
    dispatch(setCurrentItem(CONTACTS_PATH))
  }, [])

  // console.log({clients});

  const showUpdateModal = (client) => {
    dispatch(setCurrentClient(client))
    openUpdateClientModal()
  }
  const showDeleteModal = (client) => {
    dispatch(setCurrentClient(client))
    openDeleteClientModal()
  }
  const showDetailsClient = (client) => {
    dispatch(setCurrentClient(client))
    navigate(DETAILS_CLIENTS_PATH + '?id=' + client.id)
  }
  const showActionButtons = (cell, row) => {
    return (
      <div >
        <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={() => showDetailsClient(row)} />
        <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon' onClick={() => showUpdateModal(row)} />
        <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon' onClick={() => showDeleteModal(row)} />
        {/* The tooltips below */}
        <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }

  let clientsListMap = clientsList.map(client => {
    return { ...client, nom_complet: client.firstName + ' ' + client.lastName }
  })
  const columns = [
    {
      dataField: "nom_complet",
      text: "Prénom et nom",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsClient(row)}>{row.nom_complet}</p>
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsClient(row)}>{row.telephone}</p>
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsClient(row)}>{row.email}</p>
    },
    {
      dataField: "adresse",
      text: "Adresse",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsClient(row)}>{row.adresse}</p>
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: showActionButtons
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPageList: [{
      text: '5', value: 5
    },
    {
      text: '10', value: 10
    },
    {
      text: '25', value: 25
    },
    {
      text: 'Tous', value: clientsList.length
    }]
  });

  function openAddClientModal() {
    setAddModalIsOpen(true);
  }
  function openImportClientModal() {
    setImportModalIsOpen(true);
  }
  function openUpdateClientModal() {
    setUpdateModalIsOpen(true);
  }
  function openDeleteClientModal() {
    setDeleteModalIsOpen(true);
  }

  return (
    <div className='row m-0 p-0 list-clients'>
      {/* <Navbar /> */}
      <AddClientModal modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
      <ImportClientModal modalIsOpen={importModalIsOpen} setIsOpen={setImportModalIsOpen} />
      <DeleteClientModal modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />
      {updateModalIsOpen && <UdateClientModal modalIsOpen={updateModalIsOpen} setIsOpen={setUpdateModalIsOpen} />}

      {/* <div className='row m-0 text-start my-4 justify-content-center app-padding'>
        <p className='fs-1 fw-bold my-0'>Clients</p>
        <p className='my-0'>Créez, consultez et gérez vos enregistrements de clients.</p>
        <div className="row m-0 p-0 my-3">
          <div className="mx-0 p-0 col-12 col-md-4">
            <div className='input-search-container m-0 p-0'>
              <label className='row'>
                <RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />
                <input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterClientsList(e.target.value))} />
              </label>
            </div>
          </div>
          <div className="group-btn my-md-0 col-12 col-md-8 mt-3 mt-md-0 p-0">
            <button className='btn btn-add-client me-3 rounded-0 shadow' onClick={openImportClientModal}>Importer <FaFileImport className='fs-4' /></button>
            <button className='btn btn-add-client ms-2 rounded-0 shadow' type="button" onClick={openAddClientModal}>Ajouter <AiFillPlusCircle className='fs-3' /></button>
          </div>
        </div>
      </div>
       */}
      <ServicesComponent
        title="Clients"
        description="Créez, consultez et gérez vos enregistrements de clients"
        labelIcon={<RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />}
        // input={<input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterClientsList(e.target.value))} />}
        onChange={e => dispatch(filterClientsList(e.target.value))}
        onClick1={openImportClientModal}
        // firstBtnText="Importer"
        // firstBtn={<FaFileImport className='fs-4' />}
        onClick2={openAddClientModal}
        secondBtnText="Ajouter"
        secondBtn={<AiFillPlusCircle className='fs-3' />}
      />

      <ServiceDataMap
        data={clientsListMap}
        columns={columns}
        pagination={pagination}
        messageOnError="Pas de client pour le moment"
      />
    </div>
  );
};

export default ListClients;