import React, { useState } from "react";
// import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReduxAction from "../../redux/actions";
import toast_options from "../../utils/toast_options";
import Modal from "../../shared/Modal";
import DeleteModal from "../../shared/DeleteModal";

const DeleteDepense = ({ modalIsOpen, setIsOpen, shouldNavigate = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentDepense = useSelector((state) => state.depenses.currentDepense);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      height: "44%",
      width: "30%",
      minWidth: "300px",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const archiveDepense = () => {
    let { id } = currentDepense;
    setLoading(true);
    ReduxAction.depenses
      .deleteDepense(id, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Depense supprimé avec succés", toast_options);
        if (shouldNavigate) {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log("error de suppression", err);
        closeModal();
        toast.error("La suppression n'a pas réuissi", toast_options);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DeleteModal
      opened={modalIsOpen}
      onClosed={closeModal}
      loading={loading}
      onDelete={archiveDepense}
    />
  );
};

export default DeleteDepense;
