import React, { useState } from "react";
// import Modal from 'react-modal';
import "./add_produits.css";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import Select from "react-select";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddCategoryModal from "../categories/AddCategoryModal";
import { isValidNumber, isValidText } from "../../utils/form_control";
import { optionsStyle } from "../../utils/reactSelectStyles";
import { useAgenceId } from "../../hooks/useAgenceId";
import { useProprietaireId } from "../../hooks/useProprietaireId";
import Modal from "../../shared/Modal";

const AddProduitsModal = ({ modalIsOpen, setIsOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const categoriesList =
    useSelector((state) => state.categories.categoriesList) || [];
  const defaultMessage = "Ce champ est obligatoire !";
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const agenceId = useAgenceId();
  const proprietaireId = useProprietaireId();

  const customStyles = {
    content: {
      zIndex: "99999999",
      top: "50%",
      left: "50%",
      height: "70%",
      width: "60%",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    reset();
    setIsOpen(false);
  }

  const submit = (data) => {
    data.createdBy = currentUser.id;
    data.categoryId = selectedCategory.value;
    // data.proprietaireId = proprietaireId
    // console.log({ data });
    let {
      name,
      categoryId,
      image,
      minStock,
      prixAchat,
      prixVente,
      quantite,
      createdBy,
    } = data;
    if (data.image.length) {
      // console.log("image");
      let formData = new FormData();
      formData.append("name", name);
      formData.append("categoryId", categoryId);
      formData.append("image", image[0]);
      formData.append("minStock", minStock);
      formData.append("prixAchat", prixAchat);
      formData.append("prixVente", prixVente);
      formData.append("quantite", quantite);
      formData.append("createdBy", createdBy);
      formData.append("agenceId", agenceId);
      formData.append("proprietaireId", proprietaireId);

      setLoading(true);
      ReduxAction.produits
        .createNewProduct(formData, currentUser.token.token, dispatch)
        .then((response) => {
          closeModal();
          toast.success("Produit ajouté avec succés", toast_options);
        })
        .catch((err) => {
          console.log(err);
          closeModal();
          toast.error("L'ajout n'a pas réuissi", toast_options);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    // console.log("no image");
    setLoading(true);
    ReduxAction.produits
      .createNewProduct(
        {
          name,
          categoryId,
          minStock,
          prixAchat,
          prixVente,
          quantite,
          createdBy,
          agenceId,
          proprietaireId
        },
        currentUser.token.token,
        dispatch
      )
      .then((response) => {
        closeModal();
        toast.success("Produit ajouté avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("L'ajout n'a pas réuissi", toast_options);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addNewCategory = () => {
    setCategoryModalIsOpen(true);
  };
  const categoriesOptions = categoriesList
    ?.map((category) => {
      return { value: category.id, label: category.categoryName };
    })
    .reverse()
    .concat([
      {
        value: "+",
        label: (
          <p
            className="custom-mouse-clickable text-secondary p-0 m-0"
            onClick={addNewCategory}
          >
            <AiOutlinePlusCircle /> Ajouter une catégorie
          </p>
        ),
      },
    ])
    .reverse();

  const verificate = (data) => {
    if (!selectedCategory) {
      setError("categoryId", { message: defaultMessage });
    } else if (!isValidText(data.name)) {
      setError("name", { message: "Nom non valide" });
    } else if (!isValidNumber(data.quantite)) {
      setError("quantite", { message: "Quantité non valide" });
    } else if (parseInt(data.quantite) <= 0) {
      setError("quantite", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.minStock)) {
      setError("minStock", { message: "Numéro non valide" });
    } else if (parseInt(data.minStock) <= 0) {
      setError("minStock", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.prixAchat)) {
      setError("prixAchat", { message: "Prix non valide" });
    } else if (parseInt(data.prixAchat) <= 0) {
      setError("prixAchat", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.prixVente)) {
      setError("prixVente", { message: "Prix non valide" });
    } else if (parseInt(data.prixVente) <= 0) {
      setError("prixVente", { message: "La valeur minimale est de 1" });
    } else {
      submit(data);
    }
  };
  return (
    <div>
      <Modal
        opened={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onClosed={closeModal}
        title="Créer un nouveau produit"
      >
        <form className=" px-3 py-3" onSubmit={handleSubmit(verificate)}>
          {/* <div className="row m-0 p-0">
            <h6 className="col-9 fs-6">Créer un nouveau produit</h6>
            <p className="col text-end fw-bold fs-4">
              <span
                className=" border-dark rounded px-2 custom-mouse-clickable"
                onClick={closeModal}
              >
                X
              </span>
            </p>
          </div> */}
          <div className="row my-md-3">
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Nom du produit
                <input
                  type="text"
                  {...register("name", { required: defaultMessage })}
                  className="add-client-input form-control p-2"
                />
                {errors.name && (
                  <span className="custom-error-msg">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="col-12 mt-3 mt-md-0 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Catégorie
                <Select
                  styles={optionsStyle}
                  {...register("categoryId")}
                  value={selectedCategory}
                  onChange={(category) =>
                    category.value !== "+" && setSelectedCategory(category)
                  }
                  options={categoriesOptions}
                />
                {errors.categoryId && !selectedCategory && (
                  <span className="custom-error-msg">
                    {errors.categoryId.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 mt-3 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Quantité en stock
                <input
                  type="number"
                  {...register("quantite", { required: defaultMessage })}
                  className="add-client-input form-control p-2"
                />
                {errors.quantite && (
                  <span className="custom-error-msg">
                    {errors.quantite.message}
                  </span>
                )}
              </label>
            </div>
            <div className="col-12 mt-3 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Quantité en seuil
                <input
                  type="number"
                  required
                  {...register("minStock", { quantite: defaultMessage })}
                  className="add-client-input form-control p-2"
                />
                {errors.minStock && (
                  <span className="custom-error-msg">
                    {errors.minStock.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 mt-3 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Prix d'achat
                <input
                  type="number"
                  {...register("prixAchat", { required: defaultMessage })}
                  className="add-client-input form-control p-2"
                />
                {errors.prixAchat && (
                  <span className="custom-error-msg">
                    {errors.prixAchat.message}
                  </span>
                )}
              </label>
            </div>
            <div className="col-12 mt-3 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Prix de vente
                <input
                  type="number"
                  {...register("prixVente", { required: defaultMessage })}
                  className="add-client-input form-control p-2"
                />
                {errors.prixVente && (
                  <span className="custom-error-msg">
                    {errors.prixVente.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 mt-3 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Image
                <input
                  accept=".png,.jpg,.jpeg,.svg"
                  type="file"
                  {...register("image")}
                  className="add-client-input form-control p-2"
                />
                {errors.image && (
                  <span className="custom-error-msg">
                    {errors.image.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <p className="text-end mt-3">
            <button
              disabled={loading}
              type="submit"
              className="custom-button-secondary py-2 px-3 rounded-0"
            >
              {!loading ? "Enregistrer" : "Enregistrement en cours..."}
            </button>
          </p>
        </form>
      </Modal>
      <AddCategoryModal
        modalIsOpen={categoryModalIsOpen}
        setIsOpen={setCategoryModalIsOpen}
      ></AddCategoryModal>
    </div>
  );
};

export default AddProduitsModal;
