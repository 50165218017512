import ListClients from "../components/clients/ListClients";
import DetailsDepense from "../components/depenses/DetailsDepense";
import ListDepenses from "../components/depenses/ListDepenses";
import ListFournisseur from "../components/fournisseurs/ListFournisseur";
import Parametre from "../components/parametres/Parametre";
import ListProduits from "../components/produits/ListProduits";
import Forgotassword from "../components/password/Forgotassword";
import Profile from "../components/profile/Profile";
import DetailsVentePage from "../components/ventes/DetailsVentePage";
import ListVentes from "../components/ventes/ListVentes";
import { ADD_VENTES_PATH, CLIENTS_PATH, DEFAULT_PATH, DETAILS_DEPENSE_PATH, DEPENSE_PATH, DETAILS_VENTES_PATH, FORGOT_PASSWORD_PATH, FOURNISSEURS_PATH, LOGIN_PATH, PARAMETTRES_PATH, PRODUCTS_PATH, PROFILE_PATH, UPDATE_VENTES_PATH, VENTES_PATH, ADD_DEPENSE_PATH, UPDATE_DEPENSE_PATH, DETAILS_CLIENTS_PATH, DETAIL_FOURNISSEURS_PATH } from "../utils/navigation_paths";
import DetailsClientPage from "../components/clients/DetailsClientPage";
import FournisseurDetail from "../components/fournisseurs/FournisseurDetail";
import Test from "../components/ventes/Test";
import NewVentePage from "../components/ventes/new/NewVentePage";
import NewUpdateVente from "../components/ventes/new/NewUpdateVente";
import NewDepensePage from "../components/depenses/new/NewDepensePage";
import NewUpdateDepense from "../components/facture/new/NewUpdateDepense";

const { default: LoginPage } = require("../components/login/LoginPage");
const { Navigate } = require("react-router-dom");

const protectedRoutes = [
    {
        /*
            DEFAULT ROUTE
            Look the UseEfeect function in th App.js
         */
        path: DEFAULT_PATH,
        element: <Navigate to={LOGIN_PATH} />
    },
    // {
    //     path:ACCUEIL_PATH,
    //     element: <AccueilPage/>
    // },
    {
        path: '',
        element: <Navigate to={LOGIN_PATH} />
    },
    {
        path: CLIENTS_PATH,
        element: <ListClients />
    },
    {
        path: DETAILS_CLIENTS_PATH,
        element: <DetailsClientPage />
    },
    {
        path: FOURNISSEURS_PATH,
        element: <ListFournisseur />
    },
    {
        path: PRODUCTS_PATH,
        element: <ListProduits />
    },
    {
        path: VENTES_PATH,
        element: <ListVentes />
    },
    {
        path: ADD_VENTES_PATH,
        // element: <AddVentePage />
        element: <NewVentePage />
    },
    {
        path: UPDATE_VENTES_PATH,
        // element: <UpdateVentePage />
        element: <NewUpdateVente />
    },
    {
        path: DETAILS_VENTES_PATH,
        element: <DetailsVentePage />
    },
    {
        path: DEPENSE_PATH,
        element: <ListDepenses />
    },
    {
        path: ADD_DEPENSE_PATH,
        // element: <AddDepensePage />
        element: <NewDepensePage />
    },
    {
        path: UPDATE_DEPENSE_PATH,
        // element: <UpdateDepenseFacture />
        element: <NewUpdateDepense />
    },
    {
        path: DETAILS_DEPENSE_PATH,
        element: <DetailsDepense />
    },
    {
        path: PARAMETTRES_PATH,
        element: <Parametre />
    },
    {
        path: PROFILE_PATH,
        element: <Profile />
    },
    {
        path: DETAIL_FOURNISSEURS_PATH,
        element: <FournisseurDetail />
    },
    {
        path: 'test',
        element: <Test />
    },
]

export const publicRoutes = [
    {
        path: LOGIN_PATH,
        element: <LoginPage />
    },
    // {
    //     path: REGISTER_PATH,
    //     element: <RegisterPage />
    // },
    {
        path: FORGOT_PASSWORD_PATH,
        element: <Forgotassword />
    },
]

export default protectedRoutes