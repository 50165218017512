const { default: axios } = require("axios")

const { ADD_VENTE_API_ROUTE, GET_VENTES_API_ROUTE, UPDATE_VENTE_API_ROUTE, DELETE_VENTE_API_ROUTE, SEND_VENTE_EMAIL_API_ROUTE, GET_USER_VENTES_API_ROUTE, GET_AGENCE_VENTES_API_ROUTE, CREATE_NEW_VENTE_API_ROUTE, DELETE_VENTE_ARITCLE_API_ROUTE } = require("../../routes/api_routes")
const { setSelectedArticlesSlice } = require("../reducers/factureArticles")
const { setCurrentVenteDetails } = require("../reducers/venteFacture")
const { addNewVente, setVentesList, updateVenteSlice, deleteVenteSlice, setCurrentVente } = require("../reducers/ventes")
const { setCurrenteFactureForUpdate, editProductArticleId } = require("../reducers/new/newFactureArticles")

module.exports = {
    getVentesList(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_USER_VENTES_API_ROUTE}${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data) {
                        let ventes = response.data
                        dispatch(setVentesList(ventes))
                        resolve(ventes)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    getAgencesVentes(agenceId, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_AGENCE_VENTES_API_ROUTE}${agenceId}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data) {
                        let ventes = response.data.data
                        // console.log({ ventes });
                        dispatch(setVentesList(ventes))
                        resolve(ventes)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    getVenteByID(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(GET_VENTES_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    // console.log({ response });
                    if (response.status === 200) {
                        let vente = response.data
                        // console.log({ vente });
                        // dispatch(setCurrentVente(vente))
                        // !recuperer les artcicles de vente pour modifier une vente 
                        dispatch(setSelectedArticlesSlice(vente))
                        // !recuperer les artcicles de vente pour afficher les details 
                        dispatch(setCurrentVenteDetails(vente))

                        // !pour afficher la nouvelle facture de vente et garder que les produits qui ne sont pas dans la facture
                        dispatch(setCurrenteFactureForUpdate(vente))
                        resolve(vente)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    createNewVente(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_VENTE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    // console.log({ response });
                    if (response.status === 201) {
                        let newVente = response.data
                        dispatch(addNewVente(newVente))
                        // dispatch(setCurrentVent(newVente))
                        resolve(newVente)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    createNewVenteArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(CREATE_NEW_VENTE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 201) {
                        let newArticleCreated = response.data.data
                        // dispatch(addNewVente(newVente))
                        /**
                         * ! ceci permet d'ajouter "articleId" sur le produit qu'on vient d'ajouter
                         */
                        dispatch(editProductArticleId(newArticleCreated))

                        // ! on recupere la vente pour affhicher les derniere modification sur les details de la facture
                        // this.getVenteByID(newArticleCreated.vente, dispatch, token)
                        resolve(newArticleCreated)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateVente(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let vente = response.data.data
                        // console.log({ vente });
                        dispatch(updateVenteSlice(vente))
                        dispatch(setCurrentVente(vente))
                        resolve(vente)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },
    deleteVente(id, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_VENTE_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        dispatch(deleteVenteSlice(id))
                        resolve(id)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteVenteArticle({ article, vente }, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_VENTE_ARITCLE_API_ROUTE + `${article.articleId}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        // console.log({ article, response });
                        // dispatch(deleteArticleByIdSlice(article))
                        // dispatch(removeArticleFromCurrentFacture(article))
                        // ! on recupere la vente pour affhicher les derniere modification sur les details de la facture
                        // this.getVenteByID(vente, dispatch, token)
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    sendEmailFacture(id, token) {
        return new Promise((resolve, reject) => {
            axios.get(SEND_VENTE_EMAIL_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        resolve('Email envoye')
                    }
                })
                .catch(err => reject(err))
        })
    },
}
