import { createSlice, current } from "@reduxjs/toolkit";

const defaultArticle = { product: null, quantity: '1', price: 0, errors: { quantity: null, readOnly: false } }

const newFactureArticles = createSlice({
    name: "newFactureArticles",
    initialState: {
        allProducts: [],
        allProductsNotAddedToCurrentFacture: [],
        productsNotAddedToCurrentFacture: [],
        productsToFilter: [],
        currentFacture: [],
        selectedFactureClient: null,
        selectedFactureFournisseur: null,
        disableProduct: false,
        isUpdatingFacture: false,
        isDeletingArticle: false,
        isFactureForDepense: false,
        selectedCategoryForProductSearch: { value: null, label: null }
    },
    reducers: {
        setProductsNotAddedToCurrentVente: (state, { payload }) => {
            state.allProducts = payload
            state.allProductsNotAddedToCurrentFacture = payload
            state.productsNotAddedToCurrentFacture = state.allProductsNotAddedToCurrentFacture
            state.productsToFilter = payload
        },
        addNewProduitFactureSlice: (state, { payload }) => {
            // console.log({ payload });
            state.allProducts = [payload, ...state.allProducts]
            state.productsToFilter = [payload, ...state.productsToFilter]
            state.allProductsNotAddedToCurrentFacture = [payload, ...state.allProductsNotAddedToCurrentFacture]
            if (state.selectedCategoryForProductSearch?.value) {
                if (state.selectedCategoryForProductSearch.value === payload?.category?.id) {
                    state.productsNotAddedToCurrentFacture = [payload, ...state.productsNotAddedToCurrentFacture]
                }
                return
            }
            // if (state.selectedCategoryForProductSearch === payload?.category?.categoryName) {
            state.productsNotAddedToCurrentFacture = [payload, ...state.productsNotAddedToCurrentFacture]
            // }
        },
        setCurrentFacture: (state, { payload }) => {
            state.currentFacture = payload
        },
        addNewArticlesToCurrentFacture: (state, { payload }) => {
            if (state.currentFacture.length) {
                // !si le produit est deja dans la facture on l'incremente sinon on l'ajoute
                let index = state.currentFacture.findIndex(article => article.product.id === payload.id)
                if (index !== -1) {
                    state.currentFacture = state.currentFacture.map(article => {
                        if (article.product.id === payload.id) {
                            if (state.isFactureForDepense) {
                                article = {
                                    ...article,
                                    quantity: Number(article.quantity) + 1, // !incrementation
                                    price: (Number(article.quantity) + 1) * payload.prixAchat // ! prixAchat au lieu de prixVente
                                }
                            }
                            else {
                                article = {
                                    ...article,
                                    quantity: Number(article.quantity) + 1, // !incrementation
                                    price: (Number(article.quantity) + 1) * payload.prixVente // ! prixVente au lieu de prixAchat
                                }
                            }
                        }
                        return article
                    })
                }
                if (index === -1) {
                    state.currentFacture = [
                        ...state.currentFacture,
                        {
                            ...defaultArticle,
                            product: payload,
                            price: state.isFactureForDepense ? payload.prixAchat : payload.prixVente
                        }
                    ]
                }
                return
            }

            state.currentFacture = [
                ...state.currentFacture,
                {
                    ...defaultArticle,
                    product: payload,
                    price: state.isFactureForDepense ? payload.prixAchat : payload.prixVente
                }
            ]
        },
        editProductArticleId: (state, { payload }) => {
            // console.log({ payload });
            state.currentFacture = state.currentFacture.map(article => {
                if (article.product.id === payload.productId) {
                    article = { ...article, articleId: payload.id }
                }
                return article
            })
        },
        removeArticleFromCurrentFacture: (state, { payload }) => {
            state.disableProduct = false
            // ! supprimer le produit dans les articles de ventes et l'ajouter sur productsNotAddedToCurrentFacture
            state.currentFacture = state.currentFacture.filter(article => article.product.id !== payload.product.id)
            // state.allProductsNotAddedToCurrentFacture = [payload.product, ...state.allProductsNotAddedToCurrentFacture]
            // state.productsNotAddedToCurrentFacture = state.allProductsNotAddedToCurrentFacture
        },
        editArticleQuantity: (state, { payload }) => {
            state.disableProduct = false
            state.currentFacture = state.currentFacture.map(article => {
                article.errors.readOnly = false
                if (article.product.id === payload.article.product.id) {
                    article = payload.article
                }
                return article
            })
        },
        setFactureSelectedClient: (state, { payload }) => {
            state.selectedFactureClient = payload
        },
        resetFactureSelectedClient: (state) => {
            state.selectedFactureClient = null
        },
        setFactureSelectedFournisseur: (state, { payload }) => {
            state.selectedFactureFournisseur = payload
        },
        resetFactureSelectedFournisseur: (state) => {
            state.selectedFactureFournisseur = null
        },
        setArticleErrors: (state, { payload }) => {
            state.disableProduct = true
            state.currentFacture = state.currentFacture.map(article => {
                article.errors.readOnly = true
                // console.log({ payload: payload.article });
                if (article.product.id === payload.article.product.id) {
                    article.errors.readOnly = false
                    article = payload.article
                }
                return article
            })
        },
        filterProductsNotAddedToCurrentFacture: (state, { payload }) => {
            state.productsNotAddedToCurrentFacture = state.productsToFilter.filter(product => {
                if (payload) {
                    const champ = [product.name].join('').toLocaleLowerCase()
                    return champ.includes(payload.toLocaleLowerCase())
                }
                return state.productsNotAddedToCurrentFacture = state.productsToFilter
            })
        },
        setIsUpdatingFacture: (state, { payload }) => {
            if (typeof payload !== 'boolean') {
                throw new TypeError('Veuillez fournir un Boolean')
            }
            return {
                ...state,
                isUpdatingFacture: payload
            }
        },
        setIsFactureForDepense: (state, { payload }) => {
            if (typeof payload !== 'boolean') {
                throw new TypeError('Veuillez fournir un Boolean')
            }

            return {
                ...state,
                isFactureForDepense: payload
            }
        },
        setIsDeletingArticle: (state, { payload }) => {
            if (typeof payload !== 'boolean') {
                throw new TypeError('Veuillez fournir un Boolean')
            }
            return {
                ...state,
                isDeletingArticle: payload
            }
        },
        setCurrenteFactureForUpdate: (state, { payload }) => {
            // const newProducts = []
            // let productIdsInCurrentFacture = []

            state.currentFacture = payload.articles.map(article => {
                // console.log({ payload: article });
                // productIdsInCurrentFacture.push(article.productId)
                return {
                    product: article.product,
                    quantity: article.quantity,
                    price: article.montant,
                    errors: {
                        quantity: null,
                        readOnly: false
                    },
                    articleId: article.id
                }
            })


            // state.allProducts = state.allProducts.map(product => {
            //     // ! on recupere que les produits qui ne sont pas sur la facture de vente
            //     if (productIdsInCurrentFacture.indexOf(product.id) === -1) {
            //         newProducts.push(product)
            //     }
            //     return product
            // })


            state.allProductsNotAddedToCurrentFacture = state.allProducts
            state.productsNotAddedToCurrentFacture = state.allProductsNotAddedToCurrentFacture
            // console.log({ 'allProducts': state.allProducts, allProductsNotAdded: state.allProductsNotAddedToCurrentFacture, articles: payload.articles });
        },
        setSelectedCategoryForProductSearch: (state, { payload }) => {
            console.log({ payload });
            state.selectedCategoryForProductSearch = payload
            if (!payload.value) {
                state.productsNotAddedToCurrentFacture = state.allProductsNotAddedToCurrentFacture
                state.productsToFilter = state.allProductsNotAddedToCurrentFacture
                return
            }
            state.productsNotAddedToCurrentFacture = state.allProductsNotAddedToCurrentFacture.filter((article) => article.category.id === payload.value)
            state.productsToFilter = state.allProductsNotAddedToCurrentFacture.filter((article) => article.category.id === payload.value)
        },
        resetSelectedCategoryForProductSearch: (state, { payload }) => {
            state.selectedCategoryForProductSearch = {}
        },
        resetCurrentFacture: (state) => {
            state.currentFacture = []
            state.productsNotAddedToCurrentFacture = [] 
            state.selectedCategoryForProductSearch = {}
            // state.selectedFactureClient = null
            state.disableProduct = false
        }
    }
})

export const {
    setProductsNotAddedToCurrentVente,
    addNewProduitFactureSlice,
    setCurrentFacture,
    addNewArticlesToCurrentFacture,
    removeArticleFromCurrentFacture,
    editArticleQuantity,
    setFactureSelectedClient,
    setFactureSelectedFournisseur,
    resetFactureSelectedClient,
    resetFactureSelectedFournisseur,
    resetCurrentFacture,
    filterProductsNotAddedToCurrentFacture,
    setArticleErrors,
    setIsFactureForDepense,
    setIsUpdatingFacture,
    setCurrenteFactureForUpdate,
    setIsDeletingArticle,
    editProductArticleId,
    setSelectedCategoryForProductSearch,
    resetSelectedCategoryForProductSearch
} = newFactureArticles.actions

export default newFactureArticles.reducer;