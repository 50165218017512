import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    agenceId: null,
    agence:{}
}

const agenceSlice = createSlice({
    name:"agence",
    initialState,
    reducers:{
        setCurrentAgence:(state, {payload}) => {
            // console.log({payload});
            state.agence = payload
            state.agenceId = payload.id
        }
    }
})

export const {setCurrentAgence} = agenceSlice.actions
export default agenceSlice.reducer