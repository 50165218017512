const { default: axios } = require("axios")
const {  ADD_COMPANY_API_ROUTE, GET_COMPANY_API_ROUTE, GET_COMPANY_BY_ID_API_ROUTE, UPDATE_COMPANY_API_ROUTE } = require("../../routes/api_routes")
const { addNewCompnay, selcetAllCompany ,selectCurrentCompany} = require("../reducers/campany")
const { setCurrentAgence } = require("../reducers/agence")

module.exports = {
    createCompany(data,token,dispatch){
        return new Promise((resolve, reject) =>{
            axios.post(ADD_COMPANY_API_ROUTE,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then( res =>{
                console.log('COMPANY ', res)
                if(res.status === 201){
                    let newCompnay = res.data
                    dispatch(selectCurrentCompany(newCompnay))

                    resolve(res)
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
        })
    },
    updateCompany(id,data, token,dispatch){
        return new Promise((resolve, reject) =>{
            // console.log({ id });
            axios.put(UPDATE_COMPANY_API_ROUTE+`${id}/`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then( res =>{
                if(res.status === 200){
                    let newCompnay = res.data.data
                    // console.log({ newCompnay });
                    dispatch(selectCurrentCompany(newCompnay))
                    dispatch(setCurrentAgence(newCompnay))
                    resolve(res)
                }
            })
            .catch((error) => reject(error))
        })
    },
    getCurrentCompany(id,dispatch, token){
        return new Promise((resolve, reject) =>{
            axios.get(GET_COMPANY_BY_ID_API_ROUTE+`${id}/`, {headers:{Authorization: `Bearer ${token}`}})
            .then( res =>{
                if(res.status === 200){
                    let company = res.data
                    dispatch(selectCurrentCompany(company))
                }
            }).catch( error => reject(error))
        })
    },
    getCompanies(dispatch, token){
        return new Promise((resolve, reject) =>{
            axios.get(GET_COMPANY_API_ROUTE, {headers:{Authorization: `Bearer ${token}`}})
            .then( res =>{
                if(res.data){
                    let companies = res.data
                    dispatch(selcetAllCompany(companies))
                    resolve(companies)
                }
            }).catch( error => reject(error))
        })
    }
}