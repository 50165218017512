import './App.css';
import { Route, Routes } from 'react-router-dom';
import navigation_routes, { authentication_routes, publicRoutes } from './routes/navigation_routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ACCUEIL_PATH, LOGIN_PATH, VENTES_PATH } from './utils/navigation_paths';
import ApiController from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './components/navbar/SideBar';
import { FORGOT_PASSWORD_PATH, REGISTER_PATH } from './utils/navigation_paths'
import AppLayout from './layout/AppLayout';
import { useAgenceId } from './hooks/useAgenceId';
import './styles.css'
import protectedRoutes from './routes/navigation_routes';
import PublicLayout from './layout/PublicLayout';


function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.users.currentUser)
  const isDrawerOpen = useSelector(state => state.navbar.isDrawerOpen)
  const agenceId = useAgenceId()

  // useEffect(() => {
  //   let token = currentUser?.token ? currentUser?.token?.token : null
  //   if (token) {
  //     ApiController.all.getAllData(token, dispatch, ApiController, agenceId)
  //     if (window.location.href.endsWith(LOGIN_PATH)) {
  //       navigate(VENTES_PATH)
  //     }
  //   } else {
  //     if (!window.location.pathname.endsWith(REGISTER_PATH)
  //       &&
  //       !window.location.pathname.endsWith(FORGOT_PASSWORD_PATH)) {
  //       navigate(LOGIN_PATH)
  //     }
  //   }

  // }, [dispatch, currentUser?.token])
  return (
    <div className="App container-fluid m-0 p-0 font-Montserrat">
      <ToastContainer />
      <Routes>
        <Route element={<AppLayout />}>
          {
            protectedRoutes.map((route, index) => {
              return <Route key={index} path={route.path} element={route.element} />
            })
          }
        </Route>
        <Route path='/' element={<PublicLayout />}>
          {
            publicRoutes.map((route, index) => {
              return <Route key={index} path={route.path} element={route.element} />
            })
          }
        </Route>
      </Routes>
      {isDrawerOpen &&
        <SideBar></SideBar>
      }
    </div>
  );
}

export default App;
