import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    companyInfo: [],
    companyList: [],
    currentCompany:{}
}
const companySlice = createSlice({
    name:'company',
    initialState,
    reducers:{
        addNewCompnay:(state,{payload})=>{
            state.companyInfo = [...state.companyInfo,payload].reverse()
            state.companyList = [...state.companyList,payload].reverse()
        },
        setCompanyInfo:(state,{payload})=>{
            state.companyInfo = payload
            state.companyList = payload
        },
        selectCurrentCompany:(state, {payload}) =>{
            state.currentCompany = payload
        },
        selcetAllCompany:(state, {payload}) =>{
            state.companyInfo = [...state.companyInfo,payload].reverse()
            state.companyList = [...state.companyList,payload].reverse()
        }
    }
})

export const {addNewCompnay,setCompanyInfo,selcetAllCompany, selectCurrentCompany} = companySlice.actions
export default companySlice.reducer