import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentProduit: {},
    produitsList: [],
    allProduitsList: [],
}
const produitsSlice = createSlice({
    name:'produits',
    initialState,
    reducers:{
        addNewProduit:(state,{payload})=>{
            // console.log({ payload });
            state.produitsList = [payload, ...state.allProduitsList]
            state.allProduitsList = [payload, ...state.allProduitsList]
        },
        updateProduitInList:(state,{payload})=>{
            let produits = state.allProduitsList.filter(prod => prod.id !== payload.id)
            produits = [payload,...produits]
            state.allProduitsList = produits
            state.produitsList = produits
        },
        archiveProduitInList:(state,{payload})=>{
            let produits = state.allProduitsList.filter(prod => prod.id !== payload.id)
            state.allProduitsList = produits
            state.produitsList = produits
        },
        setCurrentProduit:(state,{payload})=>{
            state.currentProduit = payload
        },
        setProduitsList:(state,{payload})=>{
            state.produitsList = payload
            state.allProduitsList = payload
        },
        filterProduitsList:(state,{payload})=>{
            if(payload === ''){
                state.produitsList = state.allProduitsList
            }else{
                let list = state.allProduitsList.filter(prod => {
                    return [prod.name, prod.category.categoryName].join('').toLowerCase().includes(payload.toLowerCase())
                })
                state.produitsList = list
            }
        },
        reInitializeProduits: (state) => {
            state.currentProduit = {}
            state.produitsList = []
            state.allProduitsList = []
        }
    }
})

export const { addNewProduit, reInitializeProduits, setCurrentProduit, setProduitsList, filterProduitsList, updateProduitInList, archiveProduitInList, reduceProductsQuantity } = produitsSlice.actions
export default produitsSlice.reducer