import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { BiUserCircle } from 'react-icons/bi'
import { BsBagPlus, BsTelephone } from 'react-icons/bs'
import { CgPentagonTopLeft } from 'react-icons/cg'
import { HiOutlineLocationMarker } from 'react-icons/hi'

const EntityDetails = ({
    firstName,
    lastName,
    currentCompanyName,
    email,
    telephone,
    adresse,
    entityList,
    columns,
    errorMessage,
    id }) => {
    return (
        <div className="row m-0 p-0 text-start p-4">
            <p className='fw-bold fs-4 mb-2'><span className='fw-normal'>{firstName}</span></p>
            <div className="col-12 m-auto fw-bold">
                <p>informations générales</p>
                <div className='row m-0 p-0 bg-light shadow-sm p-3'>
                    <div className="col-12 col-md p-3">
                        <p style={{ fontWeight: '200' }}><BiUserCircle className='fs-5' /> {firstName + ' ' + lastName}</p>
                        <p style={{ fontWeight: '200' }} className='mt-2'><BsBagPlus className='fs-5' /> {currentCompanyName || 'Volkeno'}</p>
                    </div>
                    <div className="col-12 col-md p-3">
                        <p className='d-flex gap-2' style={{ fontWeight: '200' }}><BiUserCircle className='fs-5' /> {email}</p>
                        <p style={{ fontWeight: '200' }} className='d-flex gap-2 mt-2'><BsTelephone className='fs-5' /> {telephone}</p>
                    </div>
                    <div className="col-12 col-md p-3">
                        {/* <p style={{ fontWeight: '200' }}><CgPentagonTopLeft className='fs-3   ' /> {id}</p> */}
                        <p style={{ fontWeight: '200' }} className='mt-2'><HiOutlineLocationMarker className='fs-5' /> {adresse}</p>
                    </div>
                </div>
                <p className='mt-4'>Activités</p>
                {entityList.length > 0 ? (
                    <BootstrapTable
                        striped
                        keyField='table'
                        classes='text-center'
                        data={entityList}
                        columns={columns}
                    />
                ) :
                    <p className='fs-4 text-secondary'>
                        {errorMessage}
                    </p>
                }
            </div>
        </div>
    )
}

export default EntityDetails