import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentFournisseur: {},
    fournisseurList: [],
    allFournisseur: [],
}

const fournisseurSlice = createSlice(
    {
        name: "fournisseurs",
        initialState,
        reducers: {
            getfournisseurList: (state, { payload }) => {
                state.fournisseurList = payload
                state.allFournisseur = payload

            },
            addFournisseur: (state, { payload }) => {
                state.allFournisseur = [payload, ...state.allFournisseur]
                state.fournisseurList = [payload, ...state.allFournisseur]
            },
            setCurrentFournisseur: (state, { payload }) => {
                console.log({ payload });
                state.currentFournisseur = payload
            }
            , updateFournisseur: (state, { payload }) => {
                let fournisseurs = state.allFournisseur.filter(fournisseur => fournisseur.id !== payload.id)
                fournisseurs = [payload, ...fournisseurs]
                state.allFournisseur = fournisseurs
                state.fournisseurList = fournisseurs
            },
            archiveFournisseurSlice: (state, { payload }) => {
                let fournisseurs = state.allFournisseur.filter(fournisseur => fournisseur.id !== payload.id)
                state.fournisseurList = fournisseurs
                state.allFournisseur = fournisseurs
            },
            filterFournisseuList: (state, { payload }) => {
                state.fournisseurList = state.allFournisseur.filter(item => {
                    let data = [item.firstName, item.telephone, item.lastName, item.email, item.adresse].join(' ').toLowerCase()
                    return data.includes(payload.toLowerCase())
                })
            }
        }
    }
)


export const { getfournisseurList, addFournisseur, setCurrentFournisseur, updateFournisseur, archiveFournisseurSlice, filterFournisseuList } = fournisseurSlice.actions

export default fournisseurSlice.reducer