const { default: axios } = require("axios")

const { GET_CATEGORIES_API_ROUTE, ADD_CATEGORY_API_ROUTE } = require("../../routes/api_routes")
const { addNewCategory,setCategoriesList } = require("../reducers/categories")

module.exports = {
    getCategoriesList(agenceId,dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(`${GET_CATEGORIES_API_ROUTE}${agenceId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                // console.log({ response });
                if(response.data){
                    let categories = response.data.data
                    dispatch(setCategoriesList(categories))
                    resolve(categories)
                }else{
                    reject(response)
                }
            })
        })
    },
    createNewCategory(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_CATEGORY_API_ROUTE,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                // console.log(response);
                if (response.status === 201) {
                    let newCategory = response.data.data
                    dispatch(addNewCategory(newCategory))
                    resolve(response)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    // updateClient(id,data,token,dispatch){
    //     return new Promise((resolve,reject)=>{
    //         axios.put(UPDATE_PRODUIT_API_ROUTE+id+'/' ,data,{headers:{ Authorization: `Bearer ${token}` }})
    //         .then(response =>{
    //             console.log(response);
    //             if(response.data.id){
    //                 let newProduct = response.data
    //                 dispatch(updateProduitInList(newProduct))
    //                 resolve(newProduct)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(err => reject(err))
    //     })
    // },
    // archiveProduct(prod,token,dispatch){
    //     prod.archived = true
    //     return new Promise((resolve,reject)=>{
    //         axios.put(UPDATE_PRODUIT_API_ROUTE+prod.id+'/' ,prod,{headers:{ Authorization: `Bearer ${token}` }})
    //         .then(response =>{
    //             console.log(response);
    //             if(response.data.id){
    //                 let newClient = response.data
    //                 dispatch(archiveProduitInList(newClient))
    //                 resolve(newClient)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(err => reject(err))
    //     })
    // }
}
