import React from 'react';
import './sidebar.css'
import { useDispatch, useSelector } from 'react-redux';
import {Sidebar, Item, Logo, } from 'react-sidebar-ui'
import { changeDrawerState, setCurrentItem } from '../../redux/reducers/navbar';
import navbar_items from '../../utils/navbar_items'
import { NavLink, useNavigate } from 'react-router-dom';
import logo_fewnu from '../../img/logo_fewnu_trans.png'

const SideBar = () => {
    
    const navigate  = useNavigate()
    const dispatch  = useDispatch()

    const isDrawerOpen = useSelector(state => state.navbar.isDrawerOpen)
    const currentItem = useSelector(state => state.navbar.currentItem)

    const closeSidebar = () => {
        dispatch(changeDrawerState())
    }
    
    const handleclick =(itemPath,dropdownPath)=>{
        closeSidebar()
        // dispatch(setCurrentItem(itemPath))
        // if(dropdownPath){
        //     navigate(dropdownPath)
        // }else{
        //     navigate(itemPath)
        // }
    }

    const sideBarItems = () => {      
        return navbar_items.map((item, index) => {
            return (
               
                <li className="sidebar_item position-relative">
                    <NavLink
                        className='stretched-link'
                        to={item.path}
                        //  bgColor={currentItem === item.path ? '#00BEC9' : 'black'}
                        onClick={() => handleclick(item.path)}
                    >
                        {/* <item.icon className='fs-2 mx-3 my-1 sidebar_icon' /> */}
                        {item.text}
                    </NavLink>
                </li>
            )
        // }
        })
    }
    return (
        <>
            {isDrawerOpen ?
                <div className='d-md-none'>
                    <div className='sidebar-overlay' onClick={closeSidebar}>
                        <Sidebar classes={['nav-menu vh-100']} bgColor='black' isCollapsed={false}>
                        <Logo
                                className="w-25"
                            image={logo_fewnu}
                            imageName='app logo'/>
                        <ul className='p-3'>
                        {
                            sideBarItems()
                        }
                        </ul>
                        </Sidebar> 
                    </div>           
                </div> : null
            }
        </>
    );
};

export default SideBar;