import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setLogout } from '../../redux/reducers/users';
import { LOGOUT_API_ROUTE } from '../../routes/api_routes';
import { PROFILE_PATH } from '../../utils/navigation_paths';
import './user.css'

const UserDropdownMenu = ({ secondary }) => {
    const navigate = useNavigate()
    const currentUser = useSelector(state => state.users.currentUser)
    const userNameClass = secondary ? "btn dropdown-toggle text-dark" : "btn dropdown-toggle text-white"
    const agence = useSelector(state => state.agence.agence)
    const dispatch = useDispatch()

    const disconnect = () => {

        dispatch({
            type: 'store/logout'
        })
        dispatch(setLogout())
        // axios.get(LOGOUT_API_ROUTE).then((res) => {
        //     console.log({ res });
        // })
        //     .catch(err => {
        //         console.log({ err });
        //     })
    }
    return (
        <div className='d-inline m-0 p-0 ms-auto row align-items-center'>
            <div className='text-md-end text-white row align-items-center'>
                <div className="dropdown">
                    <div className={userNameClass} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {agence.nomAgence}
                    </div>
                    <ul className="dropdown-menu p-2">
                        {/* <li><p className="dropdown-item custom-mouse-clickable m-0 py-1" onClick={() => navigate(PROFILE_PATH)}>Mon profile</p></li> */}
                        <li
                            className="dropdown-item custom-mouse-clickable m-0 py-1 w-100"
                            onClick={() => disconnect()}
                        >
                            Déconnexion
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserDropdownMenu;