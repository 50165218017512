import React from 'react'

const ButtonLoading = ({ disabled, loading, text, onClick }) => {
    return (
        <div className='text-end'>
            <button
                disabled={loading || disabled}
                style={{ opacity: loading || disabled ? 0.5 : 1 }}
                className='custom-button-secondary py-2 px-3 mt-3 rounded-0 hover-1'
                onClick={onClick}
            >
                {!loading ? text : "En cours..."}
            </button>
        </div>
    )
}

export default ButtonLoading