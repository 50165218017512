import React from "react";
import Modal from "react-modal";
import "./add_produits.css";
import { useSelector, useDispatch } from "react-redux";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import DeleteModal from "../../shared/DeleteModal";

const DeleteProduitsModal = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentProduit = useSelector((state) => state.produits.currentProduit);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      height: "44%",
      width: "50%",
      minWidth: "300px",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const archiveProduct = () => {
    ReduxAction.produits
      .archiveProduct(currentProduit, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Produit supprimé avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("La suppression n'a pas réuissi", toast_options);
      });
  };
  return (
    // <div>
    //     <Modal
    //         overlayClassName={'add-client-modal-overlay'}
    //         isOpen={modalIsOpen}
    //         onAfterOpen={afterOpenModal}
    //         onRequestClose={closeModal}
    //         style={customStyles}
    //         contentLabel="Example Modal"
    //     >
    //         <div className="row m-0 p-3 px-4">
    //             <div className="row m-0 p-0 mb-3">
    //                 <p className="col-10 fs-5 pe-3">Confirmer la suppression</p>
    //                 <p className="col text-end fw-bold fs-4"><span className=' border-dark rounded px-2 custom-mouse-clickable' onClick={closeModal}>X</span></p>
    //             </div>
    //             <hr />
    //             <p className="text-center my-3 text-center">
    //                 Voulez vous vraiment supprimer ce produit? cette action est irreversible
    //             </p>
    //             <hr />
    //             <p className="text-center">
    //                 <button type='submit' className='bg-secondary text-white shadow rounded border-0 py-2 px-4 mx-3 mt-3' onClick={closeModal}>Annuler</button>
    //                 <button type='submit' className='custom-button-secondary py-2 px-3 mx-3 mt-3' onClick={archiveProduct}>Supprimer</button>
    //             </p>
    //         </div>
    //     </Modal>
    // </div>
    <DeleteModal
      opened={modalIsOpen}
      onClosed={closeModal}
      //   loading={loading}
      onDelete={archiveProduct}
    />
  );
};

export default DeleteProduitsModal;
