import React from 'react';
import Modal from 'react-modal';
import {useSelector,useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import ReduxAction from '../../redux/actions';
import { toast } from 'react-toastify';
import toast_options from '../../utils/toast_options';
import { useState } from 'react';

const TVA_Modal = ({modalIsOpen,setIsOpen}) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.users.currentUser)
    const [isTVA,setIsTVA] = useState(false)
    const defaultMessage = 'Ce champ est obligatoire !'
    const {register,reset,formState:{errors},handleSubmit} = useForm()

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height:'48%',
            width:'30%',
            minWidth:'330px',
            shadow:'0px 0px 5px black',
            background:'#eee',
            borderRadius:'0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    
    function afterOpenModal() {
    }

    function closeModal() {
        reset()
        setIsOpen(false);
        setIsTVA(false)
    }

    const submit =(data)=>{
        data.user_id = currentUser.id

        ReduxAction.clients.createNewClient(data,currentUser.token.token,dispatch)
        .then(response=>{
            closeModal()
            toast.success('Client ajouté avec succés', toast_options);
        })
        .catch(err => {
            console.log(err)
            closeModal()
            toast.error('L\'ajout n\'a pas réuissi', toast_options);
        })
    }
    const changeState = ()=>{
        setIsTVA(!isTVA)
    }
    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <form className="font-Montserrat p-0 m-0" onSubmit={handleSubmit(submit)}>
                  <div className='m-md-2'>
                    <p className='fw-bold fs-5'>Paramètres de TVA</p>
                    <p>Entrez les Informations sur votre régime de TVA (taxe sur la valeur ajouté)</p>
                    <label className='add-client-input'>
                        Régime TVA
                        <select {...register('tva')} className='add-client-input py-1' onChange={e=>changeState(e.target.value)}>
                            <option value="none">Pas de TVA</option>
                            <option value="tva">TVA</option>
                        </select>
                    </label>
                    {isTVA &&
                        <label className='add-client-input mt-3'>
                            Montant de la TVA
                            <input {...register('montant')} className='add-client-input' type={'text'} ></input>
                        </label>
                    }
                  </div>
                  <div className='text-center  mt-3'>
                    <button type='button' onClick={closeModal} className='custom-button-primary shadow py-2 px-3 mx-3'>Annuler</button>
                    <button className='custom-button-secondary shadow py-2 mx-3'>Enregistrer</button>
                  </div>
                </form>
            </Modal>
        </div>
    );
};

export default TVA_Modal;