import React from 'react'
import { AiFillPlusCircle } from 'react-icons/ai'
import { RiSearch2Line } from 'react-icons/ri';

const ServicesComponent = ({ title, description, labelIcon, input, onClick1, firstBtnText, firstBtn, onClick2, secondBtnText, secondBtn, onChange }) => {
  return (
    <div className='row m-0 text-start my-4 justify-content-center app-padding'>
      <p className='fs-1 fw-bold my-0'>{title}</p>
      <p className='mt-2'>{description}</p>
      <div className="row m-0 p-0 my-sm-3 my-2">
        <div className="mx-0 p-0 col-12 col-md-6 position-relative">
          <label className='input-search-container w-100'>
            <RiSearch2Line className='icon-search mx-0 ' />
            <input name='search' className='input-search' type="text" onChange={onChange} />
          </label>
        </div>
        <div className="group-btn my-md-0 col-12 col-md-6 mt-3 mt-md-0 p-0 gap-2">
          {
            firstBtnText ? <button
            className="btn-add p-2 px-3"
            type="button"
            onClick={onClick1}
          >
            {firstBtnText} <AiFillPlusCircle className="fs-4" />
            </button> : null
          }
          {/* <button className='btn btn-add-client me-3 rounded-0 shadow' onClick={onClick1}>{firstBtnText}<span className='service-btn-icon'>{firstBtn}</span></button> */}
          {

            secondBtnText && <button
              className="btn-add p-2 px-3"
              type="button"
              onClick={onClick2}
            >
              {secondBtnText} <AiFillPlusCircle className="fs-4" />
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default ServicesComponent