import React from "react";
// import Modal from 'react-modal';
import "./add_clients.css";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import Modal from "../../shared/Modal";

const UdateClientModal = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentClient = useSelector((state) => state.clients.currentClient);
  const defaultMessage = "Ce champ est obligatoire !";
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: currentClient,
    values: currentClient,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      height: "65%",
      width: "60%",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    reset();
    setIsOpen(false);
  }

  const submit = (data) => {
    let { firstName, lastName, telephone, adresse, email } = data;
    let user = { firstName, lastName, telephone, adresse, email };
    ReduxAction.clients
      .updateClient(currentClient.id, user, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Client modifié avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("La modification n'a pas réuissi", toast_options);
      });
  };
  return (
    <div>
      <Modal
        title={"Mettre à jour un client"}
        opened={modalIsOpen}
        onClosed={closeModal}
      >
        <form className="p-3" onSubmit={handleSubmit(submit)}>
          <div className="row my-md-3">
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Prénom
                <input
                  type="text"
                  {...register("firstName", { required: defaultMessage })}
                  className="form-control p-2"
                />
                {errors.firstName && (
                  <span className="custom-color-danger">
                    {errors.firstName.message}
                  </span>
                )}
              </label>
            </div>
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Nom
                <input
                  type="text"
                  {...register("lastName", { required: defaultMessage })}
                  className="form-control p-2"
                />
                {errors.lastName && (
                  <span className="custom-color-danger">
                    {errors.lastName.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Email
                <input
                  type="email"
                  required
                  {...register("email", { required: defaultMessage })}
                  className="form-control p-2"
                />
                {errors.email && (
                  <span className="custom-color-danger">
                    {errors.email.message}
                  </span>
                )}
              </label>
            </div>
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Mobile
                <input
                  type="number"
                  {...register("telephone", { required: defaultMessage })}
                  className="form-control p-2"
                />
                {errors.telephone && (
                  <span className="custom-color-danger">
                    {errors.telephone.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            {/* <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Référence
                            <input type='number' {...register('reference')} className='form-control p-2' />
                            {errors.reference && <span className='custom-color-danger'>{errors.reference.message}</span>}
                        </label>
                    </div> */}
            <div className="col-12 col-md mx-md-3 add-client-input">
              <label className="add-client-input">
                Adresse
                <input
                  type="text"
                  {...register("adresse", { required: defaultMessage })}
                  className="form-control p-2"
                />
                {errors.adresse && (
                  <span className="custom-color-danger">
                    {errors.adresse.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <p className="text-end mt-3 p-0 m-0">
            <button
              type="submit"
              className="custom-button-secondary py-2 px-3 mx-3 mt-3"
            >
              Enregistrer
            </button>
          </p>
        </form>
      </Modal>
    </div>
  );
};

export default UdateClientModal;
