import React from "react";
import Modal from "./Modal";

const DeleteModal = ({ opened, title, loading, onDelete, onClosed }) => {
  return (
    <Modal
      opened={opened}
      onClosed={onClosed}
      title={title ?? "Confirmer la suppression"}
    >
      <div className="row m-0 p-4">
        {/* <div className="row m-0 p-0">
          <p className="fs-4 px-3 text-center mb-1">Confirmer la suppression</p>
        </div>
        <hr /> */}
        <p className="text-center my-4">
          Voulez vous vraiment supprimer cette facture? cette action est
          irreversible
        </p>
        <hr />
        <p className="text-center">
          <button
            type="submit"
            className="bg-secondary text-white shadow rounded border-0 py-2 px-4 mx-3 mt-3"
            onClick={onClosed}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="custom-button-secondary py-2 px-3 mx-3 mt-3"
            onClick={onDelete}
          >
            {!loading ? "Supprimer" : "Suppression en cours..."}
          </button>
        </p>
      </div>
    </Modal>
    // </div>
  );
};

export default DeleteModal;
