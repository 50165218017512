import React, { cloneElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ResourceDataMapperWithEmptyMessage from '../../shared/ResourceDataMapperWithEmptyMessage'
import ProductFactureListItem from './ProductFactureListItem'
import { addNewArticlesToCurrentFacture, setSelectedCategoryForProductSearch } from '../../../redux/reducers/new/newFactureArticles'
import FilterProduct from './FilterProduct'
import Select from 'react-select';
import { optionsStyle } from '../../../utils/reactSelectStyles'
// import { useForm } from 'react-hook-form'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import AddCategoryModal from '../../categories/AddCategoryModal'
import AddProduitsModal from '../../produits/AddProduitsModal'
import ReactTooltip from 'react-tooltip'
import tooltip_options from '../../../utils/tooltip_options'

const AddProductToArticlesFacture = ({ createNewFactureArticle, loadingResource }) => {
    const dispatch = useDispatch()
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const { productsNotAddedToCurrentFacture, isUpdatingFacture, selectedCategoryForProductSearch } = useSelector(state => state.newFactureArticles)
    const categoriesList = useSelector(state => state.categories.categoriesList) || []
    // const { register } = useForm()
    // console.log({ productsNotAddedToCurrentFacture });
    const addNewProductToFactureArticles = (product) => {
        if (isUpdatingFacture) {
            return createNewFactureArticle(product)
        }
        dispatch(addNewArticlesToCurrentFacture(product))
    }

    const addNewCategory = () => {
        setAddModalIsOpen(true)
    }
    const categoriesOptions = categoriesList?.map(category => {
        return { value: category.id, label: category.categoryName }
    })
        .concat([{ value: null, label: 'Toutes les catégories' }])
        .reverse();

    const ClonedProductFactureListItem = ({ product }) => cloneElement(<ProductFactureListItem product={product} />, { addNewProductToFactureArticles })

    return (
        <div className='pt-0 card'>
            <AddProduitsModal modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
            {/* <AddCategoryModal modalIsOpen={categoryModalIsOpen} setIsOpen={setAddModalIsOpen}></AddCategoryModal> */}
            <h5 className='p-2 py-3 m-0 bg-light text-start fs-6 fw-bold'>Section Produit</h5>
            <div className='row'>
                <div className='col-md-6 col-12 p-2'>
                    <div className='row'>

                            <Select
                                // classNames="p-3"
                                // styles={{ padding: '1rem' }}
                                styles={optionsStyle}
                                classNamePrefix="p-2"
                                placeholder="Toutes les catégories"
                                // className='p-5'
                                autoFocus
                                // {...register('concerned')}
                                value={selectedCategoryForProductSearch}
                                onChange={(value) => dispatch(setSelectedCategoryForProductSearch(value))}
                                options={categoriesOptions}
                        />
                    </div>
                </div>
                <div className='col-md-6 col-12 p-2'>
                    <div className='row'>
                        <div className='col-10'>
                            <FilterProduct />
                        </div>
                        <div className='col-2 ps-2'>
                            <button
                                onClick={addNewCategory}
                                type="button"
                                className='btn btn-add rounded-circle p-2 py1 w-100'
                            >
                                <AiOutlinePlusCircle
                                    data-tip
                                    data-for="addProduct"
                                    size={23}
                                />
                            </button>
                            <ReactTooltip id="addProduct" {...tooltip_options}  >
                                Nouveau produit
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className='articles-grid card-body p-2'>
                <ResourceDataMapperWithEmptyMessage
                    isLoading={loadingResource}
                    resourceItem={ClonedProductFactureListItem}
                    resourceName="product"
                    resourceData={productsNotAddedToCurrentFacture}
                    emptyMessage="Pas encore de produit"
                />
            </div>
        </div>
    )
}

export default AddProductToArticlesFacture