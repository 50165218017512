const categories = require("./categories")
const clients = require("./clients")
const fournisseurs = require("./fournisseurs")
const produits = require("./produits")
const ventes = require("./ventes")

module.exports = {

    getAllData(token, dispatch, ReduxAcions, id) {
        return new Promise((resolve, reject) => {
            // now we will get the clients list
            ReduxAcions?.clients.getClientsList(id, dispatch, token)
                .catch(err => reject(err))

            // // now we will get the shops list
            // ReduxAcions.shops.getShopsList(dispatch,token)
            // .catch(err => reject(err))

            // now we will get the shops list
            // ReduxAcions?.statistic.getStatistics(dispatch,token)
            // .catch(err => reject(err))

            // now we will get the fournisseurs list
            // ReduxAcions?.fournisseurs.fetchFournisseurs(id,dispatch, token)
            // .catch(err => reject(err))

            //         // now we will get the products list
            // ReduxAcions?.produits.getProductsList(id,dispatch,token)
            // .catch(err => reject(err))

            // now we will get the ventes list
            ReduxAcions?.ventes.getVentesList(id, dispatch, token)
                .catch(err => reject(err))

            // now we will get the categories list
            ReduxAcions?.categories.getCategoriesList(id, dispatch, token)
                .catch(err => reject(err))

            // now we will get the depenses list
            // ReduxAcions?.depenses.getDepenseList(id,dispatch, token)
            // .catch(err => reject(err))

            //get company list

            // ReduxAcions?.company.getCurrentCompany(id,dispatch,token)
            // .catch( error => reject(error))
        })
    },

    getAgenceData(agenceId, dispatch, token) {
        return new Promise((resolve, reject) => {
            ventes.getAgencesVentes(agenceId, dispatch, token)
            categories.getCategoriesList(agenceId, dispatch, token)
            produits.getProductsList(agenceId, dispatch, token)
            clients.getClientsList(agenceId, dispatch, token)
            fournisseurs.fetchFournisseurs(agenceId, dispatch, token)
        }) 
    }
}