const { default: axios } = require("axios")

const { UPDATE_VENTE_API_ROUTE, ADD_VENTE_ARITCLE_API_ROUTE, DELETE_VENTE_ARITCLE_API_ROUTE, UPDATE_VENTE_ARITCLE_API_ROUTE } = require("../../routes/api_routes")
const { updateArticleByIdSlice } = require("../reducers/factureArticles")
const { setCurrentVent, saveNewArticle } = require("../reducers/venteFacture")


module.exports = {
    createNewArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_VENTE_ARITCLE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 201) {
                        let newArtcle = response.data
                        dispatch(saveNewArticle(newArtcle))
                        resolve(newArtcle)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateArticle(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_ARITCLE_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let article = response.data.data
                        // console.log({ article });
                        dispatch(updateArticleByIdSlice(article))
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteArticle(article, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_VENTE_ARITCLE_API_ROUTE + `${article.articleId}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        console.log({ article, response });
                        // dispatch(deleteArticleByIdSlice(article))
                        // dispatch(removeArticleFromCurrentFacture(article))
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    changeVenteClient(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    console.log(response);
                    if (response.data.id) {
                        let newVente = response.data
                        dispatch(setCurrentVent(newVente))
                        resolve(newVente)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
}
