import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    categoriesList: [],
    allCategoriesList: [],
}
const categoriesSlice = createSlice({
    name:'categories',
    initialState,
    reducers:{
        addNewCategory:(state,{payload})=>{
            state.categoriesList = [...state.allCategoriesList,payload].reverse()
            state.allCategoriesList = [...state.allCategoriesList,payload].reverse()
        },
        setCategoriesList:(state,{payload})=>{
            state.categoriesList = payload
            state.allCategoriesList = payload
        },
    }
})

export const {addNewCategory,setCategoriesList} = categoriesSlice.actions
export default categoriesSlice.reducer