import React, { useState } from "react";
import PortalReactDOM from "react-dom";
// import Modal from "react-modal";
import "./add_produits.css";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Select from "react-select";
import AddCategoryModal from "../categories/AddCategoryModal";
import { isValidNumber, isValidText } from "../../utils/form_control";
import { createPortal } from "react-dom";
import Modal from "../../shared/Modal";

const UdateProduitModal = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);

  const categoriesList = useSelector(
    (state) => state.categories.categoriesList
  );
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentProduit = useSelector((state) => state.produits.currentProduit);
  const [selectedCategory, setSelectedCategory] = useState({
    value: currentProduit.category.id,
    label: currentProduit.category.categoryName,
  });
  const defaultMessage = "Ce champ est obligatoire !";
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: currentProduit,
  });

  const customStyles = {
    content: {
      zIndex: "99999",
      top: "50%",
      left: "50%",
      height: "75%",
      width: "60%",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    reset();
    setIsOpen(false);
  }

  const submit = (data) => {
    data.createdBy = currentUser.id;
    data.categoryId = selectedCategory.value;
    let {
      name,
      categoryId,
      minStock,
      prixAchat,
      prixVente,
      quantite,
      createdBy,
    } = data;

    let formData = new FormData();
    formData.append("name", name);
    formData.append("categoryId", categoryId);
    // formData.append('image',image[0])
    formData.append("minStock", minStock);
    formData.append("prixAchat", prixAchat);
    formData.append("prixVente", prixVente);
    formData.append("quantite", quantite);
    formData.append("createdBy", createdBy);

    ReduxAction.produits
      .updateProduit(
        currentProduit.id,
        formData,
        currentUser.token.token,
        dispatch
      )
      .then((response) => {
        closeModal();
        toast.success("Produit ajouté avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("L'ajout n'a pas réuissi", toast_options);
      });
  };

  const verificate = (data) => {
    if (!selectedCategory) {
      setError("categoryId", { message: defaultMessage });
    } else if (!isValidText(data.name)) {
      setError("name", { message: "Nom non valide" });
    } else if (!isValidNumber(data.quantite)) {
      setError("quantite", { message: "Quantité non valide" });
    } else if (parseInt(data.quantite) <= 0) {
      setError("quantite", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.minStock)) {
      setError("minStock", { message: "Numéro non valide" });
    } else if (parseInt(data.minStock) <= 0) {
      setError("minStock", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.prixAchat)) {
      setError("prixAchat", { message: "Prix non valide" });
    } else if (parseInt(data.prixAchat) <= 0) {
      setError("prixAchat", { message: "La valeur minimale est de 1" });
    } else if (!isValidNumber(data.prixVente)) {
      setError("prixVente", { message: "Prix non valide" });
    } else if (parseInt(data.prixVente) <= 0) {
      setError("prixVente", { message: "La valeur minimale est de 1" });
    } else {
      submit(data);
    }
  };

  const addNewCategory = () => {
    setCategoryModalIsOpen(true);
  };
  const categoriesOptions = categoriesList
    .map((category) => {
      return { value: category.id, label: category.categoryName };
    })
    .reverse()
    .concat([
      {
        value: "+",
        label: (
          <span
            className="custom-mouse-clickable text-secondary"
            onClick={addNewCategory}
          >
            <AiOutlinePlusCircle /> Ajouter un produit
          </span>
        ),
      },
    ])
    .reverse();

  return createPortal(
    <>
      <div>
        <Modal
          title={"Mettre à jour un produit"}
          opened={modalIsOpen}
          onClosed={closeModal}
        >
          <form className=" p-3" onSubmit={handleSubmit(verificate)}>
            <div className="row my-md-3">
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  Nom du produit
                  <input
                    type="text"
                    {...register("name", { required: defaultMessage })}
                    className="add-client-input form-control p-2"
                  />
                  {errors.name && (
                    <span className="custom-error-msg">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  Catégorie
                  <Select
                    {...register("categoryId")}
                    value={selectedCategory}
                    onChange={(category) => setSelectedCategory(category)}
                    options={categoriesOptions}
                  />
                  {errors.categoryId && (
                    <span className="custom-error-msg">
                      {errors.categoryId.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="row my-md-3">
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  Quantité en stock
                  <input
                    type="number"
                    {...register("quantite", { required: defaultMessage })}
                    className="add-client-input form-control p-2"
                  />
                  {errors.quantite && (
                    <span className="custom-error-msg">
                      {errors.quantite.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  "Quantité en seuil"
                  <input
                    type="number"
                    required
                    {...register("minStock", { quantite: defaultMessage })}
                    className="add-client-input form-control p-2"
                  />
                  {errors.minStock && (
                    <span className="custom-error-msg">
                      {errors.minStock.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="row my-md-3">
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  Prix d'achat
                  <input
                    type="number"
                    {...register("prixAchat", { required: defaultMessage })}
                    className="add-client-input form-control p-2"
                  />
                  {errors.prixAchat && (
                    <span className="custom-error-msg">
                      {errors.prixAchat.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                <label className="add-client-input">
                  Prix de vente
                  <input
                    type="number"
                    {...register("prixVente", { required: defaultMessage })}
                    className="add-client-input form-control p-2"
                  />
                  {errors.prixVente && (
                    <span className="custom-error-msg">
                      {errors.prixVente.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            {/* <div className="row my-md-3">
                                <div className="col-12 col-md mx-md-3 add-client-input mt-2 mt-md-0">
                                    <label className='add-client-input'>
                                        Image
                                        <input accept='.png,.jpg,.jpeg,.svg' type='file' {...register('image')} className='add-client-input form-control p-2' />
                                        {errors.image && <span className='custom-error-msg'>{errors.image.message}</span>}
                                    </label>
                                </div>
                            </div> */}
            <p className="text-end mt-3">
              <button
                type="submit"
                className="custom-button-secondary py-2 px-3 mx-3 mt-3"
              >
                Enregistrer
              </button>
            </p>
          </form>
        </Modal>
        <AddCategoryModal
          modalIsOpen={categoryModalIsOpen}
          setIsOpen={setCategoryModalIsOpen}
        ></AddCategoryModal>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default UdateProduitModal;

export const ModalPortal = (component) => {
  const modalRoot = document.getElementById("modal-root");

  return createPortal(component, modalRoot);
};

// export const MyComponent = ({ component }) => ReactDOM.createPortal(component, 'dom-location')
