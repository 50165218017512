import React, { useEffect, useState } from 'react'
import AddProductToArticlesFacture from './AddProductToArticlesFacture'
import NewFactureEditArticle from './NewFactureEditArticle'
import { resetCurrentFacture } from '../../../redux/reducers/new/newFactureArticles'
import { useDispatch } from 'react-redux'
import ApiController from '../../../redux/actions'
import { useAgenceId } from '../../../hooks/useAgenceId'
import { useToken } from '../../../hooks/useToken'

const NewFactureArticles = ({
    children,
    deleteFactureArticle,
    createNewFactureArticle,
    loadingResource,
    updateArticleQuantityOnChange
}) => {
    const dispatch = useDispatch()
    const [selectComponent, footerComponent] = children
    const token = useToken()
    let agenceId = useAgenceId()
    const [loadingProduct, setLoadingProduct] = useState()

    useEffect(() => {
        dispatch(resetCurrentFacture())
        setLoadingProduct(true)
        ApiController.produits.getProductsList(agenceId, dispatch, token).finally(() => { setLoadingProduct(false) })
        ApiController.categories.getCategoriesList(agenceId, dispatch, token)
    }, [agenceId, dispatch, token])

    return (
        <div className='row facture-articles'>
            <div
                className='col-lg-7 pe-0 pe-lg-4 col-12 mb-4'
            >
                <AddProductToArticlesFacture
                    loadingResource={loadingResource || loadingProduct}
                    createNewFactureArticle={createNewFactureArticle}
                />
            </div>
            <div
                style={{ maxHeight: '500px', overflow: 'auto' }}
                className='col-lg-5 col-12 mb-3 card'
            >
                <h5 className='p-2 py-3 m-0 bg-light text-start fs-6 fw-bold'>Rubrique facturation</h5>
                {selectComponent}
                <NewFactureEditArticle
                    loadingResource={loadingResource}
                    deleteFactureArticle={deleteFactureArticle}
                    updateArticleQuantityOnChange={updateArticleQuantityOnChange}
                >
                    {footerComponent}
                </NewFactureEditArticle>
            </div>

        </div>
    )
}

export default NewFactureArticles