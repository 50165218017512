import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FactureProgressBar from './FactureProgressBar';
import { UPDATE_VENTES_PATH } from '../../utils/navigation_paths';
import { useNavigate } from 'react-router-dom';
import ReduxAction from '../../redux/actions';
import DeleteVenteModal from './DeleteVenteModal';
import PaymentVenteModal from './PaymentVenteModal';
import SendEmailModal from './SendEmailModal';
import { useToken } from '../../hooks/useToken';
import { reinitializeArticlesState } from '../../redux/reducers/factureArticles';
import { initializeCurrentDepenseDetails } from '../../redux/reducers/depenseFacture';
import FactureDetails from '../facture/FactureDetails';
import { setSelectedClient } from '../../redux/reducers/venteFacture';
import { useMemo } from 'react';
import { setFactureSelectedClient, setIsUpdatingFacture } from '../../redux/reducers/new/newFactureArticles';
import SkeletonTableRow from '../shared/SkeletonTableRow';

const DetailsVentePage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loadingFacture, setLoadingFacture] = useState(false)
    const currentVente = useSelector(state => state.ventes.currentVente)
    const { currentVenteDetails } = useSelector(state => (state.venteFacture))
    const currentCompany = useSelector(state => state.company.currentCompany)
    const token = useToken()
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
    const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
    let factureDate = new Date(currentVenteDetails?.vente?.created_at)
    let total = currentVenteDetails?.montantTotal || 0

    // console.log({ currentVenteDetails, currentVente });

    const venteTableFormat = currentVenteDetails?.articles

    const columns = useMemo(() => [
        {
            dataField: "product.name",
            text: "Désignation",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "quantity",
            text: "Quantité",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "product.prixVente",
            text: "Prix Unitaire",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "montant",
            text: "Total",
            headerClasses: 'custom-table-column text-white',
        }
    ], [])

    useEffect(() => {
        dispatch(setIsUpdatingFacture(false))
        dispatch(reinitializeArticlesState())
        dispatch(initializeCurrentDepenseDetails())
        setLoadingFacture(true)
        ReduxAction.ventes.getVenteByID(currentVente.id, dispatch, token).finally(() => {
            setLoadingFacture(false)
        })
    }, []);



    function openPaymentModal() {
        setPaymentModalIsOpen(true);
    }

    const onEdit = () => {
        // console.log({ currentVente });
        // ! on active la mise a jour
        dispatch(setIsUpdatingFacture(true))
        if (currentVente.client) {
            let clientId = currentVente.client.id
            let fullName = currentVente.client.firstName + ' ' + currentVente.client.lastName
            // ! we need the selected client to show in the select form when we edit
            // dispatch(setSelectedClient({ value: clientId, label: fullName }))
            dispatch(setFactureSelectedClient({ value: clientId, label: fullName }))
        }
        navigate(UPDATE_VENTES_PATH + `?id=${currentVente.id}`)
    }
    // console.log({ currentVente });
    return (
        <>
            {/* <Navbar/> */}
            <DeleteVenteModal modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} shouldNavigate={true} />
            {emailModalIsOpen && (
                <SendEmailModal modalIsOpen={emailModalIsOpen} setIsOpen={setEmailModalIsOpen} />
            )}
            <PaymentVenteModal modalIsOpen={paymentModalIsOpen} setIsOpen={setPaymentModalIsOpen} somme_total={total} />
            <FactureDetails
                factureFor="Client"
                loadingFacture={loadingFacture}
                dataTable={venteTableFormat}
                columns={columns}
                factureProgressBar={FactureProgressBar}
                transactionStatus={currentVente.status}
                totalTransaction={total}
                logo={currentCompany.logo}
                fullName={currentVente.client?.firstName ? currentVente.client?.firstName + ' ' + currentVente?.client?.lastName : "......................................."}
                phone={currentVente.client?.telephone ?? '.............................'}
                // messageOnEmptyFullName="Pas de client"
                factureDate={factureDate ?? '.....................'}
                numbreJoursRestants={0}
                navigateToEdit={UPDATE_VENTES_PATH}
                currentTransactionId={currentVente.id}
                transactionDate={currentVente.date}
                openPaymentModal={openPaymentModal}
                onEdit={onEdit}
            />

            {/* <div className='row m-0 p-2 p-md-5 custom-height-max bg-light text-start'>
                <p className='fs-2 fw-bold my-0'>Détails de la facture</p>
                <p className='mb-4'>Voici votre facture, vous pouvez la télécharger ou l'imprimer directement</p>
                <div className='row m-0 p-0'>
                    <div className="col-12 col-md-12 col-xl-9 pdf-container mb-4 bg-white shadow-sm border">
                        <PDFExport ref={pdfExportComponent} paperSize="A4">
                            <div id='facture' className='row m-3' ref={el => (factureRef = el)} >
                                <div className='row'>
                                    <p className="col">
                                        <img
                                            src={`${DEFAULT_API}${currentCompany.logo}`}
                                            alt="logo entreprise"
                                            className="border-ligth text-start h-70git me-3"
                                            onError={e => e.target.src = 'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'}
                                            style={{ width: '20%' }}
                                        />
                                        <span className='fw-bold'>{nomAgence}</span>
                                    </p>
                                    <p className="col text-end align-items-center row">
                                        <span className='fw-bold'>A: <span className='fw-normal text-decoration-underline'>
                                            {
                                                currentVente.client ? currentVente.client_info?.firstName + ' ' + currentVente.client_info?.lastName : <span className='text-danger'>Pas de client defini</span>
                                            }
                                        </span>
                                        </span>
                                    </p>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '500px' }}>
                                        <div className="row my-3">
                                            <div className="col-3">
                                                <p className='p-0 m-0 fw-bold'>Adresse facture</p>
                                                <p className='p-0 m-0'>{agenceAdresse}</p>
                                            </div>
                                            <div className="col-3">
                                                <p className='p-0 m-0 fw-bold'>Adresse de livraison</p>
                                                <p className='p-0 m-0'>{currentVente.client_info?.adresse ?? "Pas d'adresse choisie"}</p>
                                            </div>
                                            <div className="col-3">
                                                <p className='p-0 m-0 fw-bold'>Date Facture</p>
                                                <p className='p-0 m-0'>{factureDate?.toLocaleDateString('fr-SN')}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <p className='text-end'>à payer dans {numbreJoursRestants ?? ""} jours</p>
                                        </div>
                                        <div className='text-center'>
                                            <BootstrapTable
                                                striped
                                                keyField='table'
                                                data={venteTableFormat || []}
                                                columns={columns}
                                            />
                                        </div>
                                        <div>
                                            <div className='d-flex justify-content-end mt-3'>
                                                <div style={{ width: '380px' }} className='pt-3 p-2 shadow-sm border mt-1 text-end'>
                                                    <span className='total fs-3 fw-bold '>Total {total} XOF </span>
                                                    <span className='total fs-6 fw-light me-5 mb-5'>({currentVente.list_products?.length} article{currentVente.list_products?.length > 1 && 's'})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                    <div className='col-12 col-md-12 mb-4 col-xl-3 ps-xl-3'>
                        <div className='text-center facture-paiment-card mb-4 custom-card-shadow'>
                            <div className='d-block'>
                                <FactureProgressBar />
                            </div>
                            <div className='bg-white'>
                                <div className="row">
                                    <div className="col text-start px-4 py-2">
                                        <span className='fw-bold'>Payé</span>
                                        <p className=''>{currentVente.status === 'ENCOURS' ? '00' : total} XOF</p>
                                    </div>
                                    <div className="col text-end px-4 py-2">
                                        <span className='fw-bold'>En cours</span>
                                        <p className=''>{currentVente.status === 'ENCOURS' ? total : '00'} XOF</p>
                                    </div>
                                </div>
                                <div className="row px-4 pb-3">
                                    {currentVente.status === 'ENCOURS' ?
                                        <button onClick={openPaymentModal} className='custom-button-secondary py-2 fw-bold rounded-0 shadow-none'>Paiment facture</button>
                                        :
                                        <p className='fs-90 text-start'><span className='text-decoration-underline custom-color-primary'>Payé</span> le {new Date(currentVente.date).toLocaleDateString('fr-SN')}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='text-center bg-white facture-card-links text-start custom-card-shadow'>
                            <div className='row text-start p-3'>
                                <div className="col" style={{ opacity: currentVente.status === 'ENCOURS' ? 1 : 0.7 }}>
                                    <HiMail className='custom-color-primary fs-3' />&nbsp;
                                    <p onClick={() => currentVente.status === 'ENCOURS' && sendEmail()} className='text-dark fs-70 custom-mouse-clickable'>Email</p>
                                </div>
                                <div className="col ">
                                    <IoMdPrint className='custom-color-primary fs-3' />&nbsp;
                                    <ReactToPrint
                                        trigger={() => {
                                            return <p className='text-dark fs-70 custom-mouse-clickable'>Imprimer</p>;
                                        }}
                                        content={() => factureRef}
                                    />
                                </div>
                            </div>
                            <div className='row text-start p-3'>
                                <div className="col" style={{ opacity: currentVente.status === 'ENCOURS' ? 1 : 0.7 }}>
                                    <AiFillEdit className='custom-color-primary fs-3' />&nbsp;
                                    <p onClick={() => currentVente.status === 'ENCOURS' && navigate(UPDATE_VENTES_PATH + `?id=${currentVente.id}`)} className='text-dark fs-70 custom-mouse-clickable'>Modifier</p>
                                </div>
                            </div>
                            <div className='row p-3'>
                                <div className="col text-center" style={{ opacity: currentVente.status === 'ENCOURS' ? 1 : 0.7 }}>
                                    <AiOutlineCloseCircle className='custom-color-primary fs-3 m-auto' />&nbsp;
                                    <p onClick={() => currentVente.status === 'ENCOURS' && openDeleteProductModal()} className='text-dark fs-70 custom-mouse-clickable m-auto'>Annuler</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
export default DetailsVentePage;