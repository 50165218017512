import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentItem } from '../../redux/reducers/navbar'
import { PROFILE_PATH } from '../../utils/navigation_paths'
import Navbar from '../navbar/Navbar'
import {FiEdit} from 'react-icons/fi'
import {IoMdContact} from 'react-icons/io'
import './Profile.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ReduxAction from '../../redux/actions'
import { toast } from 'react-toastify'
import toast_options from '../../utils/toast_options'

const Profile = () => {
    const DEFAULT_MESSAGE = 'Ce champ est obligatoire!'
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentUser = useSelector(state => state.users.currentUser)

    const [isEditingPassword,setIsEditingPassword] = useState(false)
    const [isEditingImage,setIsEditingImage] = useState(false)

    useEffect(()=>{
        dispatch(setCurrentItem(PROFILE_PATH))
    })

    const {register,handleSubmit,formState:{errors},setError} = useForm()

    const submit = (data) => {
        ReduxAction.users.updateUser(data,currentUser.token.token,dispatch)
        .then(response =>{
            // alert(JSON.stringify(response))
            toast.success('Votre profile a bien été mis a jours',toast_options)
            navigate(-1)
        })
        .catch(err => console.log(err))
    };
    const verificate = (data) => {
        let {firstName,lastName,email} = data
        let submitData = {firstName,lastName,email}

        if(isEditingPassword){
            if(data.password !== data.confirm_password){
                setError('password',{message:'Les mots de passe ne correspondent pas!'})
            }else{
                ReduxAction.users.changePassword(currentUser.id,{password:'test1234'},currentUser.token.token)
            }
        }else{
            submit({...currentUser,...submitData})
        }

    };

    return (
        <>
            {/* <Navbar /> */}
            <div className="container-fluid bg-light custom-height-max">
                <div className="row p-2 p-md-5 mt-3">
                    <p className="fs-4 text-start">Modfier votre profile</p>
                    <div className="col-md-12">
                        <div className="card py-3">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(verificate)} className=" px-md-3">
                                    <div className="row text-start">
                                        <div className="col-12 col-md px-3">
                                            <div>
                                                <label className="w-100">
                                                    Prénom
                                                    <input {...register('firstName',{required:DEFAULT_MESSAGE})} defaultValue={currentUser?.firstName} type="text" className='form-control p-2' />
                                                    {errors.firstName && <p className='custom-error-msg p-0 m-0'>{errors.firstName.message}</p>}
                                                </label>
                                                <label className="w-100 mt-3">
                                                    Nom
                                                    <input {...register('lastName',{required:DEFAULT_MESSAGE})} defaultValue={currentUser?.lastName} type="text" className='form-control p-2' />
                                                    {errors.lastName && <p className='custom-error-msg p-0 m-0'>{errors.lastName.message}</p>}
                                                </label>
                                                <label className="w-100 mt-3">
                                                    Adresse email
                                                    <input {...register('email',{required:DEFAULT_MESSAGE})} defaultValue={currentUser?.email} type="text" className='form-control p-2' />
                                                    {errors.email && <p className='custom-error-msg p-0 m-0'>{errors.email.message}</p>}
                                                </label>
                                                <span className='mt-3 custom-mouse-clickable text-dark' onClick={()=> setIsEditingPassword(!isEditingPassword)}>
                                                    {isEditingPassword?
                                                        <span className='custom-mouse-clickable custom-color-primary btn-link mt-3 d-block'>Garder le mot de passe actuel</span>
                                                        :
                                                        <span className='custom-mouse-clickable custom-color-primary btn-link mt-3 d-block'>Changer mon mot da passe <FiEdit/></span> 
                                                    }
                                                </span> 
                                            </div>
                                        </div>
                                        {isEditingPassword &&
                                        <div className='col-12 col-md px-3'>
                                            <div>
                                                <label className="w-100">
                                                    Mot de passe actuel
                                                    <input {...register('current_password',{required:DEFAULT_MESSAGE})} type="password" className='form-control p-2' />
                                                    {errors.current_password && <p className='custom-error-msg p-0 m-0'>{errors.current_password.message}</p>}
                                                </label>
                                                <label className="w-100 mt-3">
                                                    Nouveau mot de passe
                                                    <input {...register('password',{required:DEFAULT_MESSAGE})} type="password" className='form-control p-2' />
                                                    {errors.password && <p className='custom-error-msg p-0 m-0'>{errors.password.message}</p>}
                                                </label>
                                                <label className="w-100 mt-3">
                                                    Confirmer mot de passe
                                                    <input {...register('confirm_password',{required:DEFAULT_MESSAGE})} type="password" className='form-control p-2' />
                                                    {errors.confirm_password && <p className='custom-error-msg p-0 m-0'>{errors.confirm_password.message}</p>}
                                                </label>
                                            </div>
                                        </div>
                                        }
                                        <div className="col-12 col-md px-3">
                                            <div className='text-center'>
                                                {isEditingImage?
                                                    <>
                                                        <label className='text-start row'>
                                                            Image
                                                            <input accept='.png,.jpg,.jpeg,.svg' {...register('image',{required:'Choisissez une image'})} type="file" className='bg-light form-control p-2'/>
                                                            {errors.image && <p className='custom-color-danger text-center p-0 m-0'>{errors.image.message}</p>}
                                                        </label>
                                                        <div className="text-start">
                                                            <span onClick={()=>setIsEditingImage(false)}  className="mt-3 custom-mouse-clickable custom-color-primary btn-link">Garder l'imge actuel</span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <IoMdContact className='text-secondary' size={150}/>
                                                        <FiEdit onClick={()=>setIsEditingImage(true)} className='custom-color-primary custom-mouse-clickable' size={25}/>
                                                    </>
                                                }
                                            </div>
                                            
                                            <div className='mt-4 text-center'>
                                                <button onClick={()=>navigate(-1)} type='button' className="btn btn-secondary rounded-0 mx-3 my-3 py-2 px-3">Annuler</button>
                                                <button className='custom-button-secondary rounded-0 shadow py-2 ms-3 px-3'>Enregistrer</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
