import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import { CgTrashEmpty } from 'react-icons/cg'
import SkeletonTableRow from '../../shared/SkeletonTableRow';
import { editArticleQuantity, removeArticleFromCurrentFacture, setArticleErrors } from '../../../redux/reducers/new/newFactureArticles';

const NewFactureEditArticle = ({
    children,
    deleteFactureArticle,
    updateArticleQuantityOnChange,
    loadingResource
}) => {
    const [search, setSearch] = useState('')
    let inputRef = useRef(null)
    const dispatch = useDispatch()
    const { currentFacture, isUpdatingFacture, isFactureForDepense } = useSelector(state => state.newFactureArticles)

    const sommeTotal = currentFacture.reduce((acc, current) => {
        return acc += current.price
    }, 0)

    // const [loading, setLoading] = useState(false)

    useEffect(() => {
        inputRef.current?.focus()
    }, [search])

    const quantityFormater = (cell, article) => {
        return (
            <div className='d-flex justify-content-center align-items-center gap-2'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <input
                        aria-invalid={article.errors?.quantity ? "true" : "false"}
                        aria-errormessage='error-msg'
                        readOnly={article.errors?.readOnly}
                        ref={inputRef}
                        style={{
                            width: '80px',
                            opacity: article.errors?.readOnly ? 0.5 : 1
                        }}
                        className='form-label'
                        type="number"
                        value={article.quantity || search}
                        onBlur={(e) => {
                            if (!e.target.value || Number(e.target.value) <= 0) {
                                e.target.value = 1
                                setSearch(e.target.value)
                                dispatch(editArticleQuantity({
                                    article: {
                                        ...article,
                                        quantity: e.target.value,
                                        price: isFactureForDepense ?
                                            article.product.prixAchat * Number(e.target.value)
                                            :
                                            article.product.prixVente * Number(e.target.value)
                                    }
                                }))
                                if (isUpdatingFacture) {
                                    updateArticleQuantityOnChange(article, { quantity: Number(e.target.value = 1) })
                                }
                            }
                        }}
                        onChange={e => {
                            setSearch(e.target.value)
                            // !on ne verifie pas la quantite s'il s'agit d'une depense
                            // console.log({ isFactureForDepense });
                            if (!isFactureForDepense) {
                                if (Number(e.target.value) > article.product.quantite) {
                                    dispatch(setArticleErrors({
                                        article: {
                                            ...article,
                                            quantity: e.target.value,
                                            price: article.product.prixVente * Number(e.target.value),
                                            errors: {
                                                quantity: {
                                                    message: `${article.product.quantite} produits au max`
                                                },
                                                readOnly: false
                                            }
                                        }
                                    }))
                                    return
                                }
                                dispatch(editArticleQuantity({
                                    article: {
                                        ...article,
                                        quantity: e.target.value,
                                        price: article.product.prixVente * Number(e.target.value),
                                        errors: {
                                            quantity: null,
                                            readOnly: false
                                        }
                                    }
                                }))
                                return
                            }
                            dispatch(editArticleQuantity({
                                article: {
                                    ...article,
                                    quantity: e.target.value,
                                    price: article.product.prixAchat * Number(e.target.value),
                                    errors: {
                                        quantity: null,
                                        readOnly: false
                                    }
                                }
                            }))
                            if (isUpdatingFacture && e.target.value) {
                                updateArticleQuantityOnChange(article, { quantity: e.target.value })
                            }
                        }}
                    />
                    {article.errors?.quantity && <span style={{ fontSize: '10px' }} id='error-msg' className="custom-error-msg m-0 p-0 errormessage">{article.errors.quantity.message}</span>}
                </div>

            </div>
        )
    }

    const deleteFormatter = (cell, article) => {
        return <button
            disabled={article.errors?.readOnly}
            onClick={() => {
                // setLoading(true)
                if (isUpdatingFacture) {
                    deleteFactureArticle(article)
                    return
                }
                dispatch(removeArticleFromCurrentFacture(article))
            }}
            className='btn btn-outline-danger p-0 px-1'
            type="button">
            {
                <CgTrashEmpty size={15} />
            }
        </button>
    }

    const columns = useMemo(() => [
        {
            dataField: "product.name",
            text: "Nom product",
            headerClasses: 'text-gris'
        },
        {
            dataField: "quantity",
            // text: "Quantité",
            text: "Qte",
            formatter: quantityFormater,
            headerClasses: 'text-gris'
        },
        {
            dataField: "price",
            text: "Prix",
            headerClasses: 'text-gris'
        },
        {
            dataField: "actions",
            text: "Supprimer",
            formatter: deleteFormatter,
            headerClasses: 'text-gris'
        },
    ], [])

    // console.log({ currentFacture });

    return (
        <div className='product mt-3'>
            {
                loadingResource ? <div className='px-2'>
                    {[1].map((item, i) => <SkeletonTableRow key={i} />)}
                </div> :
                    currentFacture.length ?
                        <div className='p-2'>
                    <BootstrapTable
                data={currentFacture}
                columns={columns}
                        keyField='id'
                striped
                    />
                        <div style={{ fontSize: '12.5px' }} className='d-flex justify-content-between mt-3'>
                            <p>Total:</p>
                                <p className='fw-bold'>{sommeTotal} Fcfa</p>
                        </div>
                    {children}
                    </div>
                        : <p className='mt-3'>Veuillez selectionner des articles</p>
            }

        </div>
    )
}

export default NewFactureEditArticle