import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    statistic: {},
}
const statisticSlice = createSlice({
    name:'statistic',
    initialState,
    reducers:{
        setStatistic:(state,{payload})=>{
            state.statistic = payload
        },
    }
})

export const { setStatistic } = statisticSlice.actions
export default statisticSlice.reducer