import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Navbar from '../components/navbar/Navbar'
// import { useAgenceId } from '../hooks/useAgenceId'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useCurrentUserId } from '../hooks/useCurrentUserId'
// import { LOGIN_PATH } from '../utils/navigation_paths'

const AppLayout = () => {
  const userId = useCurrentUserId()
  // const agenceId = useAgenceId()
  const currentUser = useCurrentUser()
  const location = useLocation()
  // console.log({ currentUser });

  return (userId && currentUser?.status === 'gerant') ? (
    <div>
      <Navbar />
      <main className='' >
        <section className='mb-5 p-2 pt-4'>
          <Outlet />
        </section>
      </main>
    </div>
  ) : <Navigate to={'/login'} state={{ from: location }} replace />
}

export default AppLayout