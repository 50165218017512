const { default: axios } = require("axios")
const { GET_FOURNISSEUR_API_ROUTE, ADD_FOURNISSEUR_API_ROUTE, UPDATE_FOURNISSEUR_API_ROUTE, DELETE_FOURNISSEUR_API_ROUTE, GET_FOURNISSEUR_BYID_API_ROUTE, UPLOAD_fOURNISSEUR_BY_CSV_API_ROUTE, GET_USER_FOURNISSEUR_API_ROUTE } = require("../../routes/api_routes")
const { getfournisseurList, addFournisseur, updateFournisseur, archiveFournisseur, setCurrentFournisseur } = require("../reducers/fournisseurs")

module.exports = {
    uploadFournisseurByCSV(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(UPLOAD_fOURNISSEUR_BY_CSV_API_ROUTE, data, { headers: { Authorization: token } })
                .then(response => {
                    if (response.status === 201) {
                        resolve(response)
                    }
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    },
    fetchFournisseurs(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_FOURNISSEUR_API_ROUTE}${id}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let fournisseurs = response.data.data
                        dispatch(getfournisseurList(fournisseurs))
                        resolve(fournisseurs)
                    } else {
                        console.log(response);
                        reject(response)
                    }
                })
        })
    },
    getFournisseurById(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(GET_FOURNISSEUR_BYID_API_ROUTE + id + '/', { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let fournisseur = response.data.data
                        dispatch(setCurrentFournisseur(fournisseur))
                        resolve(fournisseur)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    createNewFournisseur(data, token, dispatch, agenceId) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_FOURNISSEUR_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 201) {
                        let newFournisseur = response.data.data
                        // console.log({ newFournisseur });
                        this.fetchFournisseurs(agenceId, dispatch, token)
                        // dispatch(addFournisseur(newFournisseur))
                        resolve(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateFournisseur(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_FOURNISSEUR_API_ROUTE + id + '/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let newFournisseur = response.data.data
                        dispatch(updateFournisseur(newFournisseur))
                        resolve(newFournisseur)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    archiveFournisseur(fournisseurId, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_FOURNISSEUR_API_ROUTE + fournisseurId + '/', { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    console.log({ response });
                    if (response.status === 204) {
                        // dispatch(archiveFournisseur(fournisseurId))
                        resolve(fournisseurId)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    }
}