import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentShop: {},
    shopsList: []
}
const shopsSlice = createSlice({
    name:'shops',
    initialState,
    reducers:{
        setCurrentShop:(state,{payload})=>{
            state.currentShop = payload
        },
        setshopsList:(state,{payload})=>{
            state.shopsList = payload
        },
    }
})

export const { setCurrentShop, setshopsList} = shopsSlice.actions
export default shopsSlice.reducer