import React, { useState } from "react";
// import Modal from "react-modal";
import "../clients/add_clients.css";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import {
  isValidMobilePhone,
  isValidText,
} from "../../utils/form_control";
import { useAgenceId } from "../../hooks/useAgenceId";
import Modal from "../../shared/Modal";

const AddFournisseur = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const defaultMessage = "Ce champ est obligatoire !";
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();
  const agenceId = useAgenceId();
  const [loading, setLoading] = useState(false);

  function afterOpenModal() {}

  function closeModal() {
    reset();
    setIsOpen(false);
  }

  const submit = (data) => {
    data.createdBy = currentUser.id;
    data.agenceId = agenceId;
    // data.proprietaireId = proprietaireId

    // console.log({ data });
    setLoading(true);
    ReduxAction.fournisseurs
      .createNewFournisseur(data, currentUser.token.token, dispatch, agenceId)
      .then((response) => {
        // console.log("list des fournisseur", response)
        closeModal();
        toast.success("Fournisseur ajouté avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("L'ajout n'a pas réuissi", toast_options);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verificate = (data) => {
    if (!isValidText(data.firstName)) {
      setError("firstName", { message: "Prénom non valide" });
    } else if (!isValidText(data.lastName)) {
      setError("lastName", { message: "Nom non valide" });
    } 
    // else if (!isValidEmail(data.email)) {
    //     setError('email', { message: 'Email non valide' })
    // }
    else if (!isValidMobilePhone(data.telephone)) {
      setError("telephone", { message: "Numéro de tel non valide" });
    } else {
      submit(data);
    }
  };

  return (
    <>
      <div>
        <Modal
          opened={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onClosed={closeModal}
          title="Ajouter un fournisseur"
        >
          <form className="pt-2 pb-4 px-3" onSubmit={handleSubmit(verificate)}>
            {/* <div className="row py-3">
                            <h6 className="col-9 fs-6 ">Ajouter un fournisseur</h6>
                            <p className="col-3 text-end fw-bold fs-4"><span className=' border-dark rounded px-2 custom-mouse-clickable' onClick={closeModal}>X</span></p>
                        </div> */}
            <div className="row my-md-3">
              <div className="col-12 col-md add-client-input">
                <label className="add-client-input">
                  Prénom
                  <input
                    type="text"
                    {...register("firstName", { required: defaultMessage })}
                    className="form-control p-2"
                  />
                  {errors.firstName && (
                    <span className="custom-color-danger">
                      {errors.firstName.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="col-12 col-md mt-3 mt-md-0 ms-md-2 add-client-input">
                <label className="add-client-input">
                  Nom
                  <input
                    type="text"
                    {...register("lastName", { required: defaultMessage })}
                    className="form-control p-2"
                  />
                  {errors.lastName && (
                    <span className="custom-color-danger">
                      {errors.lastName.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="row my-md-3">
              <div className="col-12 col-md mt-3 mt-md-0 add-client-input">
                <label className="add-client-input">
                  Email
                  <input
                    type="email"
                    {...register("email")}
                    className="form-control p-2"
                  />
                  {/* {errors.email && <span className='custom-color-danger'>{errors.email.message}</span>} */}
                </label>
              </div>
              <div className="col-12 col-md mt-3 mt-md-0 ms-md-2 add-client-input">
                <label className="add-client-input">
                  Téléphone
                  <input
                    type="number"
                    {...register("telephone", { required: defaultMessage })}
                    className="form-control p-2"
                  />
                  {errors.telephone && (
                    <span className="custom-color-danger">
                      {errors.telephone.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="row my-md-3">
              <div className="col-12 col-md mt-3 mt-md-0 add-client-input">
                <label className="add-client-input">
                  Adrese
                  <input
                    type="text"
                    {...register("adresse")}
                    className="form-control p-2"
                  />
                  {errors.adresse && (
                    <span className="custom-color-danger">
                      {errors.adresse.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="col-12 col-md mt-3 mt-md-0 ms-md-2 add-client-input">
                <label className="add-client-input">
                  Raison sociale
                  <input
                    type="text"
                    {...register("raison")}
                    className="form-control p-2"
                  />
                  {errors.raison && (
                    <span className="custom-color-danger">
                      {errors.raison.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <p className="text-end mt-4">
              <button
                style={{ opacity: loading ? 0.5 : 1 }}
                disabled={loading}
                type="submit"
                className="custom-button-secondary py-2 px-3 mt-3"
              >
                {!loading ? "Enregistrer" : "Enregistrement en cours..."}
              </button>
            </p>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default AddFournisseur;
