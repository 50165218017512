/** --------------------------------------------------------------------
 * ! LOCAL APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = 'http://127.0.0.1:8000/api'
// export const DEFAULT_IMAGE_API = 'http://127.0.0.1:8000'

/** --------------------------------------------------------------------
 * ! RECETTE APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = 'http://167.99.253.190:8086/api'
// export const DEFAULT_IMAGE_API = 'http://167.99.253.190:8086'
// Nouvelle api recette: http://167.99.253.190:8087/red/docs/
// export const DEFAULT_API = 'http://167.99.253.190:8087/api'
// export const DEFAULT_IMAGE_API = 'http://167.99.253.190:8087'
/** --------------------------------------------------------------------
 * ! PARTAGE APIS
 * ---------------------------------------------------------------------
 */
// 1er etage: 192.168.68.155
// export const DEFAULT_API = 'http://192.168.68.186:8000/api'
// export const DEFAULT_IMAGE_API = 'http://192.168.68.186:8000'

// deuxieme: 192.168.68.164

/** --------------------------------------------------------------------
 * ! PROD APIS
 * ---------------------------------------------------------------------
 */
export const DEFAULT_API = 'https://api-pack.fewnu.app/api'
export const DEFAULT_IMAGE_API = 'https://api-pack.fewnu.app'

/**-------------------------------------*
 * ! LOCAL API RESET PASSWORD           *
 ---------------------------------------*/
// export const RESET_PASSWORD_API_ROUTE = 'http://127.0.0.1:8000/reset_password'
// export const RESET_PASSWORD_API_ROUTE_SENT = 'http://127.0.0.1:8000/reset_password_sent'

/**-------------------------------------*
 * ! RESET PASSWORS API                 *
 ---------------------------------------*/
export const RESET_PASSWORD_API_ROUTE_SENT =
  "https://167.99.253.190:8081/reset_password_sent";
export const RESET_PASSWORD_API_ROUTE = "https://167.99.253.190:8081/reset_password";


export const DEFAULT_ROUTE = DEFAULT_API + "/v1/";

/**-------------------------------------*
 *! AUTHENTICATION ROUTES               *
 ---------------------------------------*/
export const REGISTER_API_ROUTE = DEFAULT_ROUTE + "users/";
export const LOGIN_API_ROUTE = DEFAULT_ROUTE + "login/"; // ! connect only gerant
export const UPDATE_USER_API_ROUTE = DEFAULT_ROUTE + "user/"; // + {id}/
export const CHANGE_USER_PASSWORD_API_ROUTE = DEFAULT_ROUTE + "user/password/"; // + {id}/
export const LOGOUT_API_ROUTE = DEFAULT_ROUTE + 'logout/'

/**---------------------------------------*
 * ! CLIENTS ROUTES                       *
 -----------------------------------------*/
export const ADD_CLIENT_API_ROUTE = DEFAULT_ROUTE + "clients/";
export const UPDATE_CLIENT_API_ROUTE = DEFAULT_ROUTE + "clients/"; // + {id}/
export const DELETE_CLIENT_API_ROUTE = DEFAULT_ROUTE + "clients/"; // + {id}/
export const GET_CLIENT_BY_ID_API_ROUTE = DEFAULT_ROUTE + "clients/"; // + {id}/
export const UPLOAD_CLIENT_BY_CSV_API_ROUTE = DEFAULT_ROUTE + "client/upload/"; // + {id}/
export const GET_CLIENTS_API_ROUTE = DEFAULT_ROUTE + "clients/?agenceId="; // + {agenceId}
// export const GET_USER_CLIENTS_API_ROUTE = DEFAULT_ROUTE + "client/user/";

/**---------------------------------------*
 * ! AGENCE ROUTES                       *
 -----------------------------------------*/
//  export const GET_GERANT

/**---------------------------------------*
 * ! PRODUCTS ROUTES                      *
 -----------------------------------------*/
export const ADD_PRODUCT_API_ROUTE = DEFAULT_ROUTE + "products/"; // + {id}
export const GET_PRODUITS_API_ROUTE = DEFAULT_ROUTE + "products/?agenceId="; // + {id}
export const GET_USER_PRODUITS_API_ROUTE = DEFAULT_ROUTE + "products/user/";
export const UPDATE_PRODUIT_API_ROUTE = DEFAULT_ROUTE + "products/"; // + {id}/
export const DELETE_PRODUIT_API_ROUTE = DEFAULT_ROUTE + "products/"; // + {id}/

/**---------------------------------------*
 * ! VENTES ROUTES                        *
 -----------------------------------------*/
export const ADD_VENTE_API_ROUTE = DEFAULT_ROUTE + "ventes/";
export const CREATE_NEW_VENTE_API_ROUTE = DEFAULT_ROUTE + "ventes/articles/";
export const UPDATE_VENTE_API_ROUTE = DEFAULT_ROUTE + "ventes/"; // + {id}/
export const DELETE_VENTE_API_ROUTE = DEFAULT_ROUTE + "ventes/"; // + {id}/
export const GET_VENTES_API_ROUTE = DEFAULT_ROUTE + "ventes/details/";
export const GET_USER_VENTES_API_ROUTE = DEFAULT_ROUTE + "ventes/user/";
export const GET_AGENCE_VENTES_API_ROUTE = DEFAULT_ROUTE + "ventes/agences/";
export const SEND_VENTE_EMAIL_API_ROUTE = DEFAULT_ROUTE + "ventes/mail/"; // + {id}/

/**---------------------------------------*
 * ! ARTICLES ROUTES                      *
 -----------------------------------------*/
export const ADD_VENTE_ARITCLE_API_ROUTE = DEFAULT_ROUTE + "articles/";
export const UPDATE_VENTE_ARITCLE_API_ROUTE = DEFAULT_ROUTE + "ventes/articles/"; // + {id}/
export const DELETE_VENTE_ARITCLE_API_ROUTE = DEFAULT_ROUTE + "ventes/articles/"; // + {id}/

/**---------------------------------------*
 * ! CATEGORIES ROUTES                    *
 -----------------------------------------*/
export const ADD_CATEGORY_API_ROUTE = DEFAULT_ROUTE + "category/";
export const GET_CATEGORIES_API_ROUTE = DEFAULT_ROUTE + "category/?agenceId=";
// export const GET_USER_CATEGORIES_API_ROUTE = DEFAULT_ROUTE + "category/user/";

/**---------------------------------------*
 * ! FOURNISSEURS ROUTES                  *
 -----------------------------------------*/
export const GET_FOURNISSEUR_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/?agenceId=" // + {agenceId}
export const GET_USER_FOURNISSEUR_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/user/";
export const ADD_FOURNISSEUR_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/";
export const UPDATE_FOURNISSEUR_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/";
export const DELETE_FOURNISSEUR_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/";
export const UPLOAD_fOURNISSEUR_BY_CSV_API_ROUTE =
  DEFAULT_ROUTE + "fournisseur/upload"; // + {id}/
export const GET_FOURNISSEUR_BYID_API_ROUTE = DEFAULT_ROUTE + "fournisseurs/";

/**---------------------------------------*
 * ! DEPENSES ROUTES                      *
 -----------------------------------------*/
export const GET_DEPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/agences/";
export const GET_USER_DPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/use/";
export const ADD_DPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/";
export const CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE = DEFAULT_ROUTE + "depenses/articles/";
export const UPDATE_DPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/"; // + {id}/
export const DELETE_DPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/"; // + {id}/
export const GET_BY_ID_DPENSES_API_ROUTE = DEFAULT_ROUTE + "depenses/details/"; // + {id}/
export const DELETE_DPENSES__ARICLE_API_ROUTE = DEFAULT_ROUTE + "depenses/articles/"; // + {id}/

/**---------------------------------------*
 * ! ACHATS ROUTES                        *
 -----------------------------------------*/
export const GET_DPENSES__ARICLE_API_ROUTE = DEFAULT_ROUTE + "achat/";
export const ADD_DPENSES__ARICLE_API_ROUTE = DEFAULT_ROUTE + "achat/";
export const UPDATE_DPENSES__ARICLE_API_ROUTE = DEFAULT_ROUTE + "depenses/articles/";

/**---------------------------------------*
 * ! COMPANY ROUTES                       *
 -----------------------------------------*/
export const GET_COMPANY_API_ROUTE = DEFAULT_ROUTE + "company/";
export const GET_COMPANY_BY_ID_API_ROUTE = DEFAULT_ROUTE + "company/"; //`${id}/`
export const ADD_COMPANY_API_ROUTE = DEFAULT_ROUTE + "company/";
export const UPDATE_COMPANY_API_ROUTE = DEFAULT_ROUTE + "agences/"; //`${id}/`
export const DELETE_COMPANY_API_ROUTE = DEFAULT_ROUTE + "company/";

export const UPLOAD_FILE = DEFAULT_ROUTE + "fournisseur/upload";

export const GET_SHOPS_API_ROUTE = DEFAULT_ROUTE + "shops/";

export const GET_STATICTICS_API_ROUTE = DEFAULT_ROUTE + "statistic";
