export const DEFAULT_PATH = '*'
export const LOGIN_PATH = '/login'
export const REGISTER_PATH = '/register'

export const ACCUEIL_PATH = '/accueil'

export const CONTACTS_PATH = '/contacts'

export const CLIENTS_PATH = '/clients'
export const DETAILS_CLIENTS_PATH = CLIENTS_PATH + '/details'

export const FOURNISSEURS_PATH = '/fournisseurs'
export const DETAIL_FOURNISSEURS_PATH = FOURNISSEURS_PATH + '/details'


export const VENTES_PATH = "/ventes"
export const ADD_VENTES_PATH = VENTES_PATH + '/add'
export const UPDATE_VENTES_PATH = VENTES_PATH + '/update'
export const DETAILS_VENTES_PATH = VENTES_PATH + '/details'

export const DEPENSE_PATH = "/depenses"
export const ADD_DEPENSE_PATH = DEPENSE_PATH + '/add'
export const DETAILS_DEPENSE_PATH = DEPENSE_PATH + '/details'
export const UPDATE_DEPENSE_PATH = DEPENSE_PATH + '/update'

export const PRODUCTS_PATH = "/produits"
export const PARAMETTRES_PATH = "/parametres"
export const PROFILE_PATH = "/profile"
export const FORGOT_PASSWORD_PATH = "/forgot-password"