import React from 'react';
import Modal from 'react-modal';
import './add_clients.css'
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ReduxAction from '../../redux/actions';
import { toast } from 'react-toastify';
import toast_options from '../../utils/toast_options';
import { useAgenceId } from '../../hooks/useAgenceId';

const ImportFournisseurModal = ({ modalIsOpen, setIsOpen }) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.users.currentUser)
    const { register, reset, formState: { errors }, handleSubmit, setError } = useForm()
    const agenceId = useAgenceId()

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height: '44%',
            width: '40%',
            shadow: '0px 0px 5px black',
            background: '#eee',
            borderRadius: '0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function afterOpenModal() {
    }

    function closeModal() {
        reset()
        setIsOpen(false);
    }

    const submit = (data) => {
        const formData = new FormData()
        formData.append('csv_file', data.csv_file[0])
        formData.append('user_id', currentUser.id)
        ReduxAction.fournisseurs.uploadFournisseurByCSV(formData, currentUser.token.token, dispatch)
            .then(response => {
                closeModal()
                toast.success('L\'importation des fournisseurs a réuissi', toast_options)
                ReduxAction.fournisseurs.fetchFournisseurs(agenceId, dispatch, currentUser.token.token)
            })
            .catch((err) => {
                toast.error('L\'importation des fournisseurs n\'a pas réuissi')
                setError('csv_file', { message: 'L\'importation a échoué (fichier non valide)' })
            })
    }
    const verificate = (data) => {

        submit(data)

    }
    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <form className="px-3 py-4" onSubmit={handleSubmit(verificate)}>
                    <p className='fw-bold text-center'>Importer des clients au format CSV</p>
                    <label className="row mt-5">
                        <input {...register('csv_file', { required: 'Ce champ est obligatoire !' })} type={'file'} accept='.csv' className="form-control p-2" />
                        {errors.csv_file && <p className='custom-error-msg'>{errors.csv_file.message}</p>}
                    </label>
                    <div className='mt-3 row gap-3 justify-content-center'>
                        <button className='bg-secondary text-white shadow rounded-0 border-0 py-2 col ' type='button' onClick={closeModal}>Annuler</button>
                        <button className='custom-button-secondary rounded-0 py-2 col '>Importer</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default ImportFournisseurModal;