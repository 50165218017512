import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    depenseList: [],
    allDepense: [],
    currentDepense: {}
}


const depenseSlice = createSlice({
    name: "depenses",
    initialState,
    reducers: {
        getDepenseList: (state, { payload }) => {
            state.depenseList = payload
            state.allDepense = payload
        },
        addNewDepense: (state, { payload }) => {
            state.depenseList = [payload, ...state.allDepense]
            state.allDepense = [payload, ...state.allDepense]
        },
        updateDepenseSlice: (state, { payload }) => {
            let depense = state.allDepense.filter(depense => depense.id === payload.id)[0]
            // console.log({ depense });
            const copyDepenses = [...state.allDepense]
            const indexOfDepense = state.allDepense.indexOf(depense)
            copyDepenses[indexOfDepense] = payload
            // depenses = [...depenses, payload].reverse()
            state.allDepense = copyDepenses
            state.depenseList = copyDepenses
        },
        setCurrentDepense: (state, { payload }) => {
            state.currentDepense = payload
        },
        filterDepenseList: (state, { payload }) => {
            if (payload === '') {
                state.depenseList = state.allDepense
            } else {
                state.depenseList = state.allDepense.filter(depense => {
                    let champs = [depense?.fournisseur?.firstName, depense?.fournisseur?.lastName]
                    let txt = champs.join(' ').toLowerCase()
                    return txt.includes(payload.toLowerCase())
                })
            }
        },
        archiveDepense: (state, { payload }) => {
            let depenses = state.allDepense.filter(depense => depense.id !== payload)

            state.allDepense = depenses
            state.depenseList = depenses
        },
        reInitializeDepenses: (state) => {
            state.depenseList = {}
            state.allDepense = []
            state.currentDepense = []
        }
    }
})


export const { getDepenseList, addNewDepense, updateDepenseSlice, setCurrentDepense, filterDepenseList, archiveDepense, reInitializeDepenses } = depenseSlice.actions

export default depenseSlice.reducer