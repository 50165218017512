import React, { useRef } from 'react'
import { AiFillEdit } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { DEFAULT_API, DEFAULT_IMAGE_API } from '../../routes/api_routes';
import { useAgenceName } from '../../hooks/useAgenceName';
import { useAgenceAdresse } from '../../hooks/useAgenceAdresse';
import BootstrapTable from 'react-bootstrap-table-next';
import { IoMdPrint } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAgence } from '../../hooks/useAgence';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi'
import { BsPhone } from 'react-icons/bs'
import { FactureAImprimer } from './FactureAImprimer';
import FactureAMontrer from './FactureAMontrer';
// import { CiLocationOn } from 'react-icons/ci'

// export const ComponentToPrint = React.forwardRef((props, ref) => {
//     return (
//         <div ref={ref}>My cool content here!</div>
//     );
// });

const App = ({
    agence,
    nomAgence,
    factureDate,
    agenceAdresse,
    agenceEmail,
    agencePhone,
    factureFor,
    clientFullName,
    clientPhone,
    dataTable,
    columns,
    totalTransaction
}) => {
    const pdfExportComponent = React.useRef(null);
    return (
        <div>
            <div className="example-config">
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={() => {
                        if (pdfExportComponent.current) {
                            pdfExportComponent.current.save();
                        }
                    }}
                >
                    Export PDF
                </button>
            </div>

            <div
                style={{
                    position: "absolute",
                    left: "-1000px",
                    top: 0,
                }}
            >
                <PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
                    <div
                        style={{ width: '800px' }}
                        id='facture-a-imprimer'
                        className='row m-3 p-4'
                    // ref={ref}
                    >
                        {/* ********************** entreprise **************** */}
                        <div className='row'>
                            <div className="col-6 text-start">
                                <img
                                    src={`${DEFAULT_IMAGE_API}${agence.logo}`}
                                    alt="logo entreprise"
                                    className="border-ligth text-start h-70git me-3"
                                    onError={e => e.target.src = 'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'}
                                    style={{ width: '100px' }}

                                />
                                <p className='fw-bold'>{nomAgence}</p>
                            </div>
                            <div className="col-6 text-end">
                                <p className='p-0 m-0'><span className='fw-bold'>Date Facture:</span> {'factureDate'}</p>
                                {/* <p className='p-0 m-0'><span className='fw-bold'>Numero vente:</span> vte-25</p> */}
                                {/* <p className='p-0 m-0'></p> */}
                            </div>
                        </div>

                        <div className='row my-5 mt-2 text-start'>
                            <div className='col-6 p-3 ps-0'>
                                <p className='fs-6'><HiOutlineLocationMarker />  {agenceAdresse}</p>
                                <p className='fs-6 my-3'> <HiOutlineMail /> {agenceEmail}</p>
                                <p className='fs-6'> <BsPhone /> {agencePhone}</p>
                            </div>
                            <div style={{ backgroundColor: '#EFEDED', color: 'black' }} className='col-6 p-3'>
                                <h6 className='fw-bold'>{factureFor.toUpperCase()}</h6>
                                <p style={{ fontSize: '14px' }}>Nom: {clientFullName}</p>
                                <p style={{ fontSize: '14px' }}>Telephone: {clientPhone}</p>
                            </div>
                        </div>

                        <div className='table-a-imprimer'>
                            <BootstrapTable
                                striped
                                keyField='table'
                                data={dataTable || []}
                                columns={columns}
                            />
                        </div>
                        {/* <div>
                    <p className='text-end'>
                        <span className='pt-3 p-2 shadow-sm border'>
                            <span className='total fs-3 fw-bold '>Total {total} XOF </span>
                            <span className='total fs-6 fw-light me-5 mb-5'>({venteTableFormat?.length} article{venteTableFormat?.length > 1 && 's'})</span>
                        </span>
                    </p>
                </div> */}
                        <div>
                            <div className='d-flex justify-content-end mt-0'>
                                <div style={{ minWidth: '220px' }} className='p-2 shadow-sm border mt-1 text-end'>
                                    <span className='total fs-5 fw-bold '>Total {totalTransaction} XOF </span>
                                    <span className='total fs-6 fw-light me-3 mb-5'>({dataTable?.length} article{dataTable?.length > 1 && 's'})</span>
                                </div>
                            </div>
                        </div>

                        <p className='mt-5 text-start'>Signature </p>
                    </div>
                </PDFExport>
            </div>
        </div>
    );
};

const FactureDetails = ({
    factureProgressBar: FactureProgressBar,
    transactionStatus,
    factureFor,
    totalTransaction,
    fullName,
    phone,
    messageOnEmptyFullName,
    factureDate,
    numbreJoursRestants,
    dataTable,
    columns,
    navigateToEdit,
    currentTransactionId,
    transactionDate,
    openPaymentModal,
    onEdit,
    loadingFacture
}) => {
    let factureRef = null
    const componentRef = useRef();
    const pdfExportComponent = useRef(null);
    const nomAgence = useAgenceName()
    const agenceAdresse = useAgenceAdresse()
    const navigate = useNavigate()
    const { agence } = useAgence()
    const currentCompany = useSelector(state => state.company.currentCompany)

    // console.log({ agence });

    // const columns = [
    //     {
    //         dataField: "product.name",
    //         text: "Désignation",
    //         headerClasses: 'custom-table-column text-white'
    //     },
    //     {
    //         dataField: "quantity",
    //         text: "Quantité",
    //         headerClasses: 'custom-table-column text-white'
    //     },
    //     {
    //         dataField: "product.prixAchat",
    //         text: "Prix",
    //         headerClasses: 'custom-table-column text-white'
    //     },
    //     {
    //         dataField: "montant",
    //         text: "Total",
    //         headerClasses: 'custom-table-column text-white',
    //     }
    // ];
    const exportPDFWithMethod = () => {
        // let element = document.querySelector('.k-grid');
        console.log(pdfExportComponent.current);
        savePDF(pdfExportComponent.current.rootElForPDF, {
            paperSize: 'A4',
            fileName: 'facture.pdf',
            scale: 0.8
        });
    };
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };
    // console.log({ transactionDate });
    return (
        <>
            {/* <App
                agence={agence}
                nomAgence={nomAgence}
                factureDate={factureDate}
                agenceAdresse={agenceAdresse}
                agenceEmail={agence.email}
                agencePhone={agence.phone}
                factureFor={factureFor}
                clientFullName={fullName}
                clientPhone={phone}
                dataTable={dataTable}
                columns={columns}
                totalTransaction={totalTransaction}
            /> */}
            {/* <ComponentToPrint ref={componentRef} /> */}
            <div className='d-none'>
                <FactureAImprimer
                    // pdfExportComponent={pdfExportComponent}
                    factureFor={factureFor}
                    // pdfExportComponent={pdfExportComponent}
                    // factureRef={factureRef}
                    ref={componentRef}
                    companyLogo={`${DEFAULT_API}${currentCompany.logo}`}
                    nomAgence={nomAgence}
                    factureDate={factureDate?.toLocaleDateString('fr-SN')}
                    agenceAdresse={agenceAdresse}
                    agenceEmail={agence.email}
                    agencePhone={agence.phone}
                    clientFullName={fullName}
                    clientPhone={phone}
                    dataTable={dataTable}
                    columns={columns}
                    totalTransaction={totalTransaction}
                />
            </div>
            <div className='row m-0 p-0 p-md-5 custom-height-max bg-light text-start'>
                <p className='fs-2 fw-bold my-0'>Détails de la facture</p>
                <p className='mb-4'>Voici votre facture,vous pouvez la télécharger ou l'imorimer directement</p>
                <div className='row m-0 p-0'>
                    <div className="col-12 col-md-12 col-xl-9 p-3 pdf-container mb-4 bg-white shadow-sm border">
                        {/* <PDFExport ref={pdfExportComponent} paperSize="A4"> */}
                        <div
                            id='facture'
                            className='row m-3'
                        >
                            {/* ********************** entreprise **************** */}
                            {/* <div className='row'>
                                <div className="col-6 text-start">
                                    <img
                                        src={`${DEFAULT_API}${currentCompany.logo}`}
                                        alt="logo entreprise"
                                        className="border-ligth text-start h-70git me-3"
                                        onError={e => e.target.src = 'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'}
                                        style={{ width: '100px' }}

                                    />
                                    <p className='fw-bold'>{nomAgence}</p>
                                </div>
                                <div className="col-6 text-end">
                                    <p className='p-0 m-0'><span className='fw-bold'>Date Facture:</span> {factureDate?.toLocaleDateString('fr-SN')}</p>
                                </div>
                            </div> */}

                            <FactureAMontrer
                                factureFor={factureFor}
                                loadingFacture={loadingFacture}
                                companyLogo={`${DEFAULT_API}${currentCompany.logo}`}
                                nomAgence={nomAgence}
                                factureDate={factureDate?.toLocaleDateString('fr-SN')}
                                agenceAdresse={agenceAdresse}
                                agenceEmail={agence.email}
                                agencePhone={agence.phone}
                                clientFullName={fullName}
                                clientPhone={phone}
                                dataTable={dataTable}
                                columns={columns}
                                totalTransaction={totalTransaction}
                            />

                            {/* <div className='row my-5 mt-2'>
                                <div className='col-6 p-3 ps-0'>
                                    <p className='fs-6'><HiOutlineLocationMarker />  {agenceAdresse}</p>
                                    <p className='fs-6 my-3'> <HiOutlineMail /> {agence.email}</p>
                                    <p className='fs-6'> <BsPhone /> {agence.phone}</p>
                                </div>
                                <div style={{ backgroundColor: '#EFEDED', color: 'black' }} className='col-6 p-3'>
                                    <h6 className='fw-bold'>Client</h6>
                                    <p style={{ fontSize: '14px' }}>Nom client: {fullName}</p>
                                    <p style={{ fontSize: '14px' }}>Telephone: {phone}</p>
                                </div>
                            </div>

                            <div className='text-center'>
                                <BootstrapTable
                                    striped
                                    keyField='table'
                                    data={dataTable || []}
                                    columns={columns}
                                />
                            </div>
                            <div>
                                <div className='d-flex justify-content-end mt-0'>
                                    <div style={{ width: '380px' }} className='pt-3 p-2 shadow-sm border mt-1 text-end'>
                                        <span className='total fs-3 fw-bold '>Total {totalTransaction} XOF </span>
                                        <span className='total fs-6 fw-light me-3 mb-5'>({dataTable?.length} article{dataTable?.length > 1 && 's'})</span>
                                    </div>
                                </div>
                            </div> */}

                            {/* <p className='mt-5'>Signature </p> */}
                        </div>
                        {/* </PDFExport> */}
                    </div>
                    <div className='col-12 col-md-12 mb-4 col-xl-3 ps-xl-3'>
                        <div className='text-center facture-paiment-card mb-4 custom-card-shadow'>
                            <div className='d-block'>
                                <FactureProgressBar />
                            </div>
                            <div className='bg-white'>
                                <div className="row">
                                    <div className="col text-start px-4 py-2">
                                        <span className='fw-bold'>Payée</span>
                                        <p className=''>{transactionStatus === 'ENCOURS' ? '00' : totalTransaction} XOF</p>
                                    </div>
                                    <div className="col text-end px-4 py-2">
                                        <span className='fw-bold'>En cours</span>
                                        <p className=''>{transactionStatus === 'ENCOURS' ? totalTransaction : '00'} XOF</p>
                                    </div>
                                </div>
                                <div className="row px-4 pb-3">
                                    {transactionStatus === 'ENCOURS' ?
                                        <button onClick={openPaymentModal} className='custom-button-secondary py-2 fw-bold rounded-0 shadow-none'>Paiement facture</button>
                                        :
                                        <p className='fs-90 text-start'>
                                            <span className='text-decoration-underline custom-color-primary'>Payé</span> {transactionDate ? `le ${new Date(transactionDate).toLocaleDateString('fr-SN')} ` : null}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='text-center bg-white facture-card-links text-start custom-card-shadow'>
                            <div className='row text-start p-3'>
                                {/* <div className="col" style={{ opacity: currentDepense.transactionStatus === 'ENCOURS' ? 1 : 0.7 }}>
                                        <HiMail className='custom-color-primary fs-3' />&nbsp;
                                        <p onClick={() => currentDepense.transactionStatus === 'ENCOURS' && sendEmail()} className='text-dark fs-70 custom-mouse-clickable'>Email</p>
                                    </div> */}
                                <div className="d-flex justify-content-between">
                                    {/* <ReactToPrint
                                        trigger={() => <button>Print this out!</button>}
                                        content={() => componentRef.current}
                                    /> */}
                                    {/* <button onClick={exportPDFWithMethod} type="button">Imprimer</button> */}
                                    <ReactToPrint
                                        trigger={() => {
                                            return <button className='text-dark fs-70 btn border fs-70 p-2 action-btn'>
                                                <IoMdPrint className='custom-color-primary fs-5' />
                                                <span className='d-inline-block ms-1'>Imprimer</span>
                                            </button>;
                                        }}
                                        content={() => componentRef.current}
                                    />
                                    <button
                                        disabled={transactionStatus === 'ENCOURS' ? 0 : 1}
                                        style={{ opacity: transactionStatus === 'ENCOURS' ? 1 : 0.45 }}
                                        onClick={onEdit} className='text-dark action-btn btn border fs-70 p-2'>
                                        <AiFillEdit className='custom-color-primary fs-5 ' />
                                        <span className='d-inline-block ms-1'>Modifier</span>
                                    </button>
                                </div>
                                {/* <div className="col-6 text-end" style={{ opacity: transactionStatus === 'ENCOURS' ? 1 : 0.7 }}>
                                </div> */}
                            </div>
                            <div className='row text-start p-3'>
                                {/* <div className="col ">
                                        <FaDownload className='custom-color-primary fs-4' />&nbsp;&nbsp;
                                        <p onClick={handleExportWithComponent} className='text-dark fs-70 custom-mouse-clickable'>Télécharger</p>
                                    </div> */}
                            </div>
                            {/* <div className='row p-3'>
                                    <div className="col text-center" style={{ opacity: currentDepense.transactionStatus === 'ENCOURS' ? 1 : 0.7 }}>
                                        <AiOutlineCloseCircle className='custom-color-primary fs-3 m-auto' />&nbsp;
                                        <p onClick={() => currentDepense.transactionStatus === 'ENCOURS' && openDeleteProductModal()} className='text-dark fs-70 custom-mouse-clickable m-auto'>Annuler</p>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FactureDetails