import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

const ServiceDataMap = ({ data = [], columns, pagination, messageOnError }) => {
  return (
    <>
      <div className='custom-bt-container app-padding'>
        {data?.length > 0 && (
          <BootstrapTable
            striped
            keyField='id'
            data={data}
            columns={columns}
            // pagination={pagination}
            pagination={pagination}
          />
        )}
      </div>
      {data?.length < 1 && (
        <div className="row m-0 p-0">
          <p className='empty--message'>{messageOnError}</p>
        </div>
      )}
    </>
  )
}

export default ServiceDataMap