import React from "react";
// import Modal from 'react-modal';
import "./add_ventes.css";
import { useSelector, useDispatch } from "react-redux";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "../../shared/Modal";
import DeleteModal from "../../shared/DeleteModal";

const DeleteVenteModal = ({
  modalIsOpen,
  setIsOpen,
  shouldNavigate = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentVente = useSelector((state) => state.ventes.currentVente);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      // height:'44%',
      // width:'30%',
      minWidth: "300px",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const archiveVente = () => {
    setLoading(true);
    ReduxAction.ventes
      .deleteVente(currentVente.id, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Facture supprimé avec succés", toast_options);
        if (shouldNavigate) {
          navigate(-1);
        }
      })
      .catch((err) => {
        // console.log(err)
        closeModal();
        toast.error("La suppression n'a pas réuissi", toast_options);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <DeleteModal
      opened={modalIsOpen}
      onClosed={closeModal}
      loading={loading}
      onDelete={archiveVente}
    />
  );
};

export default DeleteVenteModal;
