import React, { useEffect, useState } from 'react'
import NewFactureArticles from '../../facture/new/NewFactureArticles'
import SelectFactureClient from '../../facture/new/SelectFactureClient'
import ButtonLoading from '../../shared/ButtonLoading'
import { useDispatch, useSelector } from 'react-redux'
import { useToken } from '../../../hooks/useToken'
import ApiController from '../../../redux/actions'
import { useNavigate } from 'react-router-dom'
import { addNewArticlesToCurrentFacture, removeArticleFromCurrentFacture } from '../../../redux/reducers/new/newFactureArticles'
import { toast } from 'react-toastify'

const NewUpdateVente = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { disableProduct, currentFacture } = useSelector(state => state.newFactureArticles)
    const currentVente = useSelector(state => state.ventes.currentVente)
    const currentVenteId = currentVente.id
    const token = useToken()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        ApiController.ventes.getVenteByID(currentVenteId, dispatch, token).finally(() => setLoading(false))
    }, [currentVenteId, dispatch, token])

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Modification de la facture de vente</p>
            </div>
            <NewFactureArticles
                loadingResource={loading}
                createNewFactureArticle={(product) => {
                    dispatch(addNewArticlesToCurrentFacture(product))
                    let index = currentFacture.findIndex(article => article.product.id === product.id)
                    // console.log({ index, product });
                    if (index !== -1) {
                        let article = currentFacture.find(article => article.product.id === product.id)
                        // console.log({ article });
                        ApiController.ventesFacture.updateArticle(article.articleId, { quantity: article.quantity + 1 }, token, dispatch)
                        return
                    }
                    ApiController.ventes.createNewVenteArticle(
                        { quantity: 1, productId: product.id, vente: currentVenteId, price: product.prixVente },
                        token,
                        dispatch
                    )
                        .catch(() => {
                            toast.error("Une erreur est survenue lors de l'ajout d'un nouvel article, veuillez réessayer")
                            // !on supprime le produit sur la currentFacture et on le remet dans la liste des produits en cas d'erreur
                            dispatch(removeArticleFromCurrentFacture({ product }))
                        })
                }}
                deleteFactureArticle={(article) => {
                    dispatch(removeArticleFromCurrentFacture({ product: article.product }))
                    ApiController.ventes.deleteVenteArticle({ article, vente: currentVenteId }, token, dispatch)
                        .catch(() => {
                            toast.error('Une erreur est survenue lors de la suppression, veuillez réessayer')
                            dispatch(addNewArticlesToCurrentFacture(article.product))
                        })
                }}
                updateArticleQuantityOnChange={(article, data) => {
                    // console.log({ article });
                    ApiController.ventesFacture.updateArticle(article.articleId, data, token, dispatch)
                }}
            >
                <SelectFactureClient
                    isUpdating={true}
                />
                <ButtonLoading
                    disabled={disableProduct}
                    text="Modifier"
                    loading={null}
                    onClick={() => navigate(-1)}
                />
            </NewFactureArticles>
        </div>
    )
}

export default NewUpdateVente