import React from 'react';
import Modal from 'react-modal';
import './add_produits.css'

const DetailsProduitsModal = ({modalIsOpen,setIsOpen}) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height:'65%',
            width:'60%',
            shadow:'0px 0px 5px black',
            background:'#eee',
            borderRadius:'0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    
    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
            </Modal>
        </div>
    );
};

export default DetailsProduitsModal;