const { default: axios } = require("axios")

const { ADD_DPENSES_API_ROUTE, UPDATE_DPENSES_API_ROUTE, DELETE_DPENSES_API_ROUTE, GET_BY_ID_DPENSES_API_ROUTE, GET_DEPENSES_API_ROUTE, CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE } = require("../../routes/api_routes")
const { setCurrentDepens, setCurrentDepenseDetails } = require("../reducers/depenseFacture")
const { addNewDepense, archiveDepense, getDepenseList, updateDepenseSlice, setCurrentDepense, } = require("../reducers/depenses")
const { setSelectedArticlesSlice } = require("../reducers/factureArticles")
const { setCurrenteFactureForUpdate, editProductArticleId } = require("../reducers/new/newFactureArticles")

module.exports = {
    getDepenseList(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_DEPENSES_API_ROUTE}${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data) {
                        let depenses = response.data.data
                        dispatch(getDepenseList(depenses))
                        resolve(depenses)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    getDepenseByID(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(GET_BY_ID_DPENSES_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let depense = response.data
                        // console.log({ depense });
                        dispatch(setSelectedArticlesSlice(depense))
                        dispatch(setCurrentDepenseDetails(depense))
                        // !pour afficher la nouvelle facture de vente et garder que les produits qui ne sont pas dans la facture
                        dispatch(setCurrenteFactureForUpdate(depense))
                        resolve(depense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createNewDepense(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_DPENSES_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    // console.log({ response });
                    if (response.status === 201) {
                        let newDepense = response.data
                        // console.log({ newDepense });
                        dispatch(addNewDepense(newDepense))
                        // dispatch(setCurrentDepens(newDepense))
                        resolve(newDepense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    createNewDepenseArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    console.log({ response });
                    if (response.status === 201) {
                        let newArticleCreated = response.data.data
                        // console.log({ newArticleCreated });
                        dispatch(editProductArticleId(newArticleCreated))
                        resolve(newArticleCreated)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateDepense(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_DPENSES_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let depense = response.data.data
                        dispatch(updateDepenseSlice(depense))
                        dispatch(setCurrentDepense(depense))
                        resolve(depense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteDepense(id, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_DPENSES_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        dispatch(archiveDepense(id))
                        resolve(id)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
}
