import React, { useState } from "react";
import "./login.css";
import logo_fewnu from "../../img/logo_fewnu.png";
import logo_fewnu_trans from "../../img/logo_fewnu_trans.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FiAlertTriangle, FiEye, FiEyeOff } from "react-icons/fi";
import ReduxAction from "../../redux/actions";
import { useDispatch } from "react-redux";
// import { isValidEmail } from '../../utils/form_control';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [visiblePassword, setVisiblePassword] = useState(false);

  const submit = (data) => {
    setIsLoading(true);
    ReduxAction.users
      .loginUser(data, dispatch, ReduxAction)
      .then((user) => {
        // console.log({user});
        setIsLoading(false);
        navigate("/ventes");
        ReduxAction.all.getAllData(user.token.token, dispatch);
      })
      .catch((err) => {
        // console.log('error*********: ', err)
        setIsLoading(false);
        if (err.response.status === 401) {
          setError("password", {
            message: "Téléphone ou mot de passe incorrect",
          });
        } else {
          setError("password", {
            message: "L'authentification n'a pa réuissi",
          });
        }
      });
  };
  const verificate = (data) => {
    // if (!isValidEmail(data.email)) {
    //     setError('email', { message: 'L\'email n\'est pas valide' })
    // } else {
    //     console.log({data});
    //     submit(data)
    // }
    submit(data);
  };
  return (
    <div className="login-page m-0 p-0">
      <div className="row login-top-bar m-0 p-0 shadow">
        <div className="col me-auto text-start d-flex align-items-center gap-2">
          <img src={logo_fewnu} alt="" />
          <p className="custom-separator-bar-secondary"></p>
          <p className="custom-color-primary font-Montserrat">
            Compta & Facturation
          </p>
        </div>
        {/* <p className='col-3 d-none d-md-block text-end me-md-3 pt-2'>
                    <Link className='custom-color-primary text-decoration-none font-Montserrat' to="/register" >Inscrivez-vous!</Link>
                </p> */}
      </div>
      <div className="row m-0 p-0 justify-content-center">
        <div className="row justify-content-center">
          <div className="row  justify-content-center">
            <img className="image-fewnu-login" src={logo_fewnu_trans} alt="" />
          </div>
          <div className="row">
            <hr className="login-hr" />
            <p className="text-white fs-3 font-Montserrat">
              Compta & Facturation
            </p>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <p className=" text-white font-Montserrat">
            Connectez-vous et gérez votre business!
          </p>
          <form
            onSubmit={handleSubmit(verificate)}
            className="row m-0 p-0 justify-content-center"
          >
            <div className="col-10 col-sm-8 col-md-6 col-lg-3">
              <input
                {...register("phone", {
                  required: "Veuillez saisir votre email",
                })}
                type="tel"
                placeholder="Numero téléphone"
                className={"login-input form-control mt-1 px-2"}
              />
              {errors.email && (
                <p className="custom-color-danger">
                  <FiAlertTriangle /> {errors.email.message}
                </p>
              )}

              <div
                className="mt-4"
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                <input
                  {...register("password", {
                    required: "Veuillez saisir un mot de passe",
                  })}
                  type={visiblePassword ? "text" : "password"}
                  placeholder="Password"
                  className="login-input form-control px-2"
                />
                <div
                  style={{
                    height: 41.5,
                    width: 50,
                    backgroundColor: "#fff",
                    display: "grid",
                    placeItems: "center",
                    // border: "2px solid gray",
                    cursor: "pointer",
                  }}
                  className="login-input form-control px-2"
                  onClick={() => setVisiblePassword((v) => !v)}
                >
                  {visiblePassword ? (
                    <FiEye color="#000" />
                  ) : (
                    <FiEyeOff color="#000" />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="custom-color-danger">
                  <FiAlertTriangle /> {errors.password.message}
                </p>
              )}

              <p className="text-end mt-3">
                <Link
                  className="text-end text-white custom-mouse-clickable text-deoration-none"
                  to="/forgot-password"
                >
                  Mot de passe oublié?
                </Link>
              </p>
              <button
                disabled={isLoading}
                type="submit"
                className="login-btn row m-0 p-0 mt-3 align-items-center font-Montserrat"
              >
                <span className="col-2"></span>
                <span className="col-8">Connexion</span>
                <span className="col-2">
                  <div
                    style={{ width: "20px", height: "20px" }}
                    className={
                      isLoading ? "spinner-border fs-6 mt-1" : "d-none"
                    }
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </span>
              </button>
            </div>
          </form>

          {/* <p className='text-white d-block d-md-none mt-2'>
                        Pas de compte? <Link className='text-white fw-bold text-decoration-none' to="/register" >Inscrivez-vous!</Link>
                    </p> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
