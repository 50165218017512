import React from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import logo_fewnu_trans from '../../img/logo_fewnu_trans.png'
import ReduxAction from '../../redux/actions'
import './password.css'


const Forgotassword = () => {

    const {handleSubmit,register} = useForm()

    const submit = (data) => {
        ReduxAction.users.resetPssword(data)
    };
    return (
        <div className='login-page m-0 p-0'>
            <div className='row password-top-bar custom-bg-primary m-0 p-0 shadow'>
                <div className='col me-auto text-start'>
                    <img className='password_logo' src={logo_fewnu_trans} alt="" />
                    <b className='text-white ps-2 font-Montserrat'> <p className='custom-separator-bar-primary'></p> Compta & Facturation</b>
                </div>
                    <p className='col-3 d-none d-md-block text-end me-md-3 pt-2'>
                        <Link className='text-white text-decoration-none font-Montserrat' to="/login" >Connexion</Link>
                    </p>
            </div>
            <div className="row m-0 p-0 justify-content-center">
                <div className="row  justify-content-center">
                    <div className="row justify-content-center">
                        <img className='image-fewnu-login' src={logo_fewnu_trans} alt="" />
                    </div>
                    <div className="row">
                        <hr className='login-hr' />
                        <p className='text-white fs-3 font-Montserrat' >Compta & Facturation</p>
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    <p className=" text-white font-Montserrat">Veuillez saisir votre email</p>
                    <form onSubmit={handleSubmit(submit)} className='row m-0 p-0 justify-content-center'>
                        <div className="col-10 col-sm-8 col-md-6 col-lg-3">
                            <input {...register('email')} type="email" placeholder='Email' className={'login-input form-control mt-1 mb-4'}/>                                                                                                              
                            <button type='submit' className="login-btn row m-0 p-0 align-items-center font-Montserrat">
                                <span>Envoyer</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Forgotassword
