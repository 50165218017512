const { default: axios } = require("axios")

const { GET_PRODUITS_API_ROUTE, ADD_PRODUCT_API_ROUTE, UPDATE_PRODUIT_API_ROUTE, DELETE_PRODUIT_API_ROUTE } = require("../../routes/api_routes")
const { setProduitsList, addNewProduit, updateProduitInList, archiveProduitInList} = require("../reducers/produits")
const { addNewProduitFactureSlice, setProductsNotAddedToCurrentVente } = require("../reducers/new/newFactureArticles")

module.exports = {
    getProductsList(agenceId,dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(`${GET_PRODUITS_API_ROUTE}${agenceId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if(response.data){
                    let produits = response.data.data
                    dispatch(setProduitsList(produits))
                    dispatch(setProductsNotAddedToCurrentVente(produits))
                    resolve(produits)
                }else{
                    reject(response)
                }
            })
        })
    },
    createNewProduct(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_PRODUCT_API_ROUTE,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                if (response.status === 201) {
                    let newProduct = response.data.data
                    dispatch(addNewProduit(newProduct))
                    dispatch(addNewProduitFactureSlice(newProduct))
                    resolve(response)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    updateProduit(id,data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.put(UPDATE_PRODUIT_API_ROUTE+id+'/' ,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                if (response.status === 200) {
                    let newProduct = response.data.data
                    dispatch(updateProduitInList(newProduct))
                    resolve(newProduct)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    archiveProduct(prod,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.delete(DELETE_PRODUIT_API_ROUTE+prod.id+'/' ,{headers:{ Authorization: `Bearer ${token}` }})
                .then(response => {
                if(response.status === 204){
                    dispatch(archiveProduitInList(prod))
                    resolve(prod)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    }
}
