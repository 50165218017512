import { createSlice } from "@reduxjs/toolkit";

const uploadSlice = createSlice({
    name:"file",
    initialState:{
        files:[]
    },
    reducers:{
        uploadFile:(state,{payload})=>{
            state.files = [...state.files,payload]
        },
    }
})

export const { uploadFile} = uploadSlice.actions

export default uploadSlice.reducer