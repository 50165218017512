const { default: axios } = require("axios")
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
const { GET_CLIENTS_API_ROUTE,ADD_CLIENT_API_ROUTE, UPDATE_CLIENT_API_ROUTE, DELETE_CLIENT_API_ROUTE, GET_CLIENT_BY_ID_API_ROUTE, UPLOAD_CLIENT_BY_CSV_API_ROUTE, GET_USER_CLIENTS_API_ROUTE } = require("../../routes/api_routes")
const { setClientsList, addNewClient, updateClientInList, archiveClientInList, setCurrentClient } = require("../reducers/clients")

module.exports = {
    uploadClientByCSV(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.post(UPLOAD_CLIENT_BY_CSV_API_ROUTE,data,{ headers: { Authorization: token }})
            .then(response => {
                if(response.status === 201){
                    resolve(response)
                }
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    getClientById(id,dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(GET_CLIENT_BY_ID_API_ROUTE+`${id}/`,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response => {
                if(response.status === 200){
                    let client = response.data
                    dispatch(setCurrentClient(client))
                    resolve(client)
                }else{
                    reject(response)
                }
            })
        })
    },
    getClientsList(agenceId,dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(GET_CLIENTS_API_ROUTE + `${agenceId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.status === 200) {
                    let clients = response.data.data
                    dispatch(setClientsList(clients))
                    resolve(clients)
                }else{
                    reject(response)
                }
            })
        })
    },
    createNewClient(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_CLIENT_API_ROUTE,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                if (response.status === 201) {
                    let newClient = response.data.data
                    dispatch(addNewClient(newClient))
                    resolve(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    updateClient(id,data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.put(UPDATE_CLIENT_API_ROUTE+id+'/' ,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                // console.log(response);
                if (response.status === 200) {
                    let newClient = response.data.data
                    dispatch(updateClientInList(newClient))
                    resolve(newClient)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    archiveClient(client,token,dispatch){
        client.archived = true
        return new Promise((resolve,reject)=>{
            axios.delete(DELETE_CLIENT_API_ROUTE+client.id+'/' ,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response =>{
                console.log(response);
                if(response.status === 204){
                    dispatch(archiveClientInList(client))
                    resolve(client)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    }
}
