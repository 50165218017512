import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAgenceId } from '../../hooks/useAgenceId';
import { useToken } from '../../hooks/useToken';
import ReduxAction from '../../redux/actions';
import toast_options from '../../utils/toast_options';
import Modal from '../../shared/Modal';

const AddCategoryModal = ({ modalIsOpen, setIsOpen }) => {

    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.users.currentUser)
    const token = useToken()
    const defaultMessage = 'Ce champ est obligatoire !'
    const { register, reset, formState: { errors }, handleSubmit } = useForm()
    const agenceId = useAgenceId()

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height: '50%',
            width: '50%',
            minWidth: '330px',
            shadow: '0px 0px 5px black',
            background: '#eee',
            borderRadius: '0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function afterOpenModal() {
    }

    function closeModal() {
        reset()
        setIsOpen(false);
    }
    const submit = (data) => {
        data.createdBy = currentUser.id
        data.agenceId = agenceId

        ReduxAction.categories.createNewCategory(data, token, dispatch)
            .then(response => {
                closeModal()
                toast.success('Catégorie ajouté avec succés', toast_options);
            })
            .catch(err => {
                console.log(err)
                closeModal()
                toast.error('L\'ajout n\'a pas réuissi', toast_options);
            })
    }
    return (
        <div>
            <Modal
                opened={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onClosed={closeModal}
                title="Créer une nouvelle catégorie"
            >
                <form className="p-3" onSubmit={handleSubmit(submit)}>
                    <div className="row mt-md-1">
                        {/* <div className="row m-0 p-0">
                            <p className="col fs-5 px-3">Ajouter une catégorie</p>
                        </div> */}
                        <div className="col-12 add-client-input">
                            <label className='add-client-input'>
                                Nom
                                <input type='text' {...register('categoryName', { required: defaultMessage })} className='add-client-input form-control p-2' />
                                {errors.categoryName && <span className='custom-color-danger'>{errors.categoryName.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 mt-3 add-client-input">
                            <label className='add-client-input'>
                                Description
                                <textarea type='text' {...register('description')} className='add-client-input form-control' />
                                {errors.description && <span className='custom-color-danger'>{errors.description.message}</span>}
                            </label>
                        </div>

                    </div>
                    <div className="text-end mt-3">
                        <button type='submit' className='custom-button-secondary py-2 px-3'>Enregistrer</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default AddCategoryModal;