import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentItem: 'accueil',
    isDrawerOpen:false
}
const navbarSlice = createSlice({
    name:'navbar',
    initialState,
    reducers:{
        setCurrentItem:(state,{payload})=>{
            state.currentItem = payload
        },
        changeDrawerState:(state)=>{
            state.isDrawerOpen = !state.isDrawerOpen
        },
    }
})

export const { setCurrentItem,changeDrawerState} = navbarSlice.actions
export default navbarSlice.reducer