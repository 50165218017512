import React from 'react'
import { useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import EditeProfileModal from './EditeProfileModal'
import './parametre.css'
import { useForm } from 'react-hook-form'
import TVA_Modal from './TVA_Modal'
import ReduxAction from '../../redux/actions'
import { toast } from 'react-toastify'
import toast_options from '../../utils/toast_options'
import { DEFAULT_IMAGE_API } from '../../routes/api_routes'
import { useAgence } from '../../hooks/useAgence'
import { useToken } from '../../hooks/useToken'

const Parametre = () => {
  const dispatch = useDispatch()
  const [editProfileIsOpen, setEditProfileIsOpen] = useState(false)
  const [tvaModalIsOpen, setTvaModalIsOpen] = useState(false)
  const [isLogoEditing, setIsLogoEditing] = useState(false)
  const { agence } = useAgence()
  const token = useToken()

  // console.log({ agence });

  const openModale = () => {
    setEditProfileIsOpen(true)
  }


  const { register, formState: { errors }, handleSubmit, setError } = useForm()

  // ! =================== update agence logo =====================
  const updateCompanyLgoWithLogo = (data) => {
    let { logo } = data

    const formData = new FormData()

    formData.append('logo', logo[0])

    ReduxAction.company.updateCompany(agence.id, formData, token, dispatch)
      .then(response => {
        toast.success('logo modifié avec succés', toast_options)
        setIsLogoEditing(false)
      })
      .catch(err => {
        console.log(err);
      })
  }

  const logoVerificate = (data) => {
    updateCompanyLgoWithLogo(data)
    // if (currentCompany.id) {
    //   updateCompanyLgoWithLogo(data)
    // } else {
    //   createCompanyWithLogo(data)
    // }
  };
  return (
    <>
      {/* <Navbar /> */}
      <TVA_Modal modalIsOpen={tvaModalIsOpen} setIsOpen={setTvaModalIsOpen} />
      <EditeProfileModal modalIsOpen={editProfileIsOpen} setIsOpen={setEditProfileIsOpen} />
      <div className="parametre bg-light">
        <div className="row m-0 p-3 p-3">
          <div className="text-start my-3">
            <h1>Paramètres de l'entreprise</h1>
            <p>Gérer vos paramètres</p>
          </div>
          <div className="card bg-white">
            <div className="card-boody">
              <div className="row p-3">
                <h1 className='text-start'
                  style={{
                    fontFamily: 'Montserrat',
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px"
                  }}>Paramètres commerciales</h1>
                <div className="col-md-6 mt-2">
                  <div className='text-start'>
                    <p
                      onClick={openModale}
                      className='custom-mouse-clickable'
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: "600",
                        color: "#00BEC9"
                      }}>A propos de votre entreprise</p>
                    <p>
                      Informations incluses dans les documents de vente</p>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="text-start">
                    <button
                      // onClick={() => setTvaModalIsOpen(true)}
                      className='btn btn-link fw-bold text-decoration-none'
                      style={{ color: "#00BEC9" }}
                    >Paramètre de TVA</button>
                    <p>Enregistrer les détails dans votre registre de TVA</p>

                  </div>
                </div>
              </div>
              <hr />
              <div className="row p-3">
                <h1 className='text-start'
                  style={{
                    fontFamily: 'Montserrat',
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px"
                  }}>Paramètre des documents</h1>
                <div className="col-md-6 mt-2">
                  <div className="text-start">
                    <p
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: "600",
                        color: "#00BEC9"
                      }}>Logo</p>
                    {
                      isLogoEditing ?
                        <form onSubmit={handleSubmit(logoVerificate)}>
                          <input accept='.png,.jpg,.jpeg,.svg' type='file' className="form-control p-2" {...register('logo', { required: 'Veuilez choisir une image' })} />
                          {errors.logo && <p className='custom-error-msg p-0 m-0'>{errors.logo.message}</p>}
                          <div className="row mt-2">
                            <div className='col-6'>
                              <button onClick={() => setIsLogoEditing(false)} type='button' className="btn btn-secondary p-3 w-100 py-2 rounded">Annuler</button>
                            </div>
                            <div className='col-6 ps-2'>
                              <button className="btn btn-info text-white w-100 py-2 rounded p-3">Modifier</button>
                            </div>
                          </div>
                        </form>
                        :
                        <>
                          <div>
                            <img
                              src={`${DEFAULT_IMAGE_API}${agence.logo}`}
                              alt="logo entreprise" className="border-ligth text-start h-70git"
                              onError={e => e.target.src = 'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'}
                              style={{ width: '30%' }} />
                          </div>
                          <p onClick={() => setIsLogoEditing(true)} className="btn btn-link mt-2 text-center"><FiEdit style={{ color: "#000000" }} size={25} />
                            {/* Modifier mon logo */}
                          </p>
                        </>
                    }
                  </div>
                </div>
                {/* <div className="col-md-6 mt-2">
                  <div className="text-start">
                    <p
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: "600",
                        color: "#00BEC9"
                      }}>Email</p>
                    {isEmailEditing ?
                      <form onSubmit={handleSubmit(emailVerificate)}>
                        <input {...register('email', { required: 'Veuillez saisir votre email' })} type="email" className='bg-light form-control p-2' defaultValue={currentCompany.email || 'Email non défini'} />
                        {errors.email && <p className='custom-color-danger text-center p-0 m-0'>{errors.email.message}</p>}
                        <div className="text-center">
                          <button onClick={() => setIsEmailEditing(false)} className="btn btn-secondary mx-3 my-3 py-1 rounded p-3">Annuler</button>
                          <button className="btn btn-info text-white my-3 py-1 rounded p-3">{currentCompany.id ? 'Modifier' : 'Enregistrer'}</button>
                        </div>
                      </form>
                      :
                      <p onClick={() => setIsEmailEditing(true)} className='text-dark custom-mouse-clickable'>{currentCompany.email || 'Email non défini'} <FiEdit style={{ color: "#000000" }} /></p>
                    }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Parametre
