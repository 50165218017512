const { default: axios } = require("axios")

const { GET_SHOPS_API_ROUTE } = require("../../routes/api_routes")
const { setshopsList } = require("../reducers/shops")

module.exports = {
    getShopsList(dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(GET_SHOPS_API_ROUTE,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response => { 
                if(response.data.data){
                    let shops = response.data.data
                    dispatch(setshopsList(shops))
                    resolve(shops)
                }
            })
        })
    },
    createNewShop(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            
        })
    }
}
