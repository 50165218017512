const { default: axios } = require("axios")

const { GET_STATICTICS_API_ROUTE } = require("../../routes/api_routes")
const { setStatistic } = require("../reducers/statistic")

module.exports = {
    getStatistics(dispatch,token){
        return new Promise((resolve,reject)=>{
            axios.get(GET_STATICTICS_API_ROUTE,{headers:{ Authorization: `Bearer ${token}` }})
            .then(response => {
                if(response.status === 200){
                    let statistic = response.data.data
                    dispatch(setStatistic(statistic))
                    resolve(statistic)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
}
