import React from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import { motion } from "framer-motion";

function Modal({ opened, onClosed, title, children }) {
  const modalRef = useRef(null);

  if (!opened) {
    return null;
  }

  document.addEventListener("click", (e) => {
    if (e.target.className === "modal-container") {
      onClosed();
    }
  });

  return createPortal(
    <motion.div
      initial={{ opacity: 0, scale: 0.99 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2 }}
      style={{ paddingTop: "3rem" }}
      className="modal-container"
    >
      <div className="modal-children p-4" ref={modalRef}>
        <div className="modal-header py-0">
          <span>{title ? title : ""}</span>
          <div className="modal-close-icon" onClick={onClosed}>
            <AiOutlineClose size={20} />
          </div>
        </div>
        <hr />
        <div>{children}</div>
      </div>
    </motion.div>,
    document.getElementById("root")
  );
}

export default Modal;
