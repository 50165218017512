import { Modal } from 'bootstrap';
import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import { BiUserCircle } from 'react-icons/bi';
import { BsBagPlus, BsTelephone } from 'react-icons/bs';
import { CgPentagonTopLeft } from 'react-icons/cg';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import ReduxAction from '../../redux/actions';
import { setCurrentItem } from '../../redux/reducers/navbar';
import { CONTACTS_PATH } from '../../utils/navigation_paths';
import EntityDetails from '../EntityDetails';
import Navbar from '../navbar/Navbar';

const FournisseurDetail = () => {

    const dispatch = useDispatch()

    const currentFournisseur = useSelector(state => state.fournisseurs.currentFournisseur)
    const currentCompany = useSelector(state => state.company.currentCompany)
    const depenseList = useSelector(state => state.depenses.depenseList)
    const fournisseurDepenseList = depenseList.filter(depense => depense.fournisseur === currentFournisseur.id)
    const currentUser = useSelector(state => state.users.currentUser)


    useEffect(() => {
        ReduxAction.fournisseurs.getFournisseurById(id, dispatch, currentUser.token?.token)
    }, []);

    let { id, firstName, lastName, email, telephone, adresse } = currentFournisseur


    const columns = [
        {
            dataField: "id",
            text: "Référence",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "date",
            text: "Date",
            headerClasses: 'custom-table-column text-white',
            formatter: () => <span>28-12-2022</span>
        },
        {
            dataField: "total",
            text: "Montant",
            headerClasses: 'custom-table-column text-white',
            formatter: () => <span>300 000</span>
        },
        {
            dataField: "en_cours",
            text: "Payé",
            headerClasses: 'custom-table-column text-white',
            formatter: () => <span>Non</span>
        },
        {
            dataField: "solde",
            text: "Solde",
            headerClasses: 'custom-table-column text-white',
            formatter: () => <span>100000</span>
        },
    ];


    return (
        <div>
            {/* <Navbar /> */}
            <EntityDetails
                firstName={firstName}
                lastName={lastName}
                currentCompanyName={currentCompany.name}
                email={email}
                telephone={telephone}
                adresse={adresse}
                entityList={fournisseurDepenseList}
                columns={columns}
                errorMessage="Pas d'activités pour ce fournisseur"
                id={id}
            />
        </div>
    );

}

export default FournisseurDetail