import React from 'react';
import './progress.css'
import chacked from '../../img/checked.png'
import unchacked from '../../img/unchecked.png'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useSelector } from 'react-redux';

const FactureProgressBar = () => {
    
    const currentDepense = useSelector(state => {return state.depenses.currentDepense})

    return (
        <div className="facture-progress pt-4 pb-5 px-4">
            <ProgressBar
                percent={0}
                filledBackground="#00BEC9"
                unfilledBackground="black"
            >
                <Step transition="scale">
                {({ accomplished }) => (
                    <div className='mt-3'>
                        <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="30"
                        src={chacked}
                        alt='...'
                        />
                        <br />
                        <span className='progress-label'>créé</span>
                    </div>
                )}
                </Step>
                <Step transition="scale">
                {({ accomplished }) => (
                    <div className='mt-3'>
                        <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="30"
                        src={currentDepense.status === 'ENCOURS'? unchacked:chacked}
                        alt='...'
                        />
                        <br />
                        <span className='progress-label'>Payé</span>
                    </div>
                )}
                </Step>
            </ProgressBar>
        </div>
    );
};

export default FactureProgressBar;