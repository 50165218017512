import React, { useEffect, useState } from 'react';
import './depense.css'
import { RiSearch2Line } from 'react-icons/ri';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCurrentItem } from '../../redux/reducers/navbar';
import { filterDepenseList, setCurrentDepense } from '../../redux/reducers/depenses';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdEye } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { ADD_DEPENSE_PATH, DEPENSE_PATH, DETAILS_DEPENSE_PATH, UPDATE_DEPENSE_PATH } from '../../utils/navigation_paths';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import DeleteDepense from './DeleteDepense';
import { useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ServicesComponent from '../ServicesComponent';
import ServiceDataMap from '../ServiceDataMap';
import { setSelectedFournisseur } from '../../redux/reducers/depenseFacture';
import ApiController from '../../redux/actions';
import { useAgenceId } from '../../hooks/useAgenceId';
import { useToken } from '../../hooks/useToken';
import { resetFactureSelectedFournisseur, setFactureSelectedFournisseur, setIsFactureForDepense, setIsUpdatingFacture, setProductsNotAddedToCurrentVente } from '../../redux/reducers/new/newFactureArticles';

const ListDepenses = () => {
  const { allProducts } = useSelector(state => state.newFactureArticles)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const depensesList = useSelector(state => state.depenses.depenseList) || []
  const agenceId = useAgenceId()
  const token = useToken()

  // console.log({ depensesList });

  useEffect(() => {
    // dispatch(setCurrentItem(DEPENSE_PATH))
    ApiController.depenses.getDepenseList(agenceId, dispatch, token)
  }, [agenceId, dispatch, token])

  useEffect(() => {
    dispatch(setIsFactureForDepense(true))
    dispatch(setIsUpdatingFacture(false))
    dispatch(resetFactureSelectedFournisseur())
  }, [])

  const showUpdateModal = (prod) => {
    dispatch(setCurrentDepense(prod))
    if (prod.fournisseur) {
      dispatch(setFactureSelectedFournisseur({ value: prod.fournisseur.id, label: `${prod.fournisseur.firstName} ${prod.fournisseur.lastName}` }))
    }
    dispatch(setIsUpdatingFacture(true))
    dispatch(setProductsNotAddedToCurrentVente(allProducts))
    navigate(UPDATE_DEPENSE_PATH + `?id=${prod.id}`)
    // alert()
  }
  const showDeleteModal = (depense) => {
    dispatch(setCurrentDepense(depense))
    openDeleteProductModal()
  }
  const showDetailsModal = (depense) => {
    dispatch(setCurrentDepense(depense))
    navigate(DETAILS_DEPENSE_PATH + `?id=${depense.id}`)
  }
  const showActionButtons = (cell, row) => {
    return (
      <div >
        <IoMdEye aria-label='edit' data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={() => showDetailsModal(row)} />
        {row.status === 'ENCOURS' ?
          <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon' onClick={() => showUpdateModal(row)} />
          :
          <FiEdit aria-disabled={true} className='custom-mouse-clickable opacity-1 text-secondary mx-3 fs-5' />
        }
        {row.status === 'ENCOURS' ?
          <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon' onClick={() => showDeleteModal(row)} />
          :
          <RiDeleteBinLine className='custom-mouse-clickable opacity-1 mx-3 fs-5 text-secondary' />
        }
        {/* The tooltips below */}
        <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }

  // console.log({ depensesList });

  const columns = [
    // {
    //   dataField: "id",
    //   text: "Numéro",
    //   headerClasses: 'custom-table-column text-white col-1',
    //   formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.matricule}</p>
    // },
    {
      dataField: "created_at",
      text: "Date",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        var selected_date = new Date(row.created_at)
        return (
          <p key={row} className='' onClick={() => showDetailsModal(row)}>
            {selected_date?.toLocaleDateString('fr-SN')}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {selected_date?.toLocaleTimeString('fr-SN', { hour: 'numeric', minute: 'numeric' })}
          </p>
        )
      }
    },
    {
      dataField: "fournisseur",
      text: "Fournisseur",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.fournisseur?.firstName ? `${row.fournisseur.firstName} ${row.fournisseur.lastName}` : "Pas de fournisseur"}</p>
    },
    {
      dataField: "montantTotal",
      text: "Montant",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        // let montant_total = row.list_products.reduce((prev, prod) => {
        //   return prev + prod.total
        // }, 0);
        return <p className='' onClick={() => showDetailsModal(row)}>{row.montantTotal + ' XOF'}</p>
      }
    },
    {
      dataField: "emis",
      text: "Payé",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.status === 'PAYE' ? 'Oui' : 'Non'}</p>
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: showActionButtons
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPageList: [{
      text: '5', value: 5
    },
    {
      text: '10', value: 10
    },
    {
      text: '25', value: 25
    },
    {
      text: 'Tous', value: depensesList.length
    }]
  });

  function openDeleteProductModal() {
    setDeleteModalIsOpen(true);
  }

  return (
    <div className='row m-0 p-0'>
      {/* <Navbar /> */}
      <DeleteDepense modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />
      <ServicesComponent
        title="Dépenses"
        description="Enregistrez, consultez et gérez vos dépenses."
        labelIcon={<RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />}
        // input={<input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterDepenseList(e.target.value))} />}
        onChange={e => dispatch(filterDepenseList(e.target.value))}
        onClick1={() => navigate(ADD_DEPENSE_PATH)}
        firstBtnText="Enregistrer"
        firstBtn={<AiFillPlusCircle className='fs-3' />}
        onClick2={null}
        secondBtnText=""
        secondBtn={null}
      />
      <ServiceDataMap
        data={depensesList}
        columns={columns}
        pagination={pagination}
        messageOnError="Pas d'historique pour le moment"
      />

    </div>
  );
};

export default ListDepenses;