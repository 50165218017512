import React from 'react';
import Modal from 'react-modal';
import {useSelector,useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import ReduxAction from '../../redux/actions';
import { toast } from 'react-toastify';
import toast_options from '../../utils/toast_options';
import { isValidEmail, isValidMobilePhone, isValidNumber, isValidText } from '../../utils/form_control';

const EditeProfileModal = ({modalIsOpen,setIsOpen}) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.users.currentUser)
    const currentCompany = useSelector(state => state.company.currentCompany)
    const defaultMessage = 'Ce champ est obligatoire !'
    const {register,reset,formState:{errors},handleSubmit,setError} = useForm()

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height:'80%',
            width:'50%',
            minWidth:'330px',
            shadow:'0px 0px 5px black',
            background:'#eee',
            borderRadius:'0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    
    function afterOpenModal() {
    }

    function closeModal() {
        reset()
        setIsOpen(false);
    }

    const createCompany =(data)=>{
        data.user_id = currentUser.id
        let {raisonSociale,formJuridiqu, adresse, pays, region, ville, numeroSiret, email, logo} = data
        console.log('info entreprise ',data)

        const formData = new FormData()
        formData.append('raisonSociale', raisonSociale)
        formData.append('formJuridiqu', formJuridiqu)
        formData.append('adresse', adresse)
        formData.append('pays', pays)
        formData.append('region', region)
        formData.append('ville', ville)
        formData.append('numeroSiret', numeroSiret)
        formData.append('email', email)
        formData.append('user_id', currentUser.id)
    

        ReduxAction.company.createCompany(formData,currentUser.token.token,dispatch)
        .then(() =>{
            toast.success('company ajoute !', toast_options)
            closeModal()
        })
        .catch((error) =>{
            toast.error('Ajout company ehoue !', toast_options)
            closeModal()
        })
    }

    const updateCompanyEmail =(data)=>{
        let {raisonSociale,formJuridiqu, adresse, pays, region, ville, numeroSiret, email,} = data

        const formData = new FormData()
        formData.append('raisonSociale', raisonSociale)
        formData.append('formJuridiqu', formJuridiqu)
        formData.append('adresse', adresse)
        formData.append('pays', pays)
        formData.append('region', region)
        formData.append('ville', ville)
        formData.append('numeroSiret', numeroSiret)
        formData.append('email', email)
        formData.append('user_id', currentUser.id)

        ReduxAction.company.updateCompany(currentCompany.id,formData,currentUser.token.token,dispatch)
        .then(response =>{
            toast.success('entreprise modifié avec succés',toast_options)
            closeModal()
        })
        .catch(err =>{
        console.log(err);
        })
    }

    const verificate =(data)=>{
        if(!isValidText(data.raisonSociale)){
            setError('raisonSociale',{message:'Raison sociale non valide'})
        }else if(!isValidText(data.formJuridiqu)){
            setError('formJuridiqu',{message:'Forme juridique non valide'})
        }else if(!isValidText(data.adresse)){
            setError('adresse',{message:'Adresse non valide'})
        }else if(!isValidText(data.pays)){
            setError('pays',{message:'Pays non valide'})
        }else if(!isValidText(data.region)){
            setError('region',{message:'Région non valide'})
        }else if(!isValidText(data.ville)){
            setError('ville',{message:'Ville non valide'})
        }else if(!isValidNumber(data.numeroSiret)){
            setError('numeroSiret',{message:'Numéro non valide'})
        }else if(!isValidNumber(data.codePostale)){
            setError('codePostale',{message:'Code postale non valide'})
        }
        
        else{
            if(currentCompany.id){
                updateCompanyEmail(data)
            }else{
                createCompany(data)
            }
        }
    }
    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
            <form className=" px-md-3 m-0 p-0   font-Montserrat" onSubmit={handleSubmit(verificate)}>
                <div className="row m-0 p-0">
                    <p className="fs-5 fw-bold py-0 my-1 px-3">RAISON SOCIALE ET ADRESSE COMMERCIALE</p>
                    <p className=''>Configurer le lieu où est exercée l’activité de l’entreprise et les informations qui apparaîtront dans votre facture</p>
                </div>
                <div className="row mb-md-2">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Email de l'entreprise
                            <input defaultValue={currentCompany.email} type='email' {...register('email',{required:defaultMessage})} className='add-client-input form-control' />
                            {errors.email && <span className='custom-color-danger'>{errors.email.message}</span>}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Raison sociale
                            <input defaultValue={currentCompany.raisonSocial} type='text' {...register('raisonSocial',{required:defaultMessage})} className='add-client-input form-control' />
                            {errors.raisonSocial && <span className='custom-color-danger'>{errors.raisonSocial.message}</span>}
                        </label>
                    </div>
                </div>
                <div className="row my-md-2">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Form juridique
                            <input defaultValue={currentCompany.formJuridiqu} type='text' {...register('formJuridiqu',{required:defaultMessage})} className='add-client-input form-control' />
                            {errors.formJuridiqu && <span className='custom-color-danger'>{errors.formJuridiqu.message}</span>}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Adresse
                            <input defaultValue={currentCompany.adresse} type='text' {...register('adresse',{required:defaultMessage})} className='add-client-input form-control' />
                            {errors.adresse && <span className='custom-color-danger'>{errors.adresse.message}</span>}
                        </label>
                    </div>
                </div>
                <div className="row my-md-2">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Pays
                            <input defaultValue={currentCompany.pays} type='text' {...register('pays',{required:defaultMessage})} className='add-client-input form-control'/>
                            {errors.pays && <span className='custom-color-danger'>{errors.pays.message}</span>}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Région
                            <input defaultValue={currentCompany.region} type='text' {...register('region')} className='add-client-input form-control' />
                            {errors.region && <span className='custom-color-danger'>{errors.region.message}</span>}
                        </label>
                    </div>
                </div>
                <div className="row my-md-2">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Ville
                            <input defaultValue={currentCompany.ville} type='text' {...register('ville',{required:defaultMessage})} className='add-client-input form-control'/>
                            {errors.ville && <span className='custom-color-danger'>{errors.ville.message}</span>}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Numéro siret
                            <input defaultValue={currentCompany.numeroSiret} type='number' {...register('numeroSiret')} className='add-client-input form-control'/>
                            {errors.numeroSiret && <span className='custom-color-danger'>{errors.numeroSiret.message}</span>}
                        </label>
                    </div>
                </div>
                <div className="row my-md-2">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Code postale
                            <input defaultValue={currentCompany.codePostal} type='number' {...register('codePostal',{required:defaultMessage})} className='add-client-input form-control'/>
                            {errors.codePostal && <span className='custom-color-danger'>{errors.codePostal.message}</span>}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        
                    </div>
                </div>
                <p className="text-end mt-2">
                    <button type='submit' className='bg-secondary text-white shadow rounded border-0 py-2 px-4 mx-3 mt-3' onClick={closeModal}>Annuler</button>
                    <button type='submit' className='custom-button-secondary py-2 px-3 mx-3 mt-3'>Enregistrer</button>
                </p>
            </form>
            </Modal>
        </div>
    );
};

export default EditeProfileModal;