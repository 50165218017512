import { combineReducers, configureStore } from "@reduxjs/toolkit";
import users from "../reducers/users";
import navbar from "../reducers/navbar";
import clients from "../reducers/clients";
import shops from "../reducers/shops";
import fournisseurs from "../reducers/fournisseurs";
import produits from "../reducers/produits";
import depenseFacture from "../reducers/depenseFacture";
import depenses from "../reducers/depenses";
import ventes from "../reducers/ventes";
import venteFacture from "../reducers/venteFacture";
import categories from "../reducers/categories";
import upload from "../reducers/upload";
import company from '../reducers/campany'
import statistic from '../reducers/statistic'
import agence from '../reducers/agence';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import factureArticles from "../reducers/factureArticles";
import newFactureArticles from '../reducers/new/newFactureArticles';


const persistConfig = {
    key: 'root',
    storage,
}

const combinedReducer = combineReducers({
    users,
    navbar,
    clients,
    shops,
    fournisseurs,
    produits,
    factureArticles,
    ventes,
    depenses,
    depenseFacture,
    venteFacture,
    categories,
    upload,
    company,
    statistic,
    agence,
    newFactureArticles
})

const rootReducer = (state, action) => {
    if (action.type === 'store/logout') {
        state = undefined
    }
    return combinedReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)