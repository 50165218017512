import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentItem } from '../../redux/reducers/navbar';
import Navbar from '../navbar/Navbar'
import AddFournisseur from './AddFournisseur';
import { RiDeleteBinLine, RiSearch2Line } from 'react-icons/ri';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FaFileImport } from 'react-icons/fa';

import { filterFournisseuList, setCurrentFournisseur } from '../../redux/reducers/fournisseurs';
import { CONTACTS_PATH, DETAIL_FOURNISSEURS_PATH } from '../../utils/navigation_paths';
import { IoMdEye } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import DeleteFournisseurModal from './DeleteFournisseurModal';
import './fournisseur.css'
import EditModalFournisseur from './EditModalFournisseur';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import ImportFournisseurModal from './ImportFournisseurModal';
import FournisseurDetail from './FournisseurDetail';
import { useNavigate } from 'react-router-dom';
import ServicesComponent from '../ServicesComponent';
import ServiceDataMap from '../ServiceDataMap';
import fournisseurs from '../../redux/actions/fournisseurs';
import { useAgenceId } from '../../hooks/useAgenceId';

const ListFournisseur = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.users.currentUser)
  const { fournisseurList } = useSelector(state => state.fournisseurs) || []
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
  const [detailModalFournisseur, setDetailModalFournisseur] = useState(false)
  const agenceId = useAgenceId()

  useEffect(() => {
    fournisseurs.fetchFournisseurs(agenceId, dispatch, currentUser.token.token)
    dispatch(setCurrentItem(CONTACTS_PATH))
  }, [])

  const showUpdateModal = (fournisseur) => {
    dispatch(setCurrentFournisseur(fournisseur))
    openUpdateClientModal()
  }
  const showDeleteModal = (fournisseur) => {
    dispatch(setCurrentFournisseur(fournisseur))
    openDeleteClientModal()
  }
  const showDetailsModal = (fournisseur) => {
    console.log({ fournisseur });
    dispatch(setCurrentFournisseur(fournisseur))
    navigate(DETAIL_FOURNISSEURS_PATH + '?id=' + fournisseur.id)
    console.log({ fournisseur });
  }

  const showActionButtons = (cell, row) => {
    return (
      <div>
        <IoMdEye
          data-tip
          data-for="detailsClientTip"
          className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon'
          onClick={() => {
            console.log({ row });
            showDetailsModal(row)
          }}
        />
        <FiEdit
          data-tip
          data-for="editClientTip"
          className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon'
          onClick={() => showUpdateModal(row)} />
        <RiDeleteBinLine
          data-tip
          data-for="deleteClientTip"
          className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon'
          onClick={() => showDeleteModal(row)} />
        {/* The tooltips below */}
        <ReactTooltip
          id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip
          id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip
          id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }

  const columns = [
    {
      dataField: "nom_complet",
      text: "Prénom et nom",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => {
        console.log({ row });
        showDetailsModal(row)
      }}>{row.firstName + ' ' + row.lastName}</p>
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.telephone}</p>
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.email}</p>
    },
    {
      dataField: "adresse",
      text: "Adresse",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.adresse}</p>
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: showActionButtons
    },
  ];

  function openUpdateClientModal() {
    setUpdateModalIsOpen(true);
  }
  function openDeleteClientModal() {
    setDeleteModalIsOpen(true);
  }
  function openDetailsFournisseurModal() {
    setDetailModalFournisseur(true)
  }

  const openAddFournisseur = () => {
    setAddModalIsOpen(true)
  }

  const openImportFournisseurModal = () => {
    setUploadModalIsOpen(true)
  }

  const pagination = paginationFactory({
    page: 1,
    sizePerPageList: [{
      text: '5', value: 5
    },
    {
      text: '10', value: 10
    },
    {
      text: '25', value: 25
    },
    {
      text: 'Tous', value: fournisseurList.length
    }]
  });
  return (
    <>
      <div className='row m-0 p-0'>
        {/* <Navbar /> */}
        <AddFournisseur modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
        <DeleteFournisseurModal modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />
        <ImportFournisseurModal modalIsOpen={uploadModalIsOpen} setIsOpen={setUploadModalIsOpen} />
        {
          updateModalIsOpen && <EditModalFournisseur modalIsOpen={updateModalIsOpen} setIsOpen={setUpdateModalIsOpen} />
        }
        {detailModalFournisseur && <FournisseurDetail modalIsOpen={detailModalFournisseur} setIsOpen={setDetailModalFournisseur} />}

        {/* <div className='row m-0 p-0 text-start px-md-5 my-4 justify-content-center app-padding'>
          <p className='fs-1 fw-bold my-0'>Fournisseur</p>
          <p className='my-0'>Créez, consultez et gérez vos enregistrements de clients.</p>
          <div className="row m-0 p-0 my-3">
            <div className="row mx-0 col-12 col-md-4">
              <div className='input-search-container m-0 p-0'>
                <label className='row'>
                  <RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />
                  <input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterFournisseuList(e.target.value))} />
                </label>
              </div>
            </div>
            <div className="group-btn my-md-0 col-12 col-md-8 mt-3 mt-md-0">
              <button className='btn btn-add-client me-3 rounded-0 shadow' onClick={openImportFournisseurModal}>Importer<FaFileImport className='fs-4' /></button>
              <button className='btn btn-add-client ms-2 rounded-0 shadow' type="button" onClick={openAddFournisseur}>Ajouter <AiFillPlusCircle className='fs-3' /></button>
            </div>
          </div>
        </div> */}

        <ServicesComponent
          title="Fournisseurs"
          description="Créez, consultez et gérez vos enregistrements de clients"
          labelIcon={<RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />}
          // input={<input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterFournisseuList(e.target.value))} />}
          onChange={e => dispatch(filterFournisseuList(e.target.value))}
          onClick1={openImportFournisseurModal}
          // firstBtnText="Importer"
          // firstBtn={<FaFileImport className='fs-4' />}
          onClick2={openAddFournisseur}
          secondBtnText="Ajouter"
          secondBtn={<AiFillPlusCircle className='fs-3' />}
        />

        <ServiceDataMap
          data={fournisseurList}
          columns={columns}
          pagination={pagination}
          messageOnError="Pas de fournisseur pour le moment"
        />
      </div>
    </>
  )
}

export default ListFournisseur
