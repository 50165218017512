import { createSlice } from "@reduxjs/toolkit/"
import { AiOutlinePlusCircle } from "react-icons/ai"

// let initialState = {
//     defaulArticle: { id: 'default', isSaved: false, isDisabled: true, quantite: 1 },
//     selectedArticles: [
//         { id: 'default', isSaved: false, isDisabled: false, quantite: 1 }
//     ],
//     currenteDepense: {},
//     isDepenseCreated: false,
//     isFournisseurChanged: false,
//     selectedFournisseur: null,
//     prouctsOptions: [],
//     allProuctsOptions: [],
//     depenseList: [],
//     allDepense: ['me']

// }
let initialState = {
    defaulArticle: { id: 'default', isSaved: false, isDisabled: true, quantite: 1 },
    selectedArticles: [
        { id: 'default', isSaved: false, isDisabled: false, quantite: 1 }
    ],
    depenseList: [],
    allDepense: [],
    currenteDepense: {},
    isDepenseCreated: false,
    isFournisseurChanged: false,
    selectedFournisseur: null,
    currentVenteActions: {},
    prouctsOptions: [],
    allProuctsOptions: [],
    currentDepenseDetails: {}

}
const depenseFacture = createSlice({
    name: 'depenseFacture',
    initialState,
    reducers: {
        getDepenseList: (state, { payload }) => {
            state.depenseList = payload
            state.allDepense = payload
        },
        setSelectedArticles: (state, { payload }) => {
            state.currentVente = payload
            state.isVenteCreated = true
            state.isClientChanged = false
            state.selectedArticles = payload.articles?.map(item => {
                // !on recupere que les produits qui ne font pas partie de la vente
                state.prouctsOptions = state.prouctsOptions.filter(opt => opt.value !== item.id)
                return {
                    ...state.defaulArticle,
                    id: item.id,
                    quantite: item.quantity,
                    product: {
                        value: item.product.id,
                        label: item.product.name,
                        max_qte: item.product.quantite,
                        prix: item.product.prixVente,
                    },
                    isDisabled: false,
                    isSaved: true
                }
            }).concat([{ ...state.defaulArticle, isDisabled: false }])
            state.selectedClient = { value: '', label: '' }
        },
        setCurrentDepenseDetails: (state, { payload }) => {
            state.currentDepenseDetails = payload
        },
        initializeCurrentDepenseDetails: (state) => {
            state.currentDepenseDetails = {}
        },
        setgetByArticlesProductOptions: (state, { payload }) => {
            console.log({ payload });
            // !ici on structure la liste des produits pour react-select {value:'', label:''}
            let options = payload.map(product => {
                return { value: product.id, label: product.product.name, prix: product.product.prixVente, max_qte: product.quantity }
            })
                .concat([{ value: '+', label: <p className='custom-mouse-clickable p-0 m-0'><AiOutlinePlusCircle /> Ajouter un produit</p> }])
            state.prouctsOptions = options
            state.allProuctsOptions = options
        },
        setProuctsOptions: (state, { payload }) => {
            // !ici on structure la liste des produits pour react-select {value:'', label:''}
            let options = payload.map(product => {
                return { value: product.id, label: product.name, prix: product.prixVente, max_qte: product.quantite }
            })
                .concat([{ value: '+', label: <p className='custom-mouse-clickable p-0 m-0'><AiOutlinePlusCircle /> Ajouter un produit</p> }])
            state.prouctsOptions = options
            state.allProuctsOptions = options
        },
        setCurrentDepens: (state, { payload }) => {
            state.isDepenseCreated = true
            state.currenteDepense = payload
            state.isFournisseurChanged = false
            state.selectedArticles = state.selectedArticles.map(item => {
                if (item.id === state.defaulArticle.id) {
                    item.isDisabled = false
                }
                return item
            })
        },
        setSelectedFournisseur: (state, { payload }) => {
            state.selectedFournisseur = payload
            state.isFournisseurChanged = true
        },
        reinitializeSelectedFournisseur: (state) => {
            state.selectedFournisseur = null
        },
        setArticleProduct: (state, { payload }) => {
            let { articleId, product } = payload

            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === articleId) {
                    article.product = product
                    article.errors = { ...article.errors, product: null, quantite: null, max_qte: null }
                    if (product.max_qte < 1) {
                        article.errors = { ...article.errors, max_qte: { message: 'Rupture de stock' } }
                    }
                }
                return article
            })
            // ! on supprime le produit sur les options de ventes(prouctsOptions), car on ne doit pas vendre le meme produit 2x
            state.prouctsOptions = state.prouctsOptions.filter(option => option.value !== product.value)
        },
        setArticleProdQte: (state, { payload }) => {
            let { articleId, quantite } = payload
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === articleId) {
                    article.quantite = quantite
                    article.errors = { ...article.errors, quantite: null }
                }
                return article
            })
        },
        saveNewArticle: (state, { payload }) => {
            console.log({ payload });
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === 'default') {
                    article = { ...article, id: payload.id, isSaved: true }
                }
                return article
            })
                .concat([{ ...state.defaulArticle, isDisabled: false }]);
            state.prouctsOptions = state.prouctsOptions.filter(item => item.value !== payload.products)
        },
        updateArticleById: (state, { payload }) => {
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id !== 'default') {
                    if (article.id === payload.id) {
                        article = { ...article, quantite: payload.quantite, isSaved: true }
                    }
                }
                return article
            })
        },
        deleteArticleById: (state, { payload }) => {
            state.selectedArticles = state.selectedArticles.filter(article => article.id !== payload.id)
            state.prouctsOptions = state.prouctsOptions.reverse().concat(
                state.allProuctsOptions.filter(item => item.value === payload.product.value)
            ).reverse()
        },
        setArticleEditable: (state, { payload }) => {
            console.log({ payload });
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === payload.id) {
                    article.isSaved = false
                }
                return article
            })
            state.prouctsOptions = state.prouctsOptions.reverse().concat(
                state.allProuctsOptions.filter(item => item.value === payload.product.value)
            ).reverse()
        },
        setError: (state, { payload }) => {
            let { articleId, error } = payload
            state.selectedArticles = state.selectedArticles.map(article => {

                if (article.id === articleId) {
                    article.errors = { ...article.errors, ...error }
                }
                return article
            })
        },
        reinitializeState: (state) => {
            state.selectedFournisseur = null
            state.selectedArticles = [{ id: 'default', isSaved: false, isDisabled: false, quantite: 1 }]
            state.currenteDepense = {}
            state.isDepenseCreated = false
            state.isFournisseurChanged = false
            state.selectedFournisseur = null
            state.prouctsOptions = []
        },
    }
})

export const { setSelectedArticles, setCurrentDepens, setSelectedFournisseur, setProuctsOptions, setArticleProduct, setArticleProdQte, saveNewArticle, updateArticleById, deleteArticleById, setArticleEditable, setError, reinitializeState, setCurrentDepenseDetails, setgetByArticlesProductOptions, initializeCurrentDepenseDetails, reinitializeSelectedFournisseur } = depenseFacture.actions
export default depenseFacture.reducer