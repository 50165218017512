import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxAction from '../../redux/actions';
import { CONTACTS_PATH, DETAILS_VENTES_PATH } from '../../utils/navigation_paths';
import { setCurrentItem } from '../../redux/reducers/navbar';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import { IoMdEye } from 'react-icons/io';
import { setCurrentVente } from '../../redux/reducers/ventes';
import { useNavigate } from 'react-router-dom';
import EntityDetails from '../EntityDetails';


const DetailsClientPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentClient = useSelector(state => state.clients.currentClient)
    const currentCompany = useSelector(state => state.company.currentCompany)
    const currentUser = useSelector(state => state.users.currentUser)
    const ventesList = useSelector(state => state.ventes.ventesList)
    const clientsventesList = ventesList.filter(vente => vente.client === currentClient.id)

    useEffect(() => {
        dispatch(setCurrentItem(CONTACTS_PATH))
        if(!currentClient.id){
            let id = parseInt(window.location.search.replace('?id=',''))
            if(id){
                ReduxAction.clients.getClientById(id,dispatch,currentUser.token?.token)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let {id,firstName,lastName,email,telephone,adresse} = currentClient
    
    
    const showDetailsModal =(prod)=>{
        dispatch(setCurrentVente(prod))
        navigate(DETAILS_VENTES_PATH + `?id=${prod.id}`)
    }
    const showActionButtons =(cell,row)=>{
        return (
          <div className='text-center'>
            <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable fs-5 custom-eye-icon' onClick={()=>showDetailsModal(row)}/>
        
            {/* The tooltips below */}
            <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
              Détails
            </ReactTooltip>
          </div>
        )
    }

    const columns = [
        {
          dataField: "id",
          text: "Numéro",
          headerClasses:'custom-table-column text-white col-1'
        },
        {
          dataField: "date",
          text: "Date",
          headerClasses:'custom-table-column text-white col-2',
          formatter:(cell,row)=> {
            var selected_date = new Date(row.date)
            return (
                    <span>
                        {selected_date?.toLocaleDateString('fr-SN')}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {selected_date?.toLocaleTimeString('fr-SN',{hour:'numeric',minute:'numeric'})}
                    </span>
                )
          }
        },
        {
          dataField: "montant",
          text: "Montant",
          headerClasses:'custom-table-column text-white col-2',
          formatter:(cell, row)=> {
            let montant_total = row.list_products.reduce((prev,prod)=>{
              return prev + prod.total
            },0);
            return montant_total +' XOF'
          }
        },
        {
          dataField: "emis",
          text: "Payé",
          headerClasses:'custom-table-column text-white col-1',
          formatter:(cell,row)=> row.status === 'ENCOURS'? 'Non':'Oui'
        },
        {
          dataField: "action",
          text: "Actions",
          headerClasses:'custom-table-column text-white col-1',
          formatter: showActionButtons
        },
    ];

    return (
        <div>
            {/* <Navbar/> */}
            <EntityDetails
                firstName={firstName}
                lastName={lastName}
                currentCompanyName={currentCompany.name}
                email={email}
                telephone={telephone}
                adresse={adresse}
                entityList={clientsventesList}
                columns={columns}
                errorMessage="Pas d'activité pour ce client"
                id={id}
            />
            {/* <div className="row m-0 p-0 text-start">
                <p className='fw-bold fs-4 ps-5 pt-5 '>Volkeno <span className='fw-normal'>(client)</span></p>
                <div className="col-10 m-auto fw-bold">
                    <p>informations générales</p>
                    <div className='row m-0 p-0 bg-light shadow-sm p-3'>
                        <div className="col-12 col-md">
                            <p><BiUserCircle className='fs-3'/> {firstName + ' '+ lastName}</p>
                            <p><BsBagPlus className='fs-4'/> {currentCompany.name||'Volkeno'}</p>
                        </div>
                        <div className="col-12 col-md">
                            <p><BiUserCircle className='fs-3'/> {email}</p>
                            <p><BsTelephone className='fs-4'/> {telephone}</p>
                        </div>
                        <div className="col-12 col-md">
                            <p><CgPentagonTopLeft className='fs-3   '/> {id}</p>
                            <p><HiOutlineLocationMarker className='fs-4'/> {adresse}</p>
                        </div>
                    </div>
                    <p className='mt-4'>Activités</p>
                    {clientsventesList.length > 0? (
                        <BootstrapTable
                        striped
                        classes='text-center'
                        keyField='table'
                        data={ clientsventesList }
                        columns={ columns }
                        />
                    ):
                    <p className='fs-4 text-secondary'>Pas d'activité pour ce client</p>
                }
                </div>
            </div> */}
        </div>
    );
};

export default DetailsClientPage;