const { default: axios } = require("axios")
const { UPLOAD_FILE } = require("../../routes/api_routes")
const { default: upload } = require("../reducers/upload")

module.exports = {
    uploadFile(data,token,dispatch){
        return new Promise((resolve,reject)=>{
            axios.post(UPLOAD_FILE,data,{headers:{ Authorization: `Bearer ${token}` }, withCredentials:true})
            .then(response =>{
                console.log("File", response )
                // if(response.data.id){
                //     let file = response.data
                //     dispatch(upload(file))
                //     resolve(response)
                // }else{
                //     reject(response)
                // }
            })
            .catch(err => reject(err))
        })
    },
}