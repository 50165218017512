import React from 'react';
import logo_fewnu from '../../img/logo_fewnu.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './navbar.css'
import { changeDrawerState, setCurrentItem } from '../../redux/reducers/navbar';
import { useDispatch } from 'react-redux';
import { CgMenu } from 'react-icons/cg'
import navbar_items from '../../utils/navbar_items'
import UserDropdownMenu from '../user/UserDropdownMenu';
import { ACCUEIL_PATH } from '../../utils/navigation_paths';

const Navbar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isDrawerOpen = useSelector(state => state.navbar.isDrawerOpen)
    const currentItem = useSelector(state => state.navbar.currentItem)
    const itemsClass = 'nav-item me-4 dropdown custom-mouse-clickable'
    const actifItemClass = 'active ' + itemsClass

    const showSidebar = () => {
        dispatch(changeDrawerState())
    }

    const handleclick = (itemPath, dropdownPath) => {
        dispatch(setCurrentItem(itemPath))
        if (dropdownPath) {
            navigate(dropdownPath)
        } else {
            navigate(itemPath)
        }
    }
    return (
        <div className={`row m-0 p-0 ${!isDrawerOpen ? 'sticky-top' : null}`}>
            <div className='row navbar-top-tool m-0 p-0'>
                <div className='row navbar-top-tool m-0 p-0 d-flex align-items-center app-padding'>
                    <div className='col me-auto text-start d-flex align-items-center gap-2'>
                        <img
                            style={{ cursor: 'default' }}
                            // onClick={() => navigate(ACCUEIL_PATH)}
                            className='custom-mouse-clickable ' src={logo_fewnu}
                            alt=""
                        />
                            <p className='custom-separator-bar-primary'></p>
                        <b className='text-white font-Montserrat ps-2'>
                            <span className=''>Compta & Facturation</span>
                        </b>
                    </div>
                    <div className="col text-end d-none d-md-block pe-3">
                        <UserDropdownMenu />
                    </div>
                </div>
                <div className="navbar-bottom-tool app-padding">
                    <div className='d-md-none'>
                        <div className='d-block d-md-none row'>
                            <div className='side-bar-tool text-start row p-0 m-0 d-flex align-items-center'>
                                <div className="col mt-1">
                                    <button className="side-toggler" type="button" onClick={showSidebar}>
                                        <CgMenu className='fs-2'></CgMenu>
                                    </button>
                                </div>
                                <div className="col mt- user-info text-end text-warining p-0 m-0">
                                    <UserDropdownMenu secondary={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className='navbar navbar-expand-md py-0 py-md-2'>
                        <div className='collapse navbar-collapse d-none d-md-flex' id="navbarSupportedContent">

                            <ul className='navbar-nav align-items-center'>
                                {
                                    navbar_items.map((item, index) => {
                                        if (item.dropdown) {
                                            return (
                                                <li key={index} className={currentItem === item.path ? actifItemClass : itemsClass}>
                                                    <div className="dropdown-center">
                                                        <span className="dropdown-toggle d-inline active" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {item.text}
                                                        </span>
                                                        <ul className="dropdown-menu p-2">
                                                            {
                                                                item.dropdown_items.map((child, index) => {
                                                                    return <li key={index} ><span className="dropdown-item py-2" onClick={() => handleclick(item.path, child.path)}>{child.text}</span></li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li className='me-3'>
                                                    <NavLink
                                                        key={index}
                                                        to={item.path}
                                                        cl
                                                    //  className={currentItem === item.path ? actifItemClass : itemsClass} 
                                                    //  onClick={() => handleclick(item.path)}
                                                    >
                                                    <span className='active'>{item.text}</span>
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Navbar;