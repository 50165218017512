import { createSlice } from "@reduxjs/toolkit/"
import { AiOutlinePlusCircle } from "react-icons/ai"

const defaultArticle = { id: 'default', isSaved: false, isDisabled: false, quantite: 1, product: { value: '', label: '', prix: '' } }
let initialState = {
    defaultArticle: defaultArticle,
    selectedArticles: [
        defaultArticle
    ],
    prouctsOptions: [],
    allProuctsOptions: [],

}
const factureArticles = createSlice({
    name: 'factureArticles',
    initialState,
    reducers: {
        setSelectedArticlesSlice: (state, { payload }) => {
            // console.log("factureArticles setSelectedArticles: ", payload);
            state.currentVente = payload
            state.isVenteCreated = true
            state.isClientChanged = false
            state.selectedArticles = payload.articles.map(item => {
                // !on recupere que les produits qui ne font pas partie de la vente
                state.prouctsOptions = state.prouctsOptions.filter(opt => opt.value !== item.id)
                return {
                    ...state.defaultArticle,
                    id: item.id,
                    quantite: item.quantity,
                    product: {
                        value: item.product.id,
                        label: item.product.name,
                        max_qte: item.product.quantite,
                        prix: item.product.prixVente,
                    },
                    isDisabled: false,
                    isSaved: true
                }
            }).concat([{ ...state.defaultArticle, isDisabled: false }])
            state.selectedClient = { value: '', label: '' }
        },
        setProuctsOptionsSlice: (state, { payload }) => {
            // console.log({ payload });
            // !ici on structure la liste des produits pour react-select {value:'', label:''}
            let options = payload.map(product => {
                return { value: product.id, label: product.name, prix: product.prixVente, max_qte: product.quantite }
            })
                .concat([{ value: '+', label: <p className='custom-mouse-clickable p-0 m-0'><AiOutlinePlusCircle /> Ajouter un produit</p> }])
            state.prouctsOptions = options
            state.allProuctsOptions = options
        },
        setSelectedClient: (state, { payload }) => {
            state.selectedClient = payload
            state.isClientChanged = true
        },
        setArticleProductSlice: (state, { payload }) => {
            let { articleId, product } = payload
            // ! L'objectif est de modifier la propriete "product"
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === articleId) {
                    article.product = product
                    article.errors = { ...article.errors, product: null, quantite: null, max_qte: null }
                    if (product.max_qte < 1) {
                        article.errors = { ...article.errors, max_qte: { message: 'Rupture de stock' } }
                    }
                }
                return article
            })
            // ! on supprime le produit sur les options de ventes(prouctsOptions), car on ne doit pas vendre le meme produit 2x
            state.prouctsOptions = state.prouctsOptions.filter(option => option.value !== product.value)
        },
        setArticleProdQteSlice: (state, { payload }) => {
            let { articleId, quantite } = payload
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === articleId) {
                    article.quantite = quantite
                    article.errors = { ...article.errors, quantite: null }
                }
                return article
            })
        },
        saveNewArticleSlice: (state, { payload }) => {
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === 'default') {
                    article = { ...article, id: payload.id, isSaved: true }
                }
                return article
            })
                .concat([{ id: 'default', isSaved: false, isDisabled: false, quantite: 1, product: { value: '', label: '', prix: 0 } }]);
            // state.prouctsOptions = state.prouctsOptions.filter(item => item.value !== payload.products)
        },
        updateArticleByIdSlice: (state, { payload }) => {
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === payload.id) {
                    article = { ...article, isSaved: true }
                }
                return article
            })
        },
        deleteArticleByIdSlice: (state, { payload }) => {
            // !enlever la vente dans les articles
            state.selectedArticles = state.selectedArticles.filter(article => article.id !== payload.id)
            // ! ramener le produit qui a ete supprimerlor 
            state.prouctsOptions = state.prouctsOptions.reverse().concat(
                state.allProuctsOptions.filter(item => item.value === payload.product.value)
            ).reverse()
        },
        setArticleEditableSlice: (state, { payload }) => {
            state.selectedArticles = state.selectedArticles.map(article => {
                if (article.id === payload.id) {
                    article.isSaved = false
                }
                return article
            })
            state.prouctsOptions = state.prouctsOptions.reverse().concat(
                state.allProuctsOptions.filter(item => item.value === payload.product.value)
            ).reverse()
        },
        setArticleErrorSlice: (state, { payload }) => {
            let { articleId, error } = payload
            state.selectedArticles = state.selectedArticles.map(article => {

                if (article.id === articleId) {
                    article.errors = { ...article.errors, ...error }
                }
                return article
            })
        },
        reinitializeArticlesState: (state, { payload }) => {
            state.selectedClient = null
            state.selectedArticles = [{ ...state.defaultArticle, isDisabled: false }]
            state.currentVente = {}
            state.isVenteCreated = false
            state.isClientChanged = false
            state.selectedClient = null
            state.prouctsOptions = []
        },
    }
})

export const { setSelectedArticlesSlice, setCurrentVent, setSelectedClient, setProuctsOptionsSlice, setArticleProductSlice, setArticleProdQteSlice, saveNewArticleSlice, updateArticleByIdSlice, deleteArticleByIdSlice, setArticleEditableSlice, setArticleErrorSlice, reinitializeArticlesState, setCurrentVenteDetails } = factureArticles.actions
export default factureArticles.reducer