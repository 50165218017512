import { createSlice } from "@reduxjs/toolkit/"

// let bcrypt = require('bcryptjs');
// let hash = localStorage.getItem('hash') || ''
// let userData = localStorage.getItem('currentUser') || '{}'

let initialState = {
    currentUser: {}
    // currentUser: JSON.parse(localStorage.getItem('currentUser')) || {}
}
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload
        },
        setLogout: (state) => {
            state.currentUser = {}
            localStorage.clear()
        }
    }
})

export const { setCurrentUser, setLogout } = usersSlice.actions
export default usersSlice.reducer