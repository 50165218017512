const { default: axios } = require("axios")

const { UPDATE_VENTE_API_ROUTE, ADD_DPENSES__ARICLE_API_ROUTE, UPDATE_DPENSES__ARICLE_API_ROUTE, DELETE_DPENSES__ARICLE_API_ROUTE, GET_DPENSES__ARICLE_API_ROUTE } = require("../../routes/api_routes")
const { saveNewArticle } = require("../reducers/depenseFacture")
const { setCurrentDepense, getDepenseList } = require("../reducers/depenses")
const { updateArticleByIdSlice, deleteArticleByIdSlice } = require("../reducers/factureArticles")


module.exports = {
    getArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.get(GET_DPENSES__ARICLE_API_ROUTE, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    if (res.data.data) {
                        let depense = res.data.data
                        dispatch(getDepenseList(depense))
                        resolve(depense)
                    } else {
                        reject(res)
                    }
                })
        })
    },
    createNewArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_DPENSES__ARICLE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 201) {
                        let newArtcle = response.data
                        dispatch(saveNewArticle(newArtcle))
                        resolve(newArtcle)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateArticle(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_DPENSES__ARICLE_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let article = response.data.data
                        // console.log({ article });
                        // dispatch(updateDepenseSlice(article))
                        dispatch(updateArticleByIdSlice(article))
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteArticle(article, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_DPENSES__ARICLE_API_ROUTE + `${article.articleId}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        dispatch(deleteArticleByIdSlice(article))
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    changeDepenseFournisseur(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_API_ROUTE + `${id}/`, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data.id) {
                        let newDepense = response.data
                        dispatch(setCurrentDepense(newDepense))
                        resolve(newDepense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
}
