import React, { useState } from "react";
// import Modal from 'react-modal';
import "./add_clients.css";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import {
  isValidMobilePhone,
  isValidText,
} from "../../utils/form_control";
import { useCurrentUserId } from "../../hooks/useCurrentUserId";
import { useAgenceId } from "../../hooks/useAgenceId";
import { useProprietaireId } from "../../hooks/useProprietaireId";
import Modal from "../../shared/Modal";

const AddClient = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const defaultMessage = "Ce champ est obligatoire !";
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();
  const currentUserId = useCurrentUserId();
  const agenceId = useAgenceId();
  const [loading, setLoading] = useState(false);
  const proprietaireId = useProprietaireId();

  function closeModal() {
    reset();
    setIsOpen(false);
  }

  const submit = (data) => {
    data.createdBy = currentUserId;
    data.agenceId = agenceId;
    data.proprietaireId = proprietaireId;
    setLoading(true);
    ReduxAction.clients
      .createNewClient(data, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Client ajouté avec succés", toast_options);
      })
      .catch((err) => {
        if (err?.response.status === 400) {
          if (err.response.data.telephone) {
            console.log(err.response.data.telephone);
            return setError("telephone", {
              message: "Ce numéro de téléphone existe déjà",
            });
          }
        }
        toast.error("L'ajout n'a pas réuissi", toast_options);
      })
      .finally(() => {
        closeModal();
        setLoading(false);
      });
  };
  const verificate = (data) => {
    if (!isValidText(data.firstName)) {
      setError("firstName", { message: "Prénom non valide" });
    } else if (!isValidText(data.lastName)) {
      setError("lastName", { message: "Nom non valide" });
    }
    // else if (!isValidEmail(data.email)) {
    //     setError('email', { message: 'Email non valide' })
    // }
    else if (!isValidMobilePhone(data.telephone)) {
      setError("telephone", { message: "Numéro de tel non valide" });
    } else {
      submit(data);
    }
  };
  return (
    <Modal
      // overlayClassName={"add-client-modal-overlay"}
      opened={modalIsOpen}
      // onAfterOpen={afterOpenModal}
      onClosed={closeModal}
      // style={customStyles}
      title="Créer un nouveau client"
    >
      <form className="px-3 py-3 my-0" onSubmit={handleSubmit(verificate)}>
        {/* <div className="row m-0 p-0">
          <h6 className="col-9 ">Créer un nouveau client</h6>
          <p className="col text-end fw-bold fs-4">
            <span
              className=" border-dark rounded px-2 custom-mouse-clickable"
              onClick={closeModal}
            >
              X
            </span>
          </p>
        </div> */}
        <div className="row my-md-3">
          <div className="col-12 col-md  add-client-input">
            <label className="add-client-input">
              Prénom
              <input
                type="text"
                {...register("firstName", { required: defaultMessage })}
                className="form-control p-2"
              />
              {errors.firstName && (
                <span className="custom-color-danger">
                  {errors.firstName.message}
                </span>
              )}
            </label>
          </div>
          <div className="col-12 col-md mt-3 mt-md-0 ms-md-2  add-client-input">
            <label className="add-client-input">
              Nom
              <input
                type="text"
                {...register("lastName", { required: defaultMessage })}
                className="form-control p-2"
              />
              {errors.lastName && (
                <span className="custom-color-danger">
                  {errors.lastName.message}
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md mt-3 mt-md-0  add-client-input">
            <label className="add-client-input">
              Email
              <input
                type="email"
                {...register("email")}
                className="form-control p-2"
              />
              {/* {errors.email && <span className='custom-color-danger'>{errors.email.message}</span>} */}
            </label>
          </div>
          <div className="col-12 col-md mt-3 mt-md-0 ms-md-2  add-client-input">
            <label className="add-client-input">
              Mobile
              <input
                type="number"
                {...register("telephone", { required: defaultMessage })}
                className="form-control p-2"
              />
              {errors.telephone && (
                <span className="custom-color-danger">
                  {errors.telephone.message}
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="row my-md-3">
          {/* <div className="col-12 col-md mt-3 mt-md-0 add-client-input">
                            <label className='add-client-input'>
                                Référence
                                <input type='number' {...register('reference')} className='form-control p-2' />
                                {errors.reference && <span className='custom-color-danger'>{errors.reference.message}</span>}
                            </label>
                        </div> */}
          <div className="col-12 col-md mt-3 mt-md-0 ms-md-2 add-client-input">
            <label className="add-client-input">
              Adresse
              <input
                type="text"
                {...register("adresse")}
                className="form-control p-2"
              />
              {errors.adresse && (
                <span className="custom-color-danger">
                  {errors.adresse.message}
                </span>
              )}
            </label>
          </div>
        </div>
        <p className="text-end mt-4">
          <button
            style={{ opacity: loading ? 0.5 : 1 }}
            disabled={loading}
            type="submit"
            className="custom-button-secondary rounded-0 py-2 px-3 mt-3"
          >
            {!loading ? "Ajouter" : "Ajout en cours..."}
          </button>
        </p>
      </form>
    </Modal>
  );
};

export default AddClient;
