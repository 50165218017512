const { AiFillHome, AiFillSetting } = require('react-icons/ai')
const { IoMdContact } = require('react-icons/io')
const { GiBuyCard } = require('react-icons/gi')
const { MdOutlineProductionQuantityLimits } = require('react-icons/md')
const { ACCUEIL_PATH, CONTACTS_PATH, CLIENTS_PATH, FOURNISSEURS_PATH, VENTES_PATH, PRODUCTS_PATH, PARAMETTRES_PATH, DEPENSE_PATH } = require('./navigation_paths')
module.exports = [
    // {
    //     path:ACCUEIL_PATH,
    //     text:'Accueil',
    //     icon:AiFillHome,
    // },
    // {
    //     path:CONTACTS_PATH,
    //     text:'Contacts',
    //     icon:IoMdContact,
    //     dropdown:true,
    //     dropdown_items:[
    //         {
    //             path:CLIENTS_PATH,
    //             text:'Clients'
    //         },
    //         {
    //             path:FOURNISSEURS_PATH,
    //             text:'Fournisseurs'
    //         },
    //     ]
    // },
    {
        path: VENTES_PATH,
        text: 'Ventes',
        icon: GiBuyCard,
    },
    {
        path: DEPENSE_PATH,
        text: 'Dépenses',
        icon: MdOutlineProductionQuantityLimits,
    },
    {
        path: CLIENTS_PATH,
        text: 'Clients'
    },
    {
        path: FOURNISSEURS_PATH,
        text: 'Fournisseurs'
    },
    {
        path: PRODUCTS_PATH,
        text: 'Produits',
        icon: MdOutlineProductionQuantityLimits,
    },
    {
        path: PARAMETTRES_PATH,
        text: 'Paramètres',
        icon: AiFillSetting,
    },
]