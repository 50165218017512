import React, { useEffect, useState } from 'react';
import './produits.css'
import { RiSearch2Line } from 'react-icons/ri';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { filterProduitsList, setCurrentProduit } from '../../redux/reducers/produits';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import UdateProduitModal from './UdateProduitModal';
import DeleteProduitsModal from './DeleteProduitsModal';
import DetailsProduitsModal from './DetailsProduitsModal';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import AddProduitsModal from './AddProduitsModal';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ServicesComponent from '../ServicesComponent';
import ServiceDataMap from '../ServiceDataMap';
import ApiController from '../../redux/actions';
import { useToken } from '../../hooks/useToken';
import { useAgenceId } from '../../hooks/useAgenceId';

const ListProduits = () => {

  const dispatch = useDispatch()
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const { produitsList } = useSelector(state => state.produits)
  // const agenceId = useCurrentUserId()
  const token = useToken()
  let agenceId = useAgenceId()

  // console.log({ produitsList });

  useEffect(() => {
    // setProductLoading(false)
    // ApiController.produits.getProductsList(agenceId, dispatch, token)
    ApiController.produits.getProductsList(agenceId, dispatch, token)
    ApiController.categories.getCategoriesList(agenceId, dispatch, token)
  }, [agenceId, dispatch, token])

  const showUpdateModal = (prod) => {
    dispatch(setCurrentProduit(prod))
    openUpdateProductModal()
  }
  const showDeleteModal = (prod) => {
    dispatch(setCurrentProduit(prod))
    openDeleteProductModal()
  }
  const showActionButtons = (cell, row) => {
    return (
      <div >
        {/* <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={()=>showDetailsModal(row)}/> */}
        <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable fs-5 me-3 custom-edit-icon' onClick={() => showUpdateModal(row)} />
        <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable ms-3 fs-5 custom-delete-icon' onClick={() => showDeleteModal(row)} />
        {/* The tooltips below */}
        <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      headerClasses: 'custom-table-column text-white'
    },
    {
      dataField: "category.categoryName",
      text: "Catégorie",
      headerClasses: 'custom-table-column text-white'
    },
    {
      dataField: "prixAchat",
      text: "Prix d'achat",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => row.prixAchat + ' XOF'
    },
    {
      dataField: "prixVente",
      text: "Prix de vente",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => row.prixVente + ' XOF'
    },
    {
      dataField: "quantite",
      text: "Quantité",
      headerClasses: 'custom-table-column text-white col-1'
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: showActionButtons
    },
  ];

  function openAddProductModal() {
    setAddModalIsOpen(true);
  }
  function openUpdateProductModal() {
    setUpdateModalIsOpen(true);
  }
  function openDeleteProductModal() {
    setDeleteModalIsOpen(true);
  }

  const pagination = paginationFactory({
    page: 1,
    sizePerPageList: [{
      text: '5', value: 5
    },
    {
      text: '10', value: 10
    },
    {
      text: '25', value: 25
    },
    {
      text: 'Tous', value: produitsList?.length
    }]
  });

  return (
    <div className='row m-0 p-0 list-clients'>
      {/* <Navbar /> */}
      <AddProduitsModal modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
      <DeleteProduitsModal modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />
      {updateModalIsOpen && <UdateProduitModal
        modalIsOpen={updateModalIsOpen}
        setIsOpen={setUpdateModalIsOpen}
      />}
      {detailsModalIsOpen && <DetailsProduitsModal
        modalIsOpen={detailsModalIsOpen}
        setIsOpen={setDetailsModalIsOpen} />}

      <ServicesComponent
        title="Produits"
        description="Créez, consultez et gérez vos produits"
        labelIcon={<RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />}
        // input={<input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterProduitsList(e.target.value))} />}
        onChange={e => dispatch(filterProduitsList(e.target.value))}
        onClick1={openAddProductModal}
        firstBtnText="Créer un produit "
        firstBtn={<AiFillPlusCircle className='fs-3' />}
        onClick2={null}
        secondBtnText=""
        secondBtn={null}
      />

      <ServiceDataMap
        data={produitsList}
        columns={columns}
        pagination={pagination}
        messageOnError="Pas d'historique pour le moment"
      />
    </div>
  );
};

export default ListProduits;