import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FactureProgressBar from './FactureProgressBar';
import { UPDATE_DEPENSE_PATH } from '../../utils/navigation_paths';
import ReduxAction from '../../redux/actions';
import SendEmailModal from './SendEmailModal';
import DeleteDepense from './DeleteDepense';
import PaymentDepenseModal from './PaymentDepenseModal';
import FactureDetails from '../facture/FactureDetails';
import { useNavigate } from 'react-router-dom';
import { setFactureSelectedFournisseur, setIsUpdatingFacture } from '../../redux/reducers/new/newFactureArticles';

const DetailsDepense = () => {
    const dispatch = useDispatch()
    const { currentDepense } = useSelector(state => state.depenses)
    const { currentDepenseDetails } = useSelector(state => state.depenseFacture)
    const currentUser = useSelector(state => state.users.currentUser)
    const currentCompany = useSelector(state => state.company.currentCompany)
    const [loadingFacture, setLoadingFacture] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
    const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
    const navigate = useNavigate()

    let factureDate = new Date(currentDepenseDetails?.depense?.created_at)
    let total = currentDepenseDetails?.depense?.montantTotal || 0

    const venteTableFormat = currentDepenseDetails?.articles

    const columns = useMemo(() => [
        {
            dataField: "product.name",
            text: "Désignation",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "quantity",
            text: "Quantité",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "product.prixAchat",
            text: "Prix",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "montant",
            text: "Total",
            headerClasses: 'custom-table-column text-white',
        }
    ], [])

    useEffect(() => {
        setLoadingFacture(true)
        dispatch(setIsUpdatingFacture(false))
        ReduxAction.depenses.getDepenseByID(currentDepense.id, dispatch, currentUser.token?.token).finally(() => {
            setLoadingFacture(false)
        })
    }, []);

    function openPaymentModal() {
        setPaymentModalIsOpen(true);
    }

    const onEdit = () => {
        // ! on active la mise a jour
        dispatch(setIsUpdatingFacture(true))
        if (currentDepense.fournisseur) {
            let fournisseurId = currentDepense.fournisseur
            let fullName = currentDepense.fournisseur.firstName + ' ' + currentDepense.fournisseur.lastName
            // ! we need the selected fournisseur to show in the select form when we edit
            dispatch(setFactureSelectedFournisseur({ value: fournisseurId, label: fullName }))
        }
        // console.log('edit');
        navigate(UPDATE_DEPENSE_PATH + `?id=${currentDepense.id}`)
    }

    return (
        <>
            <DeleteDepense modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} shouldNavigate={true} />
            {emailModalIsOpen && (
                <SendEmailModal modalIsOpen={emailModalIsOpen} setIsOpen={setEmailModalIsOpen} />
            )}
            <PaymentDepenseModal modalIsOpen={paymentModalIsOpen} setIsOpen={setPaymentModalIsOpen} somme_total={total} />
            <FactureDetails
                factureFor="Fournisseur"
                loadingFacture={loadingFacture}
                columns={columns}
                dataTable={venteTableFormat}
                factureProgressBar={FactureProgressBar}
                transactionStatus={currentDepense.status}
                totalTransaction={total}
                logo={currentCompany.logo}
                fullName={currentDepense.fournisseur?.firstName ? currentDepense?.fournisseur?.firstName + ' ' + currentDepense?.fournisseur?.lastName : "............................"}
                phone={currentDepense.fournisseur?.telephone ?? '.............................'}
                // messageOnEmptyFullName="Pas de fournisseur"
                factureDate={factureDate}
                numbreJoursRestants={0}
                navigateToEdit={UPDATE_DEPENSE_PATH}
                currentTransactionId={currentDepense.id}
                transactionDate={currentDepense.date}
                openPaymentModal={openPaymentModal}
                onEdit={onEdit}
            />
        </>
    );
}

export default DetailsDepense
