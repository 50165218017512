import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentUserId } from '../hooks/useCurrentUserId'
import { VENTES_PATH } from '../utils/navigation_paths'
import { useAgenceId } from '../hooks/useAgenceId';
import { useCurrentUser } from '../hooks/useCurrentUser';

const PublicLayout = () => {
    const userId = useCurrentUserId()
    // const agenceId = useAgenceId()   
    const currentUser = useCurrentUser()
    // console.log({ currentUser });

    return (!currentUser) ? (
        <Outlet />
    ) : <Outlet />
}

export default PublicLayout