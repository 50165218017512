import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentVente: {},
    ventesList: [],
    allVentesList: [],
}
const ventesSlice = createSlice({
    name: 'ventes',
    initialState,
    reducers: {
        addNewVente: (state, { payload }) => {
            state.ventesList = [payload, ...state.allVentesList]
            state.allVentesList = [payload, ...state.allVentesList]
        },
        setCurrentVente: (state, { payload }) => {
            // console.log('current vente: ' + payload);
            state.currentVente = payload
        },
        updateVenteSlice: (state, { payload }) => {
            let vente = state.allVentesList.filter(vente => vente.id === payload.id)[0]
            const copyVentes = [...state.allVentesList]
            const indexOfVente = state.allVentesList.indexOf(vente)
            copyVentes[indexOfVente] = payload

            state.allVentesList = copyVentes
            state.ventesList = copyVentes
            // state.currentVente = payload
        },
        deleteVenteSlice: (state, { payload }) => {
            // console.log({ payload });
            let ventes = state.allVentesList.filter(vente => vente.id !== payload)
            state.allVentesList = ventes
            state.ventesList = ventes
        },
        setVentesList: (state, { payload }) => {
            state.ventesList = payload
            state.allVentesList = payload
        },
        filterVentesList: (state, { payload }) => {
            if (payload === '') {
                state.ventesList = state.allVentesList
            } else {
                state.ventesList = state.allVentesList.filter(vente => {
                    let champs = [vente?.client?.firstName, vente?.client?.lastName, vente?.telephone, vente?.email]
                    let txt = champs.join(' ').toLowerCase()
                    return txt.includes(payload.toLowerCase())
                })
            }
        },
        reInitializeVentes: (state) => {
            state.ventesList = {}
            state.allVentesList = []
            state.currentVente = []
        }
    }
})

export const { addNewVente, setCurrentVente, updateVenteSlice, deleteVenteSlice, setVentesList, filterVentesList, reInitializeVentes } = ventesSlice.actions
export default ventesSlice.reducer