import React, { useState } from "react";
import AddFactureSelectForm from "../../AddFactureSelectForm";
import ApiController from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useToken } from "../../../hooks/useToken";
import { setFactureSelectedClient } from "../../../redux/reducers/new/newFactureArticles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddClient from "../../clients/AddClientModal";

const SelectFactureClient = ({ isUpdating }) => {
  const dispatch = useDispatch();
  const token = useToken();
  const { currentVente } = useSelector((state) => state.ventes);
  const { selectedFactureClient } = useSelector(
    (state) => state.newFactureArticles
  );
  const clientsList = useSelector((state) => state.clients.clientsList);
  const [clientModalIsOpen, setClientModalIsOpen] = useState(false);
  const addNewClient = () => {
    setClientModalIsOpen(true);
  };
  // console.log({ selectedFactureClient });
  const clientsOptions = clientsList?.map((fournisseur) => {
    return {
      value: fournisseur.id,
      label: fournisseur.firstName + " " + fournisseur.lastName,
    };
  });
  // .concat([{ value: '+', label: <p className='custom-mouse-clickable text-secondary p-0 m-0' onClick={addNewClient}><AiOutlinePlusCircle /> Ajouter un client</p> }])
  // .reverse();

  const changeSelectedClient = (client) => {
    //! on selectionne un client pour la vente
    // console.log({ client });
    dispatch(setFactureSelectedClient(client));
    if (isUpdating) {
      // if (selectedClient) {
      ApiController.ventes
        .updateVente(
          currentVente.id,
          { clientId: client.value },
          token,
          dispatch
        )
        .then((res) => {
          // navigate(-1)
        });
      // }
      return;
    }
  };
  return (
    <div className="row p-2">
      <div className="col-8">
        <AddFactureSelectForm
          title="Clients"
          concerned="client"
          placeholder="--selectionner client--"
          // handleSubmit={handleSubmit(submit)}
          // handleSubmit={handleSubmit(submit)}
          value={selectedFactureClient}
          onChange={(client) =>
            client.value !== "+" &&
            client.value !== selectedFactureClient?.value &&
            changeSelectedClient(client)
          }
          options={clientsOptions}
          // error={errors.client && !selectedClient && <span className='custom-color-danger text-center'>{errors.client.message}</span>}
          modal={
            <AddClient
              modalIsOpen={clientModalIsOpen}
              setIsOpen={setClientModalIsOpen}
            />
          }
          // firstCondition={isVenteCreated}
          // secondCondition={isClientChanged}
          // secondConditionProove={isVenteCreated}
        />
      </div>
      <div className="col-4 ps-2">
        <button
          onClick={addNewClient}
          type="button"
          className="btn btn-add p-2 px- w-100"
        >
          + Client
        </button>
      </div>
    </div>
  );
};

export default SelectFactureClient;
