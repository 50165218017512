import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentClient: {},
    clientsList: [],
    allClientsList: [],
}
const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        addNewClient: (state, { payload }) => {
            state.clientsList = [...state.allClientsList, payload].reverse()
            state.allClientsList = [...state.allClientsList, payload].reverse()
        },
        updateClientInList: (state, { payload }) => {
            let clients = state.allClientsList.filter(client => client.id !== payload.id)
            clients = [...clients, payload]
            state.allClientsList = clients
            state.clientsList = clients
        },
        archiveClientInList: (state, { payload }) => {
            let clients = state.allClientsList.filter(client => client.id !== payload.id)
            state.allClientsList = clients
            state.clientsList = clients
        },
        setCurrentClient: (state, { payload }) => {
            state.currentClient = payload
        },
        setClientsList: (state, { payload }) => {
            state.clientsList = payload
            state.allClientsList = payload
        },
        filterClientsList: (state, { payload }) => {
            state.clientsList = state.allClientsList.filter(item => {
                let data = [item.firstName, item.telephone, item.lastName, item.email, item.adresse].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const { addNewClient, setCurrentClient, setClientsList, filterClientsList, updateClientInList, archiveClientInList } = clientsSlice.actions
export default clientsSlice.reducer