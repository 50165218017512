import React, { Profiler } from 'react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap'
import './index.css';
import './fonts.css';
import './tables.css';
import './custom.css';
import { store, persistor } from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const callback = (id, phase, actualDuration, startTime,
  baseDuration, commitTime, interactions) => {
  console.log(
    "id: " + id + ', ' +
    " startTime: " + startTime + ', ' +
    " actualDuration: " + actualDuration + ', ' +
    " baseDuration: " + baseDuration + ', ' +
    " commitTime: " + commitTime + ', ' +
    " phase: " + phase + ', ' +
    " interactions: " + interactions
  );
}


// if (process.env.REACT_APP_NODE_ENV === "production") {
//   console.log = () => { };
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Profiler id='app' onRender={callback}> */}
          <App />
          {/* </Profiler> */}
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
