import React, { useState } from "react";
// import Modal from 'react-modal';
import "./add_ventes.css";
import { useSelector, useDispatch } from "react-redux";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import ApiController from "../../redux/actions";
import Modal from "../../shared/Modal";

const PaymentVenteModal = ({ modalIsOpen, setIsOpen, somme_total = 0 }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentVente = useSelector((state) => state.ventes.currentVente);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      height: "44%",
      // width: '30%',
      padding: "1rem",
      minWidth: "300px",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const PayVente = () => {
    setLoading(true);
    let data = { status: "PAYE" };
    ApiController.ventes
      .updateVente(currentVente.id, data, currentUser.token.token, dispatch)
      .then((response) => {
        closeModal();
        toast.success("Facture Payé avec succés", toast_options);
        ReduxAction.all.getAllData(
          currentUser.token.token,
          dispatch,
          ReduxAction,
          currentUser.id
        );
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("La payment n'a pas réuissi", toast_options);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    // <div>
    <Modal
      opened={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onClosed={closeModal}
      title="Confirmer le Payment"
    >
      <div className="row m-0 p-3">
        {/* <div className="row m-0 p-0">
                    <p className="fs-4 px-3">Confirmer le Payment</p>
                </div>
                <hr /> */}
        <p className="text-center py-3">
          Le montant total a payer est de <br /> <b>{somme_total} XOF</b>
        </p>
        <hr />
        <div className="text-center mt-3">
          <button
            type="submit"
            className="bg-secondary text-white shadow rounded border-0 py-2 px-4 mx-3 mt-3"
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            disabled={loading}
            type="submit"
            className="custom-button-secondary py-2 px-3 mx-3 mt-3"
            onClick={PayVente}
          >
            {!loading ? "Confirmer" : "Paiement en cours..."}
          </button>
        </div>
      </div>
    </Modal>
    // </div>
  );
};

export default PaymentVenteModal;
