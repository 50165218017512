import React, { useEffect, useState } from 'react'
import NewFactureArticles from '../../facture/new/NewFactureArticles'
import { useDispatch, useSelector } from 'react-redux'
import { useToken } from '../../../hooks/useToken'
import { useAgenceId } from '../../../hooks/useAgenceId'
import ApiController from '../../../redux/actions'
import { resetFactureSelectedClient, setIsUpdatingFacture } from '../../../redux/reducers/new/newFactureArticles'
import { useCurrentUserId } from '../../../hooks/useCurrentUserId'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ButtonLoading from '../../shared/ButtonLoading'
import SelectFactureClient from '../../facture/new/SelectFactureClient'

const NewVentePage = () => {
    const { currentFacture, selectedFactureClient, disableProduct } = useSelector(state => state.newFactureArticles)
    // console.log({ currentFacture });
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const token = useToken()
    let agenceId = useAgenceId()
    const userId = useCurrentUserId()
    const navigate = useNavigate()
    const [isProductLoading, setIsProductLoading] = useState(false)

    useEffect(() => {
        setIsProductLoading(true)
        ApiController.produits.getProductsList(agenceId, dispatch, token)
            .finally(() => setIsProductLoading(false))
    }, [agenceId, dispatch, token])

    useEffect(() => {
        dispatch(setIsUpdatingFacture(false))
        dispatch(resetFactureSelectedClient())
        // dispatch(resetCurrentFacture())
    }, [dispatch])

    const confirmFacture = () => {
        const articles = currentFacture.map(article => {
            const data = {
                productId: Number(article.product.id),
                quantity: Number(article.quantity),
                price: article.product.prixVente
            }
            return data
        })

        const newVente = {
            clientId: selectedFactureClient ? selectedFactureClient.value : '',
            createdBy: userId,
            agenceId,
            articles
        }

        setLoading(true)
        ApiController.ventes.createNewVente(newVente, token, dispatch).then(res => {
            toast.success('Vente effectuée')
            navigate(-1)
        }).catch(error => {
            toast.error('Une erreur est survenu')
        })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Nouvelle facture de vente</p>
            </div>
            <NewFactureArticles
                loadingResource={isProductLoading}
            >
                <SelectFactureClient />
                <ButtonLoading
                    disabled={disableProduct || !currentFacture.length}
                    text="Valider"
                    loading={loading}
                    onClick={confirmFacture}
                />
            </NewFactureArticles>
        </div>
    )
}

export default NewVentePage