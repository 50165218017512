import React, { useEffect, useState } from 'react'
import ApiController from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useToken } from '../../../hooks/useToken'
import { useNavigate } from 'react-router-dom'
import NewFactureArticles from './NewFactureArticles'
import { addNewArticlesToCurrentFacture, removeArticleFromCurrentFacture, setIsFactureForDepense } from '../../../redux/reducers/new/newFactureArticles'
import { toast } from 'react-toastify'
import SelectFactureFournisseur from '../../depenses/new/SelectFactureFournisseur'
import ButtonLoading from '../../shared/ButtonLoading'

const NewUpdateDepense = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { disableProduct, currentFacture } = useSelector(state => state.newFactureArticles)
    const currenteDepense = useSelector(state => state.depenses.currentDepense)
    // const currentVenteId = currentVente.id
    const token = useToken()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        ApiController.depenses.getDepenseByID(currenteDepense.id, dispatch, token).finally(() => setLoading(false))
    }, [currenteDepense.id, dispatch, token])

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Modification de la facture de dépense</p>
            </div>
            <NewFactureArticles
                loadingResource={loading}
                createNewFactureArticle={(product) => {
                    dispatch(addNewArticlesToCurrentFacture(product))
                    let index = currentFacture.findIndex(article => article.product.id === product.id)
                    // console.log({ index, product });
                    if (index !== -1) {
                        let article = currentFacture.find(article => article.product.id === product.id)
                        // console.log({ article });
                        ApiController.depenseFacture.updateArticle(article.articleId, { quantity: article.quantity + 1 }, token, dispatch)
                        return
                    }
                    ApiController.depenses.createNewDepenseArticle(
                        { quantity: 1, productId: product.id, depense: currenteDepense.id, price: product.prixAchat },
                        token,
                        dispatch
                    )
                        .catch(() => {
                            toast.error("Une erreur est survenue lors de l'ajout d'un nouvel article, veuillez réessayer")
                            // !on supprime le produit sur la currentFacture et on le remet dans la liste des produits en cas d'erreur
                            dispatch(removeArticleFromCurrentFacture({ product }))
                        })
                }}
                deleteFactureArticle={(article) => {
                    dispatch(removeArticleFromCurrentFacture({ product: article.product }))
                    ApiController.depenseFacture.deleteArticle(article, token, dispatch)
                        .catch(() => {
                            toast.error('Une erreur est survenue lors de la suppression, veuillez réessayer')
                            dispatch(addNewArticlesToCurrentFacture(article.product))
                        })
                }}
                updateArticleQuantityOnChange={(article, data) => {
                    ApiController.depenseFacture.updateArticle(article.articleId, data, token, dispatch)
                }}
            >
                <SelectFactureFournisseur
                    isUpdating={true}
                />
                <ButtonLoading
                    disabled={disableProduct}
                    text="Modifier"
                    loading={null}
                    onClick={() => navigate(-1)}
                />
            </NewFactureArticles>
        </div>
    )
}

export default NewUpdateDepense