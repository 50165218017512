import users from './users';
import clients from './clients';
import shops from './shops';
import fournisseurs from './fournisseurs';
import produits from './produits';
import ventes from './ventes';
import depenses from './depenses';
import all from './all';
import categories from './categories';
import ventesFacture from './ventesFacture';
import depenseFacture from './depenseFacture';
import uploadFile from './upload';
import company from './company';
import statistic from './statistic';




const ApiController = {
    users,
    clients,
    shops,
    fournisseurs,
    produits,
    ventes,
    ventesFacture,
    depenses,
    depenseFacture,
    all,
    categories,
    uploadFile,
    company,
    statistic,
}
export default ApiController