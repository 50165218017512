import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import ReduxAction from "../../redux/actions";
import { toast } from "react-toastify";
import toast_options from "../../utils/toast_options";
import { archiveFournisseurSlice } from "../../redux/reducers/fournisseurs";
import DeleteModal from "../../shared/DeleteModal";

const DeleteFournisseurModal = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentFournisseur = useSelector(
    (state) => state.fournisseurs.currentFournisseur
  );

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      height: "44%",
      width: "30%",
      minWidth: "300px",
      shadow: "0px 0px 5px black",
      background: "#eee",
      borderRadius: "0px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const deleteFournisseur = () => {
    console.log({ currentFournisseur: currentFournisseur.id });
    ReduxAction.fournisseurs
      .archiveFournisseur(
        currentFournisseur.id,
        currentUser.token.token,
        dispatch
      )
      .then((response) => {
        dispatch(archiveFournisseurSlice(currentFournisseur));
        closeModal();
        toast.success("Fournisseur supprimé avec succés", toast_options);
      })
      .catch((err) => {
        console.log(err);
        closeModal();
        toast.error("La suppression n'a pas réuissi", toast_options);
      });
  };
  return (
    <DeleteModal
      opened={modalIsOpen}
      onClosed={closeModal}
      //   loading={loading}
      onDelete={deleteFournisseur}
    />
  );
};

export default DeleteFournisseurModal;
