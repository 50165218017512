import React, { useEffect, useState } from 'react';
import './ventes.css'
import { RiSearch2Line } from 'react-icons/ri';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCurrentVente, filterVentesList } from '../../redux/reducers/ventes';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdEye } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { ADD_VENTES_PATH, DETAILS_VENTES_PATH, UPDATE_VENTES_PATH } from '../../utils/navigation_paths';
import ReactTooltip from 'react-tooltip';
import tooltip_options from '../../utils/tooltip_options';
import DeleteVenteModal from './DeleteVenteModal';
import { useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ServicesComponent from '../ServicesComponent';
import ServiceDataMap from '../ServiceDataMap';
import ApiController from '../../redux/actions';
import { useAgenceId } from '../../hooks/useAgenceId';
import { useToken } from '../../hooks/useToken';
import { setCurrentVenteDetails } from '../../redux/reducers/venteFacture';
import { resetFactureSelectedClient, setFactureSelectedClient, setIsFactureForDepense, setIsUpdatingFacture, setProductsNotAddedToCurrentVente } from '../../redux/reducers/new/newFactureArticles';
import { useAgence } from '../../hooks/useAgence';

const ListVentes = () => {

  const { ventesList } = useSelector(state => state.ventes) || []
  const { allProducts } = useSelector(state => state.newFactureArticles)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const agenceId = useAgenceId()
  const token = useToken()

  // const agence = useAgence()

  // console.log({ agence });

  useEffect(() => {
    // ApiController.ventes.getAgencesVentes(agenceId, dispatch, token)
    // ApiController.categories.getCategoriesList(agenceId, dispatch, token)
    ApiController.all.getAgenceData(agenceId, dispatch, token, ApiController)
  }, [agenceId, token, dispatch])

  useEffect(() => {
    dispatch(setIsFactureForDepense(false))
    dispatch(setIsUpdatingFacture(false))
    dispatch(resetFactureSelectedClient())
  }, [dispatch])      

  const showUpdateModal = (prod) => {
    dispatch(setFactureSelectedClient(null))
    dispatch(setCurrentVente(prod))
    if (prod.client) {
      // ! on a besoin du current user pour l'afficher au niveau du select lors de la modification d'une vente
      // dispatch(setSelectedClient({ value: prod.client.id, label: `${prod.client.firstName} ${prod.client.lastName}` }))
      dispatch(setFactureSelectedClient({ value: prod.client.id, label: `${prod.client.firstName} ${prod.client.lastName}` }))
    }

    dispatch(setIsUpdatingFacture(true))
    dispatch(setProductsNotAddedToCurrentVente(allProducts))
    navigate(UPDATE_VENTES_PATH + `?id=${prod.id}`)
    // console.log({ prod });
    // alert()
  }
  const showDeleteModal = (prod) => {
    dispatch(setCurrentVente(prod))
    openDeleteProductModal()
  }
  const showDetailsModal = (prod) => {
    dispatch(setCurrentVente(prod))
    // console.log({ prod });
    // !reinitialiser le currenteVenteDetails avant d'y acceder
    dispatch(setCurrentVenteDetails({}))
    navigate(DETAILS_VENTES_PATH + `?id=${prod.id}`)
  }
  const showActionButtons = (cell, row) => {
    return (
      <div >
        <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={() => showDetailsModal(row)} />
        {row.status === 'ENCOURS' ?
          <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon' onClick={() => showUpdateModal(row)} />
          :
          <FiEdit aria-disabled={true} className='custom-mouse-clickable opacity-1 text-secondary mx-3 fs-5' />
        }
        {row.status === 'ENCOURS' ?
          <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon' onClick={() => showDeleteModal(row)} />
          :
          <RiDeleteBinLine className='custom-mouse-clickable opacity-1 mx-3 fs-5 text-secondary' />
        }
        <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }


  const columns = [
    {
      dataField: "id",
      text: "Numéro",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.id}</p>
    },
    {
      dataField: "created_at",
      text: "Date",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        var selected_date = new Date(row.created_at)
        return (
          <p className='' onClick={() => showDetailsModal(row)}>
            {selected_date?.toLocaleDateString('fr-SN')}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {selected_date?.toLocaleTimeString('fr-SN', { hour: 'numeric', minute: 'numeric' })}
          </p>
        )
      }
    },
    {
      dataField: "client",
      text: "Client",
      headerClasses: 'custom-table-column text-white col',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.client?.id ? `${row.client.firstName} ${row.client.lastName}` : "Pas de client "}</p>
    },
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        return <p className='' onClick={() => showDetailsModal(row)}>{row?.montantTotal + ' XOF'}</p>
      }
    },
    {
      dataField: "emis",
      text: "Payé",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: (cell, row) => <p className='' onClick={() => showDetailsModal(row)}>{row.status === 'ENCOURS' ? 'Non' : 'Oui'}</p>
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: showActionButtons
    },
  ];

  const pagination = paginationFactory({
    // page: 1,
    sizePerPageList: [{
      text: '10', value: 10
    },
      {
      text: '20', value: 20
    },
    {
      text: '30', value: 30
    },
    {
      text: 'Tous', value: ventesList?.length
    }]
  });

  function openDeleteProductModal() {
    setDeleteModalIsOpen(true);
  }

  return (
    <div className='row m-0 p-0 list-clients'>
      <DeleteVenteModal
        modalIsOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
      />
      <ServicesComponent
        title="Factures de ventes"
        description="Créez, consultez et gérez vos enrégistrements"
        labelIcon={<RiSearch2Line className='fw-bold fs-2 col-2 mt-1 mx-0 ' />}
        // input={<input name='search' className='input-search col-10' type="text" onChange={e => dispatch(filterVentesList(e.target.value))} />}
        onChange={e => {
          dispatch(filterVentesList(e.target.value))
        }}
        onClick1={() => navigate(ADD_VENTES_PATH)}
        firstBtnText="Nouvelle facture"
        firstBtn={<AiFillPlusCircle className='fs-3' />}
        onClick2={null}
        secondBtnText=""
        secondBtn={null}
      />
      <ServiceDataMap
        data={ventesList}
        columns={columns}
        pagination={pagination}
        messageOnError="Pas d'historique pour le moment"
      /> 
    </div>
  );
};

export default ListVentes;