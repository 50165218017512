import React, { useState } from 'react';
import Modal from 'react-modal';
import './add_depense.css'
import {useSelector,useDispatch} from 'react-redux';
import ReduxAction from '../../redux/actions';
import { toast } from 'react-toastify';
import toast_options from '../../utils/toast_options';
import { useEffect } from 'react';

import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

const SendEmailModal = ({modalIsOpen,setIsOpen}) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.users.currentUser)
    const currentVente = useSelector(state => state.ventes.currentVente)

    const [isLoading,setIsLoading] = useState(true)

    useEffect(()=>{
        setTimeout(() => {
            //get table html
            const pdfTable = window.document.getElementById('facture');
            //html to pdf format
            var html = htmlToPdfmake(pdfTable.innerHTML);
        
            const documentDefinition = { content: html };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            console.clear();
            const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

            pdfDocGenerator.getBlob((blob) => {
                console.log(blob);
                let myFile = new File([blob],'facture'+currentVente.id+'.pdf')
                console.log(myFile);
                createFacture(myFile)
            });
        }, 2000);
    },[])

    const createFacture =(file)=>{
        let formData = new FormData()
        formData.append('facture',file)

        ReduxAction.ventes.updateVente(currentVente.id,formData,currentUser.token.token,dispatch)
        .then(()=>{
            setIsLoading(false)
        })
    }
    const sendEmail = ()=>{
        ReduxAction.ventes.sendEmailFacture(currentVente.id,currentUser.token.token)
        .then(()=>{
            toast.success("La facture vient d'être envoyé a "+currentVente.client_info.email,toast_options)
            closeModal()
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height:'50%',
            width:'40%',
            minWidth:'300px',
            shadow:'0px 0px 5px black',
            background:'#eee',
            borderRadius:'0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    
    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {isLoading? (
                    <div className='text-center row h-100 align-items-center'>
                        <div className='text-center'>
                            <div className="email-loader"></div>
                            <p>Chargement du fichier</p>
                        </div>
                    </div>
                ):(
                    <div className="row align-items-center h-100">
                        <div className="">
                            <div className="row my-2">
                                <input type="text" placeholder='Titre' className="form-control" />
                            </div>
                            <div className="row my-2">
                                <textarea className='form-control' placeholder='description'></textarea>
                            </div>
                            <div className="text-center mt-4">
                                <button className='custom-button-secondary py-2 px-4' onClick={sendEmail}>Envoyer</button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default SendEmailModal;