import React, { useEffect, useState } from 'react'
import NewFactureArticles from '../../facture/new/NewFactureArticles'
import ButtonLoading from '../../shared/ButtonLoading'
import { useDispatch, useSelector } from 'react-redux'
import ApiController from '../../../redux/actions'
import { resetFactureSelectedFournisseur, setIsFactureForDepense, setIsUpdatingFacture, setProductsNotAddedToCurrentVente } from '../../../redux/reducers/new/newFactureArticles'
import { useToken } from '../../../hooks/useToken'
import { useAgenceId } from '../../../hooks/useAgenceId'
import { useCurrentUserId } from '../../../hooks/useCurrentUserId'
import SelectFactureFournisseur from './SelectFactureFournisseur'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const NewDepensePage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isProductLoading, setIsProductLoading] = useState(false)
    const token = useToken()
    let agenceId = useAgenceId()
    const userId = useCurrentUserId()
    const dispatch = useDispatch()
    const { currentFacture, selectedFactureFournisseur, disableProduct } = useSelector(state => state.newFactureArticles)

    useEffect(() => {
        setIsProductLoading(true)
        ApiController.produits.getProductsList(agenceId, dispatch, token).then(response => {
            dispatch(setProductsNotAddedToCurrentVente(response))
        })
            .finally(() => setIsProductLoading(false))
    }, [agenceId, dispatch, token])

    useEffect(() => {
        dispatch(setIsUpdatingFacture(false))
        dispatch(resetFactureSelectedFournisseur())
    }, [])

    const confirmFacture = () => {
        const articles = currentFacture.map(article => {
            const data = {
                productId: Number(article.product.id),
                quantity: Number(article.quantity),
                price: article.product.prixAchat
            }
            return data
        })

        const newDepense = {
            fournisseurId: selectedFactureFournisseur ? selectedFactureFournisseur.value : '',
            createdBy: userId,
            agenceId,
            articles
        }

        // console.log({ newDepense });

        setLoading(true)
        ApiController.depenses.createNewDepense(newDepense, token, dispatch).then(res => {
            toast.success('Dépense effectuée')
            navigate(-1)
        }).catch(error => {
            toast.error('Une erreur est survenu')
        })
            .finally(() => {
                setLoading(false)
            })

    }

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Nouvelle facture de dépense</p>
            </div>
            <NewFactureArticles
                loadingResource={isProductLoading}
            >
                <SelectFactureFournisseur />
                <ButtonLoading
                    disabled={disableProduct}
                    text="Valider"
                    loading={loading}
                    onClick={confirmFacture}
                />
            </NewFactureArticles>
        </div>
    )
}

export default NewDepensePage